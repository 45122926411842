import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { userreducer } from './user.reducer';
import { statics } from './statics.reducer';
import { picture } from './profile.reducer';
import { alert } from './alert.reducer';
import { coachee } from './coachee.reducer';

const rootReducer = combineReducers({
    authentication,
    userreducer,
    statics,
    picture,
    alert,
    coachee
});

export default rootReducer;