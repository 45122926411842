import Picker from '@sama-io/emoji-picker-react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import '../../node_modules/@sama-io/emoji-picker-react/dist/emoji-picker-react.cjs.production.min.css'
import './MessageForm.css'
import { VoiceRecord } from './voice-record'

interface Props {
    onMessageSend: any
    channel: any
    channelId: string
}

export const MessageForm: React.FC<Props> = observer(
    ({ onMessageSend, channel, channelId }) => {
        const uploadRef = useRef(null)
        const inputRef = useRef<HTMLTextAreaElement>(null)

        const [showVoicePanel, setShowVoicePanel] = useState(false)

        const [showEmojiPicker, setShowEmojiPicker] = useState(false)

        const [isImportant, setIsImportant] = useState(false)

        const [ctrlKeySelected, setCtrlKeySelected] = useState(false)
        const [reachedTextareaHeight, setReachedTextareaHeight] =
            useState(false)

        const [showErrorPopup, openErrorPopup] = useState(false)
        const [popupErrorMessage, setPopupErrorMessage] = useState('')

        const qmarkClassNames = classNames('qmark', {
            active: isImportant,
        })

        const userTyping = useCallback(
            (e: any) => {
                // ctrl key pressed
                if (e.keyCode == 17) {
                    setCtrlKeySelected(true)
                }

                if (ctrlKeySelected && e.keyCode == 13) {
                    handleFormSubmit(e)
                }

                if (channel) {
                    channel.typing()
                }
            },
            [ctrlKeySelected],
        )

        const onKeyUp = useCallback((e: any) => {
            if (!reachedTextareaHeight) {
                e.target.style.height = '1px'
                if (e.target.scrollHeight < 100) {
                    e.target.style.height = 15 + e.target.scrollHeight + 'px'
                } else {
                    e.target.style.height = 15 + e.target.scrollHeight + 'px'
                    setReachedTextareaHeight(true)
                }
            }

            // ctrl key pressed
            if (e.keyCode == 17) {
                setCtrlKeySelected(false)
            }
        }, [])

        const onAttachmentClick = useCallback(() => {
            // @ts-ignore
            uploadRef?.current?.click()
        }, [])

        const onEmojiClick = useCallback((emojiObject: any) => {
            if (inputRef.current) {
                inputRef.current.value =
                    inputRef.current.value + emojiObject.emoji
            }
        }, [])

        const isAllowedAttachmentContentType = useCallback((type: string) => {
            return (
                type.search(
                    /pdf|application|docx|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.spreadsheetml.sheet|csv|jpg|jpeg|gif|png|xlsx|txt/i,
                ) >= 0
            )
        }, [])

        const handleFormSubmit = useCallback(
            async (event: any, voiceFile?: { file: any; length: number }) => {
                if (
                    event?.target?.files &&
                    !isAllowedAttachmentContentType(event.target.files[0].type)
                ) {
                    // wrong file type
                    openErrorPopup(true)
                    setPopupErrorMessage(
                        'Please upload one of the following file types pdf, docx, csv, jpg, jpeg, gif, png, xlsx, txt.',
                    )
                } else {
                    if (event) {
                        event.preventDefault()
                    }
                    setReachedTextareaHeight(false)

                    if (inputRef.current?.style) {
                        inputRef.current.style.height = '25px'
                    }

                    onMessageSend(
                        inputRef?.current?.value ?? '',
                        isImportant,
                        event,
                        voiceFile?.file,
                        voiceFile?.length,
                    )

                    setIsImportant(false)

                    if (inputRef.current?.value) {
                        inputRef.current.value = ''
                    }

                    setShowEmojiPicker(false)
                    setShowVoicePanel(false)
                }
            },
            [isImportant],
        )

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.value = ''
            }
        }, [channelId])

        return (
            <>
                {showVoicePanel && (
                    <VoiceRecord
                        handleFormSubmit={handleFormSubmit}
                        open={setShowVoicePanel}
                    />
                )}

                {showEmojiPicker && (
                    <div className="absolute z-[1000] right-[98px] bottom-[77px]">
                        <Picker
                            height={500}
                            width={400}
                            onEmojiClick={onEmojiClick}
                        />
                    </div>
                )}
                {!showVoicePanel && (
                    <form className="MessageForm" onSubmit={handleFormSubmit}>
                        <span
                            className={qmarkClassNames}
                            onClick={() => setIsImportant(true)}
                        >
                            !
                        </span>
                        <div className="input-container">
                            <textarea
                                onKeyDown={userTyping}
                                onKeyUp={onKeyUp}
                                ref={inputRef}
                                placeholder="Type your message..."
                            ></textarea>
                            <div
                                style={{ float: 'right', paddingRight: '6px' }}
                            >
                                <button
                                    type="button"
                                    className="emoji-icon"
                                    onClick={() =>
                                        setShowEmojiPicker(!showEmojiPicker)
                                    }
                                ></button>
                                <button
                                    type="button"
                                    className="paperclip"
                                    onClick={onAttachmentClick}
                                ></button>
                                <input
                                    type="file"
                                    hidden
                                    id="attachment-upload"
                                    ref={uploadRef}
                                    onChange={handleFormSubmit}
                                />
                                <button
                                    type="button"
                                    className="mic"
                                    onClick={() => setShowVoicePanel(true)}
                                ></button>
                            </div>
                        </div>
                        <div className="button-container">
                            <button
                                type="button"
                                className="fa fa-paper-plane"
                                onClick={handleFormSubmit}
                            ></button>
                        </div>
                    </form>
                )}
                <div></div>
                <Popup
                    open={showErrorPopup}
                    className="alertBox"
                    modal={true}
                    nested
                    lockScroll
                >
                    <>
                        <div>{popupErrorMessage}</div>
                        <button
                            className="relative btn btn-light btn-bolck"
                            type="button"
                            onClick={() => openErrorPopup(false)}
                        >
                            Close
                        </button>
                    </>
                </Popup>
            </>
        )
    },
)
