import OneSignal from 'react-onesignal'
import { userConstants } from '../_constants'

const enabled = true

async function init() {
    if (enabled) {
        const coach = JSON.parse(localStorage.getItem('userinfo') ?? '{}')
        if (coach) {
            await OneSignal.init({ appId: userConstants.ONE_SIGNAL_APP_ID })
            OneSignal.User.addEmail(coach.user.email)
            await OneSignal.login(coach._id)
            OneSignal.Notifications.requestPermission()
        }
    }
}

export const NotificationService = {
    init,
}
