import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { userConstants } from '../_constants'

function ForgotPassword() {
    const [error, setError] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const history = useNavigate()
    return (
        <div className="pink-container bg-pink">
            <div className="row">
                <div className="col-sm-6 col-md-6">
                    <div className="semi-article">
                        <figure>
                            <img
                                src="assets/logo-dark.svg"
                                width="120"
                                alt="logo"
                                className="logo"
                            />
                        </figure>
                        <h2 className="m-t-60">
                            Welcome to the <br /> Sama team!
                        </h2>
                        <p>
                            We are excited that you are part of our mission
                            <br /> to make the world an engaged and inspired
                            <br /> place
                        </p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6">
                    <div className="login-form">
                        {emailSent && (
                            <div>
                                <p>
                                    An email has been sent to your inbox. Please
                                    follow the link in the email
                                </p>
                                <p>
                                    Please check your spam folder if it has not
                                    arrived in the next 5 minutes
                                </p>
                            </div>
                        )}
                        {!emailSent && (
                            <Formik
                                initialValues={{ email: '' }}
                                onSubmit={(values) => {
                                    const requestOptions = {
                                        method: 'post',
                                        url: `${userConstants.BASE_URL}/auth/reset-password`,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: values,
                                    }
                                    axios(requestOptions)
                                        .then(function (res) {
                                            setEmailSent(true)
                                        })
                                        .catch(function (error) {
                                            Sentry.captureException(error)
                                            setError(
                                                'Please try agian. If this problem persists please contact support@sama.io',
                                            )
                                        })
                                }}
                                validate={(values) => {
                                    const errors: any = {}
                                    if (!values.email) {
                                        errors.email =
                                            'The email entered is not valid'
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                            values.email,
                                        )
                                    ) {
                                        errors.email =
                                            'The email entered is not valid'
                                    }
                                    return errors
                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isValid,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                <h3>
                                                    We will send you a new
                                                    password
                                                </h3>
                                                <div className="form-group">
                                                    <label htmlFor="email">
                                                        Email
                                                    </label>
                                                    <input
                                                        id="email"
                                                        placeholder="Type your email"
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                        className={
                                                            errors.email &&
                                                            touched.email
                                                                ? 'form-control text-input'
                                                                : 'form-control text-input'
                                                        }
                                                    />
                                                    {errors.email &&
                                                        touched.email && (
                                                            <div className="error">
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form-group btn-block-bottom">
                                                <button
                                                    className="relative btn btn-primary btn-bolck"
                                                    disabled={
                                                        !(isValid && dirty)
                                                    }
                                                >
                                                    {isSubmitting ? (
                                                        <div>
                                                            <span className="loading-spin"></span>
                                                            <span>
                                                                In progress
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        'Continue to login'
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ForgotPassword }
