import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
//import { LoginPage } from './LoginPage';

const stepOneFields = [
    {
        user: [
            // "profilePic",
            'firstName',
            'lastName',
            'birthday',
            'phoneNumber',
            'nativeLanguages',
        ],
    },
    {
        address: ['country', 'city'],
    },
    'gender',
    'coachingLanguages',
    'coachingLanguages',
    'highestEducationLevel',
    'maximumHourPerWeek',
]

const stepTwoFields = [
    //"bio",
    'startCoachingYear',
    'areasCompetencies',
    'coachingAccreditations',
    'coachingMemberships',
    'coachingIndustries',
    'coachingFunctions',
]

const stepThreeFields = [
    'experienceTotalYear',
    'experienceIndustries',
    'experienceFunctions',
    'experienceCountry',
]

function checkData(value: any) {
    const userInfo = JSON.parse(window.localStorage.getItem('userinfo') ?? '{}')
    if (userInfo != undefined) {
        if (value.user) {
            const left = value.user.filter((userValues: any) => {
                if (
                    userInfo.user[userValues] == undefined ||
                    userInfo.user[userValues] == ''
                ) {
                    return true
                } else {
                    return false
                }
            })
            if (left.length == 0) {
                return false
            } else {
                return true
            }
        }

        if (value.address) {
            const left = value.address.filter((userValues: any) => {
                if (
                    userInfo?.user?.address &&
                    (userInfo?.user?.address[userValues] == undefined ||
                        userInfo?.user?.address[userValues] == '')
                ) {
                    return true
                } else {
                    return false
                }
            })
            if (left.length == 0) {
                return false
            } else {
                return true
            }
        }

        if (userInfo[value] == undefined || userInfo[value] == '') {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

function goToIncompletOnboardingStep() {
    const userInfo = window.localStorage.getItem('userinfo')
    if (userInfo) {
        const stepOneEmptyFileds = stepOneFields.filter((value) =>
            checkData(value),
        )

        let step: number | null = 1
        if (stepOneEmptyFileds.length == 0) {
            const stepTwoEmptyFileds = stepTwoFields.filter((value) =>
                checkData(value),
            )
            step = 2
            if (stepTwoEmptyFileds.length == 0) {
                const stepThreeEmptyFileds = stepThreeFields.filter((value) =>
                    checkData(value),
                )
                step = 3
                if (stepThreeEmptyFileds.length == 0) {
                    step = null
                }
            }
        }
        return step
    }
}

function PrivateRoute({ children }: any) {
    const location = useLocation()
    const user = localStorage.getItem('user')
    const token = user ? JSON.parse(user).accessToken : null

    const userInf = localStorage.getItem('userinfo')
    const userinfo = userInf ? JSON.parse(userInf) : null
    if (!token) {
        // not logged in so redirect to login page with the return url
        return (
            <Navigate to={{ pathname: '/login' }} state={{ from: location }} />
        )
    }
    // check if route is restricted by role
    if (token && userinfo && userinfo.user.isPasswordGenerated === true) {
        // role not authorized so redirect to home page
        return (
            <Navigate to={{ pathname: '/change' }} state={{ from: location }} />
        )
    }

    let fromProfile = false
    if (location?.search) {
        const query = new URLSearchParams(location.search)
        if (query.has('fromProfile')) {
            fromProfile = true
        }
    }

    if (!fromProfile) {
        const stepNumber = goToIncompletOnboardingStep()
        if (!location?.pathname.includes('/details') && stepNumber) {
            return (
                <Navigate
                    to={{ pathname: '/details' }}
                    state={{ step: stepNumber }}
                />
            )
        } else if (location?.pathname.includes('/details') && !stepNumber) {
            return <Navigate to={{ pathname: '/home' }} />
        }
    }

    return children
}

export { PrivateRoute }
