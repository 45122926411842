import { userConstants } from '../_constants'
import axios from 'axios'
import md5 from 'md5'
import { CoacheeService } from '../_services/coachee.service'
import * as Sentry from '@sentry/react'
import { Client as ConversationsClient } from '@twilio/conversations'

let channelLists = []
let pausedLists = []
let client
let unreadCallBack = null
let activeChannel = null
let chatToken = null

const getChannelLists = () => {
    return new Promise((resolve, reject) => {
        resolve(channelLists)
    })
}

const setActiveChannel = (channel) => {
    activeChannel = channel
}

const setPausedChannelList = (channelList) => {
    pausedLists = channelList
}

const refreshToken = async () => {
    const token = await getToken(true)
    client.updateToken(token)
}

const getChatClient = async () => {
    try {
        if (!client) {
            const token = await getToken()
            client = new ConversationsClient(token)
            client.on('tokenAboutToExpire', refreshToken)
        }

        return client
    } catch (e) {
        Sentry.captureException(e)
        console.error(e)
    }
}

const getToken = (refresh) => {
    if (!chatToken || refresh) {
        chatToken = new Promise((resolve, reject) => {
            let deviceID = md5(window.navigator.userAgent)
            axios
                .get(
                    `${
                        userConstants.BASE_URL
                    }channel/token?deviceId=${deviceID.toString()}`,
                )
                .then((res) => {
                    resolve(res.data.token)
                })
                .catch((error) => {
                    Sentry.captureException(error)
                    console.log(error)
                    reject()
                })
        })
    }
    return chatToken
}

const init = () => {
    return new Promise((resolve, reject) => {
        getChannelList()
            .then(getToken)
            .then(getChatClient)
            .then(setChannelsIntoList)
            .then(getChannelLists)
            .then(resolve)
    })
}

const setUnreadCallback = (callBack) => {
    unreadCallBack = callBack
}

const configureChannelEvents = (channel) => {
    channel.on('messageAdded', (message) => {
        const id = JSON.parse(
            window.localStorage.getItem('userinfo'),
        )._id.toString()
        if (
            (!activeChannel || activeChannel?.sid != channel.sid) &&
            message.author !== id
        ) {
            channel
                .getUnreadMessagesCount()
                .then(updateUnreadMessageCount.bind(null, channel))
                .then(() => {
                    if (unreadCallBack) {
                        getTotalUnreadMessageCount().then(unreadCallBack)
                    }
                })
        }
    })
}

const getChannelList = () => {
    return CoacheeService.getCoacheeDetails().then((channels) => {
        channelLists = channels.slice(0, channels.length)
    })
}

const updateUnreadMessageCount = (channel, count) => {
    return new Promise((resolve, reject) => {
        for (let i = 0; i < channelLists.length; i++) {
            if (channelLists[i].twilio.id == channel.sid) {
                channelLists[i].unreadCount = count
            }
        }

        if (unreadCallBack) {
            getTotalUnreadMessageCount().then(unreadCallBack)
        }
        resolve(channelLists)
    })
}

const setChannelsIntoList = (chatClient) => {
    return new Promise((resolve, reject) => {
        chatClient
            .getSubscribedConversations()
            .then((paginator) => {
                for (let i = 0; i < paginator.items.length; i++) {
                    var channel = paginator.items[i]

                    for (let i = 0; i < channelLists.length; i++) {
                        if (channel.sid == channelLists[i].twilio.id) {
                            channelLists[i].channel = channel
                            configureChannelEvents(channel)
                        }
                    }
                }
            })
            .then(() => {
                let promises = []
                for (let i = 0; i < channelLists.length; i++) {
                    if (channelLists[i].channel) {
                        promises.push(
                            channelLists[i].channel
                                .getUnreadMessagesCount()
                                .then(
                                    updateUnreadMessageCount.bind(
                                        null,
                                        channelLists[i].channel,
                                    ),
                                ),
                        )
                    }
                }

                Promise.all(promises).then((values) => {
                    resolve(channelLists)
                })
            })
    })
}

const getTotalUnreadMessageCount = () => {
    return new Promise((resolve, reject) => {
        let totalCount = 0
        for (let i = 0; i < channelLists.length; i++) {
            if (channelLists[i].unreadCount) {
                totalCount = totalCount + channelLists[i].unreadCount
            }
        }
        resolve(totalCount)
    })
}

const getChannelListByChannel = (channel) => {
    for (let i = 0; i < channelLists.length; i++) {
        if (channelLists[i].channel.sid == channel.sid) {
            return channelLists[i]
        }
    }
}

const getPausedChannelListByChannel = (channel) => {
    for (let i = 0; i < pausedLists.length; i++) {
        if (pausedLists[i].channel.sid == channel.sid) {
            return pausedLists[i]
        }
    }
}

export const ChatService = {
    init,
    getChatClient,
    setActiveChannel,
    setPausedChannelList,
    getToken,
    getChannelList,
    updateUnreadMessageCount,
    getTotalUnreadMessageCount,
    getChannelLists,
    setChannelsIntoList,
    setUnreadCallback,
    getChannelListByChannel,
    getPausedChannelListByChannel
}
