import { userConstants } from '../_constants'

let user = JSON.parse(localStorage.getItem('user') ?? '{}')
const initialState = user ? { loggedIn: true, user } : {}

export function authentication(
    state = initialState,
    action: { type: string; payload: string; user: string },
) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload,
            }
        case userConstants.LOGIN_FAILURE:
            return {}
        case userConstants.LOGOUT:
            return {}
        default:
            return state
    }
}
