import { useField } from 'formik'
import { HTMLMotionProps, motion } from 'framer-motion'
import React, { useState } from 'react'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'
import './styles.css'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    label?: string
    inputClassName?: string
    small?: boolean
    editEnabled?: boolean
    applyFocusStyle?: boolean
}

export const TextInput: React.FC<Props> = ({
    name,
    label,
    small,
    inputClassName,
    className,
    editEnabled = true,
    applyFocusStyle = false,
    ...props
}) => {
    const [field, meta] = useField(name)
    const [isFocused, setIsFocused] = useState(false)

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[19px] mb-2">
                    {label}
                </label>
            )}
            {!editEnabled ? (
                <p>{field.value}</p>
            ) : (
                <motion.input
                    data-testid="input"
                    className={classNames(
                        inputClassName,
                        'bg-[#f6f7f9]',
                        small ? 'h-11' : 'h-12',
                        'rounded-[0.25rem]',
                        'px-4',
                        'text-[17px]',
                        'focus:outline-none',
                        applyFocusStyle && isFocused ? 'input-focused' : '',
                        'placeholder:text-[17px]',
                    )}
                    {...props}
                    {...field}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={field.value || ''}
                />
            )}
            {meta.error && (
                <div className="mt-[10px] uppercase">
                    <FormValidationMessage message={meta.error} />
                </div>
            )}
        </div>
    )
}
