import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import Autocomplete from '../_components/Autocomplete'
import Autocompletemulti from '../_components/Autocompletemulti'
import axios from 'axios'
import { userConstants } from '../_constants'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { CommonService } from '../_services/common.service'
import { utilsService } from '../_services/utils.service'
import { getCoachImage } from '../_services/coach-image'

function PersionalInfo(props: any) {
    const [imgSrc, setImageSrc] = useState(getCoachImage())
    const { values, handleChange, setFieldValue, resetForm } = props
    const { statics } = props
    const { errors } = props
    const { touched } = props

    const [dayOfBirth, setDayOfBirth] = useState<number>()
    const [monthOfBirth, setMonthOfBirth] = useState<string>()
    const [yearOfBirth, setYearOfBirth] = useState<number>()

    const onProfileUpload = (e: any) => {
        var file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        const requestOptions = {
            method: 'post',
            url: `${userConstants.BASE_URL}coach/${props?.coach?._id}/picture?size=thumbnail`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        }
        axios(requestOptions)
            .then(function (res) {
                CommonService.getProfilePicture(props?.coach?._id).then(
                    (image) => {
                        setImageSrc(getCoachImage)
                        values.profilePic = getCoachImage()
                    },
                )
            })
            .catch(function (error) {
                Sentry.captureException(error)
                console.log('Error: ', error)
            })
    }

    const updateBirthday = (number: any, setFunction: any, type: any) => {
        setFunction(number)

        let dayValue = dayOfBirth
        if (type == 'day') {
            dayValue = number
        }

        let monthValue = monthOfBirth
        if (type == 'month') {
            monthValue = number
        }

        let yearValue: any = yearOfBirth
        if (type == 'year') {
            yearValue = number
        }

        if (dayValue && monthValue && yearValue) {
            const monthNumber = utilsService.getMonthNumber(
                monthValue,
            ) as number
            values.birthday = new Date(yearValue, monthNumber, dayValue)
        }
    }

    // if (!imgSrc && values.profilePic && localStorage.getItem("picture")) {
    //   setImageSrc(localStorage.getItem('picture'))
    // }
    return (
        <>
            {' '}
            <h5 className="text-center">Personal information</h5>
            <div className="form-group profile-img Aligner">
                <label htmlFor="profile-pic">
                    <input
                        type="file"
                        id="profile-pic"
                        className="d-none"
                        name="profile-pic"
                        onChange={onProfileUpload}
                    />
                </label>
                <input
                    type="hidden"
                    value={values.profilePic ?? ''}
                    id="profilePic"
                    className="form-control"
                    name="profilePic"
                    onChange={handleChange}
                />
                {!imgSrc && <span className="fa fa-camera"></span>}
                {imgSrc && (
                    <img
                        className="profile-pic"
                        src={imgSrc}
                        alt="profile picture"
                    />
                )}
            </div>
            {errors.profilePic && touched.profilePic && (
                <div className="error">{errors.profilePic}</div>
            )}
            <div className="form-group">
                <label>First name</label>
                <input
                    id="firstName"
                    placeholder="Type your first name"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    className="form-control"
                />{' '}
                {errors.firstName && touched.firstName && (
                    <div className="error">{errors.firstName}</div>
                )}
            </div>
            <div className="form-group">
                <label>Last name</label>
                <input
                    id="lastName"
                    placeholder="Type your last name"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    className="form-control"
                />
                {errors.lastName && touched.lastName && (
                    <div className="error">{errors.lastName}</div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="birthday">Date of birth</label>
                <div className="dob">
                    <Autocomplete
                        options={utilsService.arrayOfNumbers(31)}
                        selected={dayOfBirth as unknown as string}
                        onSelectCallback={(v: any) => {
                            updateBirthday(v, setDayOfBirth, 'day')
                        }}
                        placeholder="Day"
                    />
                    <Autocomplete
                        options={utilsService.arrayOfMonthNames()}
                        selected={monthOfBirth as unknown as string}
                        onSelectCallback={(v: any) => {
                            updateBirthday(v, setMonthOfBirth, 'month')
                        }}
                        placeholder="Month"
                    />
                    <Autocomplete
                        options={utilsService.arrayOfYears()}
                        selected={yearOfBirth as unknown as string}
                        onSelectCallback={(v: any) => {
                            updateBirthday(v, setYearOfBirth, 'year')
                        }}
                        placeholder="Year"
                    />
                    {errors.birthday && touched.birthday && (
                        <div className="error">{errors.birthday}</div>
                    )}
                </div>
            </div>
            <div className="form-group country-code">
                <label htmlFor="phoneNumber">Phone number</label>
                <PhoneInput
                    country={
                        props?.coach?.user?.address?.country?.toLowerCase()
                            ? props?.coach?.user?.address?.country.toLowerCase()
                            : 'gb'
                    }
                    autoFormat={false}
                    enableSearch={true}
                    disableSearchIcon={true}
                    preferredCountries={['uk']}
                    value={values.phoneNumber}
                    countryCodeEditable={false}
                    placeholder="7654321987"
                    onChange={(v) => {
                        setFieldValue('phoneNumber', v, false)
                    }}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                    <div className="error">{errors.phoneNumber}</div>
                )}
            </div>
            <div className="form-group">
                <label>Gender</label>
                <div className="custom-radio">
                    <label
                        htmlFor="female"
                        className={values.gender === 'female' ? 'checked' : ''}
                    >
                        Female
                        <input
                            id="female"
                            name="gender"
                            type="radio"
                            value="female"
                            checked={values.gender === 'female'}
                            onChange={() => setFieldValue('gender', 'female')}
                            className="form-control"
                        />
                    </label>
                    <label
                        htmlFor="male"
                        className={values.gender === 'male' ? 'checked' : ''}
                    >
                        Male
                        <input
                            id="male"
                            name="gender"
                            type="radio"
                            value="male"
                            checked={values.gender === 'male'}
                            onChange={() => setFieldValue('gender', 'male')}
                            className="form-control"
                        />
                    </label>
                    <label
                        htmlFor="other"
                        className={values.gender === 'other' ? 'checked' : ''}
                    >
                        I prefer not to say
                        <input
                            id="other"
                            name="gender"
                            type="radio"
                            value="other"
                            checked={values.gender === 'other'}
                            onChange={() => setFieldValue('gender', 'other')}
                            className="form-control"
                        />
                    </label>
                </div>
            </div>
            <div className="form-group">
                <label>Country</label>
                <Autocomplete
                    options={statics?.countries}
                    selected={values.country}
                    onSelectCallback={(v: any) => {
                        setFieldValue('country', v, false)
                    }}
                    placeholder="Type or select a country"
                />
                {errors.country && touched.country && (
                    <div className="error">{errors.country}</div>
                )}
            </div>
            <div className="form-group">
                <label>City</label>
                <input
                    id="city"
                    autoComplete="chrome-off"
                    placeholder="Type your city"
                    type="text"
                    value={values.city}
                    onChange={handleChange}
                    className="form-control"
                />
                {errors.city && touched.city && (
                    <div className="error">{errors.city}</div>
                )}
            </div>
            <div className="form-group">
                <label>Native language(s)</label>
                <Autocompletemulti
                    options={statics?.languages}
                    selected={values.nativeLanguages}
                    onSelectCallback={(v: any) => {
                        setFieldValue('nativeL', v, false)
                    }}
                    placeholder="Type or select your language(s)"
                />
                {errors.nativeLanguages && touched.nativeLanguages && (
                    <div className="error">{errors.nativeLanguages}</div>
                )}
            </div>
            <div className="form-group">
                <label>Coaching language(s)</label>
                <Autocompletemulti
                    options={statics?.languages}
                    selected={values.coachingLanguages}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingL', v, false)
                    }}
                    placeholder="Enter your coaching language(s)"
                />
                {errors.coachingLanguages && touched.coachingLanguages && (
                    <div className="error">{errors.coachingLanguages}</div>
                )}
            </div>
            <div className="form-group">
                <label>Highest education level</label>
                <Autocomplete
                    options={statics?.educationLevels}
                    selected={values.highestEducationLevel}
                    onSelectCallback={(v: any) => {
                        setFieldValue('highestEducationLevel', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.highestEducationLevel &&
                    touched.highestEducationLevel && (
                        <div className="error">
                            {errors.highestEducationLevel}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label htmlFor="hours">Maximum hours available per week</label>
                <input
                    id="maximumHourPerWeek"
                    type="number"
                    value={parseInt(values.maximumHourPerWeek)}
                    onChange={handleChange}
                    className="form-control half-number"
                />
                {errors.maximumHourPerWeek && touched.maximumHourPerWeek && (
                    <div className="error">{errors.maximumHourPerWeek}</div>
                )}
            </div>
        </>
    )
}
export { PersionalInfo }
