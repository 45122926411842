import React, { useState } from 'react'
import moment from 'moment'
import { CommonService } from '../_services/common.service'
import EmptyStatIcon from '../assets/icons/empty-state.svg'

interface Props {
    title: string
    emptyTitle: string
    emptyMessage: string
    open: any
    close: any
    list: any
    onItemClick: any
}

const SamaListPopupView = ({
    title,
    emptyTitle,
    emptyMessage,
    open,
    close,
    list,
    onItemClick,
}: Props) => {
    function openNote(item: any) {
        onItemClick(item)
    }

    return (
        <>
            {open && (
                <div className="goal-view-container">
                    <div className="goal-view-box" style={{ padding: '55px' }}>
                        <div
                            className="coach-question-box"
                            style={{
                                maxHeight: 'none',
                            }}
                        >
                            <div
                                className="btn-close-solid"
                                onClick={() => close(false)}
                            ></div>
                            {list.length > 0 && (
                                <>
                                    <h2
                                        style={{
                                            paddingBottom: '45px',
                                        }}
                                    >
                                        {title}
                                    </h2>

                                    {list.map((item: any, i: number) => {
                                        return (
                                            <span key={i}>
                                                <div
                                                    className={
                                                        i == 0
                                                            ? 'note-box top'
                                                            : 'note-box'
                                                    }
                                                    onClick={() =>
                                                        openNote(item)
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            margin: '0px',
                                                            padding:
                                                                '24px 9px 25px',
                                                            backgroundColor:
                                                                '#fff2f0',
                                                            borderRadius: '50%',
                                                            color: '#ff7558',
                                                            fontSize: '11px',
                                                            width: '60px',
                                                            height: '60px',
                                                            textAlign: 'center',
                                                            lineHeight: '1',
                                                            marginTop: '7px',
                                                        }}
                                                    >
                                                        Coach {list.length - i}
                                                    </span>
                                                    <i
                                                        style={{
                                                            marginTop: '25px',
                                                        }}
                                                        className="note-box-icon"
                                                    ></i>
                                                    <span
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            paddingLeft: '20px',
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                marginBottom:
                                                                    '3px',
                                                                width: '450px',
                                                            }}
                                                        >
                                                            {CommonService.getSummaryLine(
                                                                item.main,
                                                                160,
                                                            )}
                                                        </p>
                                                        <p className="date">
                                                            {moment(
                                                                new Date(
                                                                    item._createdAt,
                                                                ),
                                                            ).format(
                                                                'DD MMM YYYY',
                                                            )}
                                                        </p>
                                                    </span>
                                                </div>
                                            </span>
                                        )
                                    })}
                                </>
                            )}
                            {list.length < 1 && (
                                <div className="no-list">
                                    <img
                                        style={{ paddingBottom: '22px' }}
                                        src={EmptyStatIcon}
                                    />
                                    <h3>{emptyTitle}</h3>
                                    <p>{emptyMessage}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SamaListPopupView
