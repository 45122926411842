

import { DetailedHTMLProps, FC, SVGProps } from 'react';

export const SpectrumSvg: FC<
  DetailedHTMLProps<SVGProps<SVGSVGElement>, SVGSVGElement>
> = () => (
  <svg width="100" height="14" viewBox="0 0 180 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.4082C0 11.8033 0.49034 11.313 1.0952 11.313C1.70007 11.313 2.19041 11.8033 2.19041 12.4082V13.8584H0V12.4082Z" fill="white"/>
<path d="M3.3501 12.4082C3.3501 11.8033 3.84044 11.313 4.4453 11.313C5.05017 11.313 5.54051 11.8033 5.54051 12.4082V13.8584H3.3501V12.4082Z" fill="white"/>
<path d="M6.69995 12.4082C6.69995 11.8033 7.19029 11.313 7.79516 11.313C8.40002 11.313 8.89036 11.8033 8.89036 12.4082V13.8584H6.69995V12.4082Z" fill="white"/>
<path d="M10.0503 12.4082C10.0503 11.8033 10.5406 11.313 11.1455 11.313C11.7504 11.313 12.2407 11.8033 12.2407 12.4082V13.8584H10.0503V12.4082Z" fill="white"/>
<path d="M13.4001 12.4082C13.4001 11.8033 13.8905 11.313 14.4954 11.313C15.1002 11.313 15.5906 11.8033 15.5906 12.4082V13.8584H13.4001V12.4082Z" fill="white"/>
<path d="M36.8506 12.4082C36.8506 11.8033 37.3409 11.313 37.9458 11.313C38.5507 11.313 39.041 11.8033 39.041 12.4082V13.8584H36.8506V12.4082Z" fill="white"/>
<path d="M40.2002 12.4082C40.2002 11.8033 40.6905 11.313 41.2954 11.313C41.9003 11.313 42.3906 11.8033 42.3906 12.4082V13.8584H40.2002V12.4082Z" fill="white"/>
<path d="M43.5508 12.4082C43.5508 11.8033 44.0411 11.313 44.646 11.313C45.2508 11.313 45.7412 11.8033 45.7412 12.4082V13.8584H43.5508V12.4082Z" fill="white"/>
<path d="M46.9004 12.4082C46.9004 11.8033 47.3907 11.313 47.9956 11.313C48.6005 11.313 49.0908 11.8033 49.0908 12.4082V13.8584H46.9004V12.4082Z" fill="white"/>
<path d="M73.7012 12.4082C73.7012 11.8033 74.1915 11.313 74.7964 11.313C75.4012 11.313 75.8916 11.8033 75.8916 12.4082V13.8584H73.7012V12.4082Z" fill="white"/>
<path d="M87.1006 12.4082C87.1006 11.8033 87.5909 11.313 88.1958 11.313C88.8007 11.313 89.291 11.8033 89.291 12.4082V13.8584H87.1006V12.4082Z" fill="white"/>
<path d="M97.1514 12.4082C97.1514 11.8033 97.6417 11.313 98.2466 11.313C98.8514 11.313 99.3418 11.8033 99.3418 12.4082V13.8584H97.1514V12.4082Z" fill="white"/>
<path d="M103.851 12.4082C103.851 11.8033 104.341 11.313 104.946 11.313C105.551 11.313 106.041 11.8033 106.041 12.4082V13.8584H103.851V12.4082Z" fill="white"/>
<path d="M123.952 12.4082C123.952 11.8033 124.442 11.313 125.047 11.313C125.652 11.313 126.142 11.8033 126.142 12.4082V13.8584H123.952V12.4082Z" fill="white"/>
<path d="M130.651 12.4082C130.651 11.8033 131.142 11.313 131.747 11.313C132.351 11.313 132.842 11.8033 132.842 12.4082V13.8584H130.651V12.4082Z" fill="white"/>
<path d="M150.751 12.4082C150.751 11.8033 151.242 11.313 151.847 11.313C152.452 11.313 152.942 11.8033 152.942 12.4082V13.8584H150.751V12.4082Z" fill="white"/>
<path d="M154.102 12.4082C154.102 11.8033 154.592 11.313 155.197 11.313C155.802 11.313 156.292 11.8033 156.292 12.4082V13.8584H154.102V12.4082Z" fill="white"/>
<path d="M157.452 12.4082C157.452 11.8033 157.942 11.313 158.547 11.313C159.152 11.313 159.642 11.8033 159.642 12.4082V13.8584H157.452V12.4082Z" fill="white"/>
<path d="M160.802 12.4082C160.802 11.8033 161.292 11.313 161.897 11.313C162.502 11.313 162.992 11.8033 162.992 12.4082V13.8584H160.802V12.4082Z" fill="white"/>
<path d="M171.11 12.4082C171.11 11.8033 171.6 11.313 172.205 11.313C172.81 11.313 173.3 11.8033 173.3 12.4082V13.8584H171.11V12.4082Z" fill="white"/>
<path d="M174.459 12.4082C174.459 11.8033 174.95 11.313 175.555 11.313C176.16 11.313 176.65 11.8033 176.65 12.4082V13.8584H174.459V12.4082Z" fill="white"/>
<path d="M177.81 12.4082C177.81 11.8033 178.3 11.313 178.905 11.313C179.51 11.313 180 11.8033 180 12.4082V13.8584H177.81V12.4082Z" fill="white"/>
<path d="M120.602 10.1457C120.602 9.54088 121.092 9.05054 121.697 9.05054C122.302 9.05054 122.792 9.54088 122.792 10.1457V13.8586H120.602V10.1457Z" fill="white"/>
<path d="M137.352 10.9941C137.352 10.3893 137.842 9.89893 138.447 9.89893C139.052 9.89893 139.542 10.3893 139.542 10.9941V13.9999H137.352V10.9941Z" fill="white"/>
<path d="M147.659 10.9941C147.659 10.3893 148.15 9.89893 148.754 9.89893C149.359 9.89893 149.85 10.3893 149.85 10.9941V13.7171H147.659V10.9941Z" fill="white"/>
<path d="M164.151 10.1457C164.151 9.54088 164.642 9.05054 165.247 9.05054C165.851 9.05054 166.342 9.54088 166.342 10.1457V13.8586H164.151V10.1457Z" fill="white"/>
<path d="M167.502 8.73168C167.502 8.12682 167.992 7.63647 168.597 7.63647C169.202 7.63647 169.692 8.12681 169.692 8.73168V13.8587H167.502V8.73168Z" fill="white"/>
<path d="M127.43 10.4287C127.43 9.82384 127.921 9.3335 128.525 9.3335C129.13 9.3335 129.621 9.82384 129.621 10.4287V13.8587H127.43V10.4287Z" fill="white"/>
<path d="M134.001 10.1457C134.001 9.54088 134.492 9.05054 135.097 9.05054C135.702 9.05054 136.192 9.54088 136.192 10.1457V13.8586H134.001V10.1457Z" fill="white"/>
<path d="M140.701 1.66088C140.701 1.05601 141.192 0.565674 141.796 0.565674C142.401 0.565674 142.892 1.05601 142.892 1.66088V13.8586H140.701V1.66088Z" fill="white"/>
<path d="M144.052 1.66088C144.052 1.05601 144.542 0.565674 145.147 0.565674C145.752 0.565674 146.242 1.05601 146.242 1.66088V13.8586H144.052V1.66088Z" fill="white"/>
<path d="M56.9507 10.4287C56.9507 9.82384 57.441 9.3335 58.0459 9.3335C58.6508 9.3335 59.1411 9.82384 59.1411 10.4287V13.8587H56.9507V10.4287Z" fill="white"/>
<path d="M90.4512 8.73168C90.4512 8.12682 90.9415 7.63647 91.5464 7.63647C92.1512 7.63647 92.6416 8.12681 92.6416 8.73168V13.8587H90.4512V8.73168Z" fill="white"/>
<path d="M113.901 1.37816C113.901 0.773298 114.392 0.282959 114.997 0.282959C115.601 0.282959 116.092 0.773299 116.092 1.37816V13.8587H113.901V1.37816Z" fill="white"/>
<path d="M117.251 1.37816C117.251 0.773298 117.741 0.282959 118.346 0.282959C118.951 0.282959 119.441 0.773299 119.441 1.37816V13.8587H117.251V1.37816Z" fill="white"/>
<path d="M100.501 9.86278C100.501 9.25792 100.991 8.76758 101.596 8.76758C102.201 8.76758 102.691 9.25792 102.691 9.86278V13.9999H100.501V9.86278Z" fill="white"/>
<path d="M110.551 6.89306C110.551 6.28819 111.042 5.79785 111.646 5.79785C112.251 5.79785 112.742 6.28819 112.742 6.89306V13.717H110.551V6.89306Z" fill="white"/>
<path d="M67.001 1.37816C67.001 0.773298 67.4913 0.282959 68.0962 0.282959C68.701 0.282959 69.1914 0.773299 69.1914 1.37816V13.8587H67.001V1.37816Z" fill="white"/>
<path d="M63.6504 4.489C63.6504 3.88414 64.1407 3.3938 64.7456 3.3938C65.3505 3.3938 65.8408 3.88414 65.8408 4.489V13.8584H63.6504V4.489Z" fill="white"/>
<path d="M77.0508 3.3579C77.0508 2.75304 77.5411 2.2627 78.146 2.2627C78.7508 2.2627 79.2412 2.75303 79.2412 3.3579V13.8586H77.0508V3.3579Z" fill="white"/>
<path d="M84.0088 3.92333C84.0088 3.31846 84.4991 2.82812 85.104 2.82812C85.7089 2.82812 86.1992 3.31846 86.1992 3.92333V13.8584H84.0088V3.92333Z" fill="white"/>
<path d="M93.8008 3.92333C93.8008 3.31846 94.2911 2.82812 94.896 2.82812C95.5008 2.82812 95.9912 3.31846 95.9912 3.92333V13.8584H93.8008V3.92333Z" fill="white"/>
<path d="M107.201 1.0952C107.201 0.49034 107.691 0 108.296 0C108.901 0 109.391 0.49034 109.391 1.0952V13.8586H107.201V1.0952Z" fill="white"/>
<path d="M30.1504 9.29711C30.1504 8.69225 30.6407 8.2019 31.2456 8.2019C31.8505 8.2019 32.3408 8.69224 32.3408 9.29711V13.8585H30.1504V9.29711Z" fill="white"/>
<path d="M33.5 7.03466C33.5 6.42979 33.9903 5.93945 34.5952 5.93945C35.2001 5.93945 35.6904 6.42979 35.6904 7.03466V13.8586H33.5V7.03466Z" fill="white"/>
<path d="M50.2505 1.37816C50.2505 0.773298 50.7408 0.282959 51.3457 0.282959C51.9506 0.282959 52.4409 0.773299 52.4409 1.37816V13.8587H50.2505V1.37816Z" fill="white"/>
<path d="M53.6006 1.37816C53.6006 0.773298 54.0909 0.282959 54.6958 0.282959C55.3007 0.282959 55.791 0.773299 55.791 1.37816V13.8587H53.6006V1.37816Z" fill="white"/>
<path d="M60.3008 1.37816C60.3008 0.773298 60.7911 0.282959 61.396 0.282959C62.0008 0.282959 62.4912 0.773299 62.4912 1.37816V13.8587H60.3008V1.37816Z" fill="white"/>
<path d="M70.3506 1.37816C70.3506 0.773298 70.8409 0.282959 71.4458 0.282959C72.0507 0.282959 72.541 0.773299 72.541 1.37816V13.8587H70.3506V1.37816Z" fill="white"/>
<path d="M80.4009 1.37816C80.4009 0.773298 80.8912 0.282959 81.4961 0.282959C82.1009 0.282959 82.5913 0.773299 82.5913 1.37816V13.8587H80.4009V1.37816Z" fill="white"/>
<path d="M16.7502 1.37816C16.7502 0.773298 17.2406 0.282959 17.8454 0.282959C18.4503 0.282959 18.9407 0.773299 18.9407 1.37816V13.8587H16.7502V1.37816Z" fill="white"/>
<path d="M20.1003 1.37816C20.1003 0.773298 20.5907 0.282959 21.1955 0.282959C21.8004 0.282959 22.2908 0.773299 22.2908 1.37816V13.8587H20.1003V1.37816Z" fill="white"/>
<path d="M23.4502 1.37816C23.4502 0.773298 23.9405 0.282959 24.5454 0.282959C25.1503 0.282959 25.6406 0.773299 25.6406 1.37816V13.8587H23.4502V1.37816Z" fill="white"/>
<path d="M26.8003 1.37816C26.8003 0.773298 27.2906 0.282959 27.8955 0.282959C28.5004 0.282959 28.9907 0.773299 28.9907 1.37816V13.8587H26.8003V1.37816Z" fill="white"/>
</svg>
);
