import { userConstants } from "../_constants";
import axios from "axios";
import * as Sentry from '@sentry/react'

const ratingUpdate = (id, data, cb) => {
    const requestOptions = {
        method: "post",
        url: `${userConstants.BASE_URL}booking/${id}/session/rate`,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    axios(requestOptions).then(function () {
        cb();
    }).catch(function (e) {
        Sentry.captureException(e)
        console.log(e);
    });
};

const getToken = (id, cb) => {
    axios.get(`${userConstants.BASE_URL}booking/${id}/session`).then(function (response) {
        cb(response);
    }).catch(function (e) {
        Sentry.captureException(e)
        console.log(e);
    });
};

const sessionEnd = (id, cb) => {
    const requestOptions = {
        method: "delete",
        url: `${userConstants.BASE_URL}booking/${id}/session`,
        headers: {
            "Content-Type": "application/json"
        }
    };
    axios(requestOptions).then(function () {
        cb();
    }).catch(function (e) {
        Sentry.captureException(e)
        console.log(e);
    });
};

export const VideService = {
    getToken,
    ratingUpdate,
    sessionEnd
};
