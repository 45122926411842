export const toBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = (error) => reject(error)
        } else {
            resolve('')
        }
    })
