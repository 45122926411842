export interface Static {
    key: string
    name: string
    colour?: string
}

export const getStaticsValue = (staticKey: string, key: string) => {
    const statics: Static[] = localStorage.getItem('statics')
        ? JSON.parse(localStorage.getItem('statics') as string)[staticKey]
        : {}

    const data = statics.reduce(
        (acc: { [key: string]: string }, current: Static) => {
            acc[current.key] = current.name
            return acc
        },
        {},
    )

    return data[key]
}
