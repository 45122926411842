import { motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../utils/misc'

interface Props {
    message: string
}

export const FormValidationMessage: React.FC<Props> = ({ message }) => {
    return (
        <motion.div
            className={classNames(
                'rounded-[.25rem]',
                'flex items-center',
                'text-sm',
                'px-4 py-3 space-x-2',
                'bg-[#ffefef] text-accent-2',
            )}
        >
            <span>{message}</span>
        </motion.div>
    )
}
