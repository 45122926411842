import { userConstants } from '../_constants'
import { getCoachImage } from '../_services/coach-image'
export function picture(
    state = { picture: getCoachImage() },
    action: { type: string; payload: string },
) {
    switch (action.type) {
        case userConstants.GET_PICTURE:
            return {
                picture: action.payload,
            }
        default:
            return state
    }
}
