import { userConstants } from "../_constants";
import axios from "axios";
import moment from "moment";
import * as Sentry from '@sentry/react'

const createAvailability = (id, data) => {
	const requestOptions = {
		method: "put",
		url: `${userConstants.BASE_URL}coach/${id}/availabilities`,
		headers: {
			"Content-Type": "application/json"
		},
		data: data
	};
	return axios(requestOptions)
};

const getMonthString = (addMonth) => {
	let month = moment(new Date());
	month.add(addMonth, 'M');
	return month.format('MMMM').toLowerCase()
}

const getAvailability = (id) => {
	const requests = []
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(0)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(1)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(2)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(3)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(4)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(5)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/month/${getMonthString(6)}`));
	return axios.all(requests).then(axios.spread((...responses) => {
		let alldates = [];
		for (let i = 0; i < responses.length; i++) {
			alldates = alldates.concat(responses[i].data);
		}

		return alldates
	})).catch(error => {
		Sentry.captureException(error)
	})
}

const getBusyTimes = (id) => {
	const requests = []
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(0)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(1)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(2)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(3)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(4)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(5)}`));
	requests.push(axios.get(`${userConstants.BASE_URL}coach/${id}/availabilities/busy/month/${getMonthString(6)}`));

	return axios.all(requests).then(axios.spread((...responses) => {
		let alldates = [];
		for (let i = 0; i < responses.length; i++) {
			alldates = alldates.concat(responses[i].data);
		}
		return alldates.map((date) => {
			return {
				to: new Date(date.to),
				from: new Date(date.from)
			}
		})
	})).catch(error => {
		Sentry.captureException(error)
	})
}


export const AvailService = {
	createAvailability,
	getAvailability,
	getBusyTimes
};
