import { Formik, Field } from 'formik'
import { classNames } from '../../../utils/misc'

interface Props {
    validate: any
    signIn: any
}

export const AppleConnectionForm = ({ validate, signIn }: Props) => {
    return (
        <Formik
            validate={validate}
            onSubmit={signIn}
            validateOnChange
            initialValues={{ email: '', password: '' }}
        >
            {({ handleSubmit, isValid, errors, isSubmitting }) => (
                <form
                    className="entry-form mt-3"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <label htmlFor="email">
                        <span className="mb-2">Apple ID</span>
                        <Field
                            className={classNames(
                                'text-[#10123a] pl-[7px] h-[50px]',
                                'color-[#10123a]',
                                'border-none outline-none rounded-[6px]',
                                'bg-[#f4f5f8] font-[15px]',
                            )}
                            name="email"
                            type="email"
                            autoComplete="off"
                            placeholder="Enter an email adress"
                        />
                        {errors.email && (
                            <span className="error-message">
                                {errors.email}
                            </span>
                        )}
                    </label>
                    <label htmlFor="password" className="mt-4">
                        <span>App-specific password</span>
                        <span className="subtext mb-2">
                            For information on how to set up an app specific
                            password, please visit:{' '}
                            <a
                                href="https://support.apple.com/en-us/HT204397"
                                target="_blank"
                            >
                                https://support.apple.com/en-us/HT204397
                            </a>
                            .
                        </span>
                        <Field
                            className={classNames(
                                'text-[#10123a] pl-[7px] h-[50px]',
                                'color-[#10123a]',
                                'border-none outline-none rounded-[6px]',
                                'bg-[#f4f5f8] font-[15px]',
                            )}
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder="Enter an app-specific password"
                        />
                        {errors.password && (
                            <span className="error-message">
                                {errors.password}
                            </span>
                        )}
                    </label>
                    <div className="mt-4">
                        <button
                            className={classNames(
                                'flex items-center justify-center',
                                'w-full h-[30px] border-none outline-none',
                                'bg-[#ff7558] rounded-[6px]',
                                'disabled:bg-[#d3d6db]',
                            )}
                            type="submit"
                            disabled={!isValid || isSubmitting}
                        >
                            <span>Connect</span>
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}
