import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class Autocomplete extends Component {
    static propTypes = {
        options: PropTypes.instanceOf(Array).isRequired,
        selected: PropTypes.any,
        onSelectCallback: PropTypes.any,
        placeholder: PropTypes.any,
    }

    toggleContainer: any
    constructor(props: any) {
        super(props)
        this.state = {
            activeOption: 0,
            filteredOptions: [],
            selectedOptions: (this.props as any).selected || [],
            showOptions: false,
            userInput: '',
        }
        this.toggleContainer = React.createRef()
        this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    }

    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler)
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler)
    }
    onClickOutsideHandler(event: any) {
        if (
            (this.state as any).showOptions &&
            !this.toggleContainer.current.contains(event.target)
        ) {
            this.setState((state: any) => ({
                activeOption: 0,
                filteredOptions: [],
                showOptions: !state.showOptions,
            }))
        }
    }
    onChange = (e: any) => {
        const { options } = this.props as any
        const userInput = e.currentTarget.value

        // this is the autocomplete in action
        const filteredOptions = options.filter(
            (optionName: any) =>
                optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
        )

        this.setState({
            activeOption: 0,
            filteredOptions,
            showOptions: userInput ? true : false,
            userInput: e.currentTarget.value,
        })
    }

    selectOption = (text: any) => {
        if (text) {
            const { selectedOptions } = this.state as any
            selectedOptions.push(text)
            if ('onSelectCallback' in this.props) {
                ;(this.props as any).onSelectCallback(selectedOptions)
            }
            this.setState({
                activeOption: 0,
                filteredOptions: [],
                selectedOptions,
                showOptions: false,
                userInput: '',
            })
        }
    }

    onClick = (e: any) => {
        const { selectedOptions } = this.state as any
        if (selectedOptions.indexOf(e.currentTarget.innerText) === -1) {
            this.selectOption(e.currentTarget.innerText)
        }
    }
    onDropdown = (e: any) => {
        this.setState((state: any, props: any) => ({
            activeOption: 0,
            filteredOptions: props.options,
            showOptions: !state.showOptions,
        }))
    }
    onKeyDown = (e: any) => {
        const { activeOption, filteredOptions } = this.state as any
        if (e.keyCode === 13) {
            this.selectOption(filteredOptions[activeOption])
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return
            }
            this.setState({ activeOption: activeOption - 1 })
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                return
            }
            this.setState({ activeOption: activeOption + 1 })
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            onDropdown,
            state: {
                // @ts-ignore
                activeOption,
                // @ts-ignore
                filteredOptions,
                // @ts-ignore
                selectedOptions,
                // @ts-ignore
                showOptions,
                // @ts-ignore
                userInput,
            },
        } = this
        let optionList
        if (showOptions) {
            filteredOptions.filter((optionName: any) =>
                selectedOptions.includes(optionName),
            )

            if (filteredOptions.length) {
                optionList = (
                    <ul className="options">
                        {filteredOptions.map(
                            (optionName: any, index: number) => {
                                let className
                                if (index === activeOption) {
                                    className = 'option-active'
                                }

                                return (
                                    <li
                                        className={className}
                                        key={optionName}
                                        onClick={onClick}
                                    >
                                        {optionName}
                                    </li>
                                )
                            },
                        )}
                    </ul>
                )
            } else {
                optionList = (
                    <div className="no-options">
                        <em>No Option!</em>
                    </div>
                )
            }
        }
        let selectedList
        if (selectedOptions.length) {
            selectedList = (
                <ul className="sub-options">
                    {selectedOptions.map((optionName: any, index: number) => {
                        return (
                            <li className={'option-item'} key={index}>
                                {optionName}
                                <span
                                    className="close-item"
                                    onClick={(e) => {
                                        const { selectedOptions } = this
                                            .state as any
                                        selectedOptions.splice(
                                            selectedOptions.indexOf(optionName),
                                            1,
                                        )
                                        this.setState({
                                            activeOption: 0,
                                            filteredOptions: [],
                                            selectedOptions,
                                            showOptions: false,
                                            userInput: '',
                                        })
                                    }}
                                >
                                    &times;
                                </span>
                            </li>
                        )
                    })}
                </ul>
            )
        }
        return (
            <div
                ref={this.toggleContainer}
                className={
                    showOptions
                        ? 'filter-dropdown-container open'
                        : 'filter-dropdown-container'
                }
            >
                <div className="filter-dropdown">
                    <input
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={userInput}
                        placeholder={(this.props as any).placeholder}
                    />
                    <button
                        className="down-carret"
                        type="button"
                        onClick={onDropdown}
                    >
                        <span className="arrow down"></span>
                    </button>
                </div>
                {optionList}
                {selectedList}
            </div>
        )
    }
}

export default Autocomplete
