import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Popup from 'reactjs-popup'
import { classNames } from '../../../utils/misc'

interface Props {
    disconnectCalendar: any
    setShowCalendarSelection: any
}

export const DropdownMenu = ({
    disconnectCalendar,
    setShowCalendarSelection,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="dropdown">
            <Popup
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }}
                modal
                nested
                lockScroll
                className="alertBox"
            >
                <>
                    <div>
                        Are you sure you wish to disconnect the calendar syncing
                    </div>
                    <div>
                        <button
                            className="relative btn btn-primary btn-bolck"
                            type="button"
                            onClick={disconnectCalendar}
                        >
                            Yes
                        </button>
                        <button
                            className="relative ml-2 btn btn-light btn-bolck"
                            type="button"
                            onClick={close}
                        >
                            Cancel
                        </button>
                    </div>
                </>
            </Popup>
            <Dropdown>
                <Dropdown.Toggle
                    className={classNames(
                        'flex text-[100%] items-center color-[#0052b4]',
                        'bg-transparent  border-none m-0 p-0',
                    )}
                    variant="success"
                    id="dropdown-basic"
                >
                    <span>Edit</span>
                    <img
                        src="/assets/images/edit-pencil.png"
                        alt="Edit icon"
                        className="icon ml-[10px]"
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        className="pl-[14px] pr-[8px] border-b-[1px] border-b-solid border-b-[#e8ecef]"
                        onClick={() => setShowCalendarSelection(true)}
                    >
                        Change calendar
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="text-[#fe3b30]"
                        onClick={() => setIsOpen(true)}
                    >
                        Disconnect
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
