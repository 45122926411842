import { userConstants } from '../_constants'
import axios from 'axios'

export const getCategories = () => {
    return axios
        .get(`${userConstants.BASE_URL}resource/coach/category`)
        .then((response) => {
            return response.data
        })
}

export const getResources = () => {
    return axios
        .get(`${userConstants.BASE_URL}resource/coach?limit=3000`)
        .then((response) => {
            return response.data
        })
}
