import { IsISO8601, validateSync } from 'class-validator'
import { addHours } from 'date-fns'
import { assign } from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { of, map as rxmap, switchMap, tap } from 'rxjs'
import { TrackingService } from '../_services/tracking.service'
import { dataURItoBlob } from '../misc/images'
import { RescheduleBookingModel } from '../models/request/booking/reschedule-booking.model'
import { CoachNotification } from '../models/response/coach-notification'
import { Booking, BookingCancelDetails } from '../Sessions/types/Booking'
import { HttpMethod } from '../utils/constants'
import { request } from '../utils/misc/request'
import { Resettable } from '../utils/misc/resettable'
import { dehydrateToStorage, hydrateFromStorage } from '../utils/misc/stoage'
import { toBase64 } from '../utils/misc/to-base-64'

// This needs doing "properly" eventually
const userInfo = localStorage.getItem('userinfo')
const coachId = userInfo ? JSON.parse(userInfo)._id.toString() : null

const COACHEE_PROFILE_PICTURE_INFORMATION_PREFIX_KEY =
    'SAMA:COACHEE-PICTURE-INFORMATION/'
const HOURS_TO_CACHE_LOGO = 1

class CoacheePictureInformation {
    public data!: string | null

    @IsISO8601()
    public expires!: string
}

export class CoachStore implements Resettable {
    public id: string = coachId
    public bookings: Booking[] = []
    public hasCoachNotifications: boolean = false
    public coachNotifications: CoachNotification[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public processBookings(): void {
        this.bookings.map((booking) => {
            this.getCoacheeProfilePicture(booking.fromCoachee._id).subscribe({
                next(response) {
                    if (response) {
                        booking.fromCoachee.image = URL.createObjectURL(
                            dataURItoBlob(response),
                        )
                    }
                },
            })
            return booking
        })
    }

    @action
    public getSessionUrl(bookingId: string) {
        return request<never, any>(`booking/${bookingId}/url`, HttpMethod.GET, {
            silentErrors: true,
        })
    }

    @action
    public getBookings() {
        return request<never, Booking[]>(
            `coach/${this.id}/bookings?limit=50`,
            HttpMethod.GET,
            {
                silentErrors: true,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.bookings = response.data.sort((a, b) => {
                            return a.date > b.date ? 1 : -1
                        })
                        this.processBookings()
                    }
                })
            }),
        )
    }

    @action
    public getCoachNotifications() {
        return request<never, CoachNotification[]>(
            `coach-notification/${this.id}`,
            HttpMethod.GET,
            {
                silentErrors: true,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.coachNotifications = response.data.sort((a, b) => {
                            return a._createdAt < b._createdAt ? 1 : -1
                        })
                        this.hasCoachNotifications =
                            this.coachNotifications.filter(
                                (notification) =>
                                    notification.status === 'unread',
                            ).length > 0
                        console.log(this.hasCoachNotifications)
                    }
                })
            }),
        )
    }

    /**
     *
     * THIS NEEDS A MODEL NEXT TIME IT IS TOUCHED
     */
    @action
    public updateCoachNotifications(notification: CoachNotification) {
        return request(
            `coach-notification/${this.id}/${notification._id}`,
            HttpMethod.PUT,
            {
                body: {
                    status: 'unread',
                },
                silentErrors: true,
            },
        ).pipe(
            tap(() => {
                runInAction(() => {
                    this.getCoachNotifications().subscribe()
                })
            }),
        )
    }

    @action
    public rescheduleBooking(model: RescheduleBookingModel) {
        return request(
            `booking/${model._id}/force-reschedule`,
            HttpMethod.POST,
            {
                body: model.getRequestBody(),
                silentErrors: true,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.getBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public cancelBooking(
        booking: Booking,
        BookingCancelDetails?: BookingCancelDetails,
    ) {
        return request(`booking/${booking._id}`, HttpMethod.DELETE, {
            silentErrors: true,
            body: BookingCancelDetails,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.status === 204) {
                        this.getBookings().subscribe()
                        const bookingDate = new Date(booking.date)
                        const now = new Date()
                        // if we are 5 mins late we are late
                        const less_24hours =
                            bookingDate.getTime() - now.getTime() <
                            24 * 60 * 60 * 1000
                        TrackingService.send('session_cancelled', {
                            booking_id: booking._id,
                            less_24hours: less_24hours,
                            is_video_session: booking.isVideoSession,
                            coachee_id: booking.fromCoachee._id,
                            coachee_company_id:
                                booking.fromCoachee?.company?._id,
                            coachee_company_label:
                                booking.fromCoachee?.company?.label,
                        })
                    }
                })
            }),
        )
    }

    @action
    public getCoacheeProfilePicture(coacheeId: string) {
        const userPictureInformation =
            hydrateFromStorage<CoacheePictureInformation>(
                COACHEE_PROFILE_PICTURE_INFORMATION_PREFIX_KEY + coacheeId,
            )
        const model = assign(
            new CoacheePictureInformation(),
            userPictureInformation,
        )
        if (userPictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)
            if (now <= expiresAt && userPictureInformation.data) {
                return of(userPictureInformation.data)
            }
        }
        return request<never, Blob>(
            `coachee/${coacheeId}/picture`,
            HttpMethod.GET,
            {
                query: { size: 'thumbnail' },
                responseType: 'blob',
                silentErrors: true,
            },
        ).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    let usedImage: string | null = image
                    if (image === 'data:application/json;base64,e30=') {
                        usedImage = null
                    }
                    runInAction(() =>
                        this.storeCoacheeProfileImage(coacheeId, usedImage),
                    )
                }
            }),
        )
    }

    @action
    public storeCoacheeProfileImage(coacheeId: string, image: string | null) {
        dehydrateToStorage(
            COACHEE_PROFILE_PICTURE_INFORMATION_PREFIX_KEY + coacheeId,
            {
                data: image,
                expires: addHours(new Date(), HOURS_TO_CACHE_LOGO),
            },
        )
    }
    @action
    public reset(): void {
        this.bookings = []
    }
}
