import { useField } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { classNames, Option } from '../utils/misc'
// import { FormValidationMessage } from '../layout'
import { ListBox } from './list-box'

interface Props {
    name: string
    label?: string | React.ReactNode
    value?: Option | null
    className?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
    onChange?: any
}

export const SelectInput: React.FC<Props> = ({
    name,
    label,
    value,
    className,
    placeholder,
    options,
    disabled,
    onChange,
}) => {
    const [field, meta, helpers] = useField(name)

    useEffect(() => {
        if (value) {
            helpers.setValue(value)
        }
    }, [value])

    const onSelect = useCallback((option: Option) => {
        if (onChange) {
            onChange(option)
        }
        helpers.setValue(option)
    }, [])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            <ListBox
                onSelect={onSelect}
                value={field.value}
                options={options}
                placeholder={placeholder}
                buttonClassName="!h-12"
                optionsClassName="!w-full bg-[#f6f7f9] !divide-[#10123A]/[3%]"
                optionClassName="text-accent-2"
                optionClassNameActive="!text-accent"
                optionClassNameSelected="!text-accent flex-row-reverse justify-between"
                disabled={disabled}
            />
            {meta.touched && meta.error && (
                <div className="mt-[10px]">
                    {/* <FormValidationMessage message={meta.error[0]} /> */}
                </div>
            )}
        </div>
    )
}
