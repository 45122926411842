import axios from 'axios';
import { userConstants } from '../_constants';
import * as Sentry from '@sentry/react'
//https://stackoverflow.com/questions/43210516/how-to-redirect-from-axios-interceptor-with-react-router-v4
// You can use any cookie library or whatever
// library to access your client storage.
//import cookie from 'cookie-machine';

/*request interceptors*/
axios.interceptors.request.use(function (config) {
    let token = "";
    if (window.localStorage.getItem('user')) {
        token = JSON.parse(localStorage.getItem('user')).accessToken;
    }

    if (config.url.includes(userConstants.BASE_URL) && token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.service = 'coachWeb';
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

let isRefreshing = false;
let requests = []
/*response interceptors*/
axios.interceptors.response.use(response => {
    return response;
}, function (error) {
    const originalReq = error.config;
    if (error?.response?.status === 401) {
        if (originalReq.url.includes('auth/refresh')) {
            window.localStorage.clear();
            window.location.replace("/login");
        } else {
            let user = JSON.parse(localStorage.getItem('user'));
            if (!isRefreshing && user && user.accessToken) {
                isRefreshing = true
                const { accessToken } = user;
                const bodyReq = { accessToken };
                return axios.post(`${userConstants.BASE_URL}auth/refresh`, bodyReq, { withCredentials: true })
                    .then(function (response) {
                        user.accessToken = response.data.accessToken;
                        localStorage.setItem("user", JSON.stringify(user));
                        let tkn = response.data.accessToken;
                        originalReq.headers['Authorization'] = `Bearer ${tkn}`;
                        requests.forEach(cb => cb(tkn))
                        requests = []
                        return axios(originalReq);
                    })
                    .then((response) => {
                        isRefreshing = false
                        return response
                    })
                    .catch(function (error) {
                        isRefreshing = false
                        Sentry.captureException(error)
                        if (error.response.status === 401 || error.response.status === 403) {
                            window.localStorage.clear();
                            window.location.replace("/login");
                        }
                    });
            } else {
                return new Promise((resolve) => {
                    // Put resolve in the queue, save it in a function form, and execute it directly after token refreshes
                    requests.push((token) => {
                        originalReq.headers['Authorization'] = `Bearer ${token}`;
                        resolve(axios(originalReq));
                    })
                })
            }
        }
    } else if (error?.response?.status === 403) {
        window.localStorage.clear();
        window.location.replace("/login");
    } else {
        Sentry.captureException(error)
    }

    if (('message' in error) && error.message === 'Network Error') {
        Sentry.captureException(error)
        console.log("error: ", JSON.stringify(error));
    }

    return Promise.reject(error);
});
