import { useState, useEffect } from 'react'
import { CommonService } from '../_services/common.service'
import './styles/goals.css'
import GoalsView from './goals-view'
import moment from 'moment'
import noGoalsImg from '../assets/no-goals.svg'

const Goals = ({ coacheeId }: { coacheeId: string }) => {
    const [goalsData, setGoalsData] = useState([])
    const [currentCoachee, setCurrentCoachee] = useState<string>()
    const [isGoalViewOpen, openGoalView] = useState(false)
    const [currentItem, setCurrentItem] = useState()

    useEffect(() => {
        setCurrentCoachee(coacheeId)
        CommonService.getGoal(coacheeId, function (res: any) {
            setGoalsData(res.data)
        })
    }, [])

    if (coacheeId) {
        if (currentCoachee !== coacheeId) {
            setCurrentCoachee(coacheeId)
            CommonService.getGoal(coacheeId, function (res: any) {
                setGoalsData(res.data)
            })
        }
    }

    function openGoalsView(item: any) {
        setCurrentItem(item)
        openGoalView(true)
    }

    return (
        <>
            <div className="custom-list">
                <div style={{ margin: '5px 27px 15px' }}>
                    Coachee adds goals. You can view but not edit.
                </div>
                {goalsData.length > 0 && (
                    <>
                        {goalsData.map((item: any, i: number) => (
                            <div
                                key={i}
                                className={
                                    item.completedAt
                                        ? 'goal-conainer-completed'
                                        : 'goal-container'
                                }
                                onClick={() => openGoalsView(item)}
                            >
                                <span className="goal-label">
                                    {item.label.substring(0, 120)}
                                </span>
                                {!item.completedAt && (
                                    <>
                                        <div className="goal-due-by-label">
                                            {item.dueDate
                                                ? 'Due by ' +
                                                  moment(item.dueDate).format(
                                                      'DD MMMM YYYY',
                                                  )
                                                : 'No due date'}
                                        </div>
                                        <div className="goal-progress-bar">
                                            <div
                                                className={`goal-progress-${item.progress}`}
                                            ></div>
                                        </div>
                                    </>
                                )}
                                {item.completedAt && (
                                    <>
                                        <div className="goal-due-by-label">
                                            {' '}
                                            Completed{' '}
                                            {moment(item.completedAt).format(
                                                'DD MMMM YYYY',
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                        <GoalsView
                            key={1}
                            item={currentItem}
                            open={isGoalViewOpen}
                            close={openGoalView}
                        />
                    </>
                )}
                {goalsData.length < 1 && (
                    <div className="no-goals">
                        <img src={noGoalsImg} />
                        <h3>No goals added</h3>
                        <p>
                            Encourage your coachee to create and track SMART
                            (specific, measurable, actionable, realistic and
                            time bound) goals.
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export default Goals
