import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userActions } from '../_actions/user.actions'

class LoginPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.props.logout()
        this.props.clearHeader()
    }

    render() {
        return (
            <div className="pink-container bg-pink">
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <div className="semi-article">
                            <figure>
                                <img
                                    src="assets/logo-dark.svg"
                                    width="120"
                                    alt="logo"
                                    className="logo"
                                />
                            </figure>
                            <h2 className="m-t-60">
                                Welcome to the <br /> Sama team!
                            </h2>
                            <p>
                                We are excited that you are part of our mission
                                <br /> to make the world an engaged and inspired
                                <br /> place.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="login-form">
                            <Formik
                                initialValues={{ email: '', password: '' }}
                                onSubmit={(values) => {
                                    return this.props.login(values)
                                }}
                                validate={(values) => {
                                    const errors: any = {}
                                    if (!values.email) {
                                        errors.email =
                                            'The email entered is not valid'
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                            values.email,
                                        )
                                    ) {
                                        errors.email =
                                            'The email entered is not valid'
                                    }
                                    return errors
                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isValid,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }: any = props
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                            data-testid="form"
                                        >
                                            <div>
                                                <h3>
                                                    To verify your identity we{' '}
                                                    <br /> need you to sign in
                                                </h3>
                                                <div className="error">
                                                    {values.loginError}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">
                                                        Email
                                                    </label>
                                                    <input
                                                        id="email"
                                                        data-testid="inputEmail"
                                                        placeholder="Type your email"
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                        className={
                                                            errors.email &&
                                                            touched.email
                                                                ? 'form-control text-input'
                                                                : 'form-control text-input'
                                                        }
                                                    />
                                                    {errors.email &&
                                                        touched.email && (
                                                            <div
                                                                className="error"
                                                                data-testid="errorForm"
                                                            >
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">
                                                        Password
                                                    </label>
                                                    <input
                                                        id="password"
                                                        data-testid="inputPass"
                                                        placeholder="Type your password"
                                                        type="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                        className={
                                                            errors.password &&
                                                            touched.password
                                                                ? 'form-control text-input'
                                                                : 'form-control text-input'
                                                        }
                                                    />
                                                    {errors.password &&
                                                        touched.password && (
                                                            <div className="error">
                                                                {
                                                                    errors.password
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="form-group">
                                                    <Link to="/forgot">
                                                        Forgot your password?
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="form-group btn-block-bottom">
                                                <button
                                                    type="submit"
                                                    data-testid="button"
                                                    className="relative btn btn-primary btn-bolck"
                                                    disabled={
                                                        !(isValid && dirty)
                                                    }
                                                >
                                                    {isSubmitting ? (
                                                        <div>
                                                            <span className="loading-spin"></span>
                                                            <span>
                                                                In progress
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div data-testid="awaitSubmit">
                                                            "Sign in"
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state: any) {
    const { loggingIn } = state.authentication
    return { loggingIn }
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    clearHeader: userActions.clearHeader,
}

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage)
export { connectedLoginPage as LoginPage }
