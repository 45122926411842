import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getClassNames } from '../../../_helpers/get-class-names'
import { ChangeCalendarForm } from './change-calendar-form'
import { DropdownMenu } from './dropdown-menu'
import './calendar-manager.css'

const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
})

interface Props {
    iconPath: string
    name: string
    provider: any
    connectFunction: any
    calendarInfo: any
    selectCalendar: any
    disconnectCalendar: any
    dispatch: any
}

export const GenericCalendarManager = connect(mapStateToProps)(
    ({
        iconPath,
        name,
        provider,
        connectFunction,
        calendarInfo,
        selectCalendar,
        disconnectCalendar,
        dispatch,
    }: Props) => {
        const isConnected = calendarInfo.provider === provider
        const calendarSelected = calendarInfo.calendar !== null
        const [showCalendarSelection, setShowCalendarSelection] =
            useState(false)

        useEffect(() => {
            if (isConnected && !calendarSelected) {
                setShowCalendarSelection(true)
            }
        }, [isConnected, calendarSelected, setShowCalendarSelection])

        return (
            <div
                className={getClassNames(
                    'calendar-manager',
                    isConnected && 'connected',
                    showCalendarSelection && 'open',
                )}
            >
                <div className="icon-area">
                    <img src={iconPath} className="icon-area-logo" />
                </div>

                <div className="text-area">
                    <span>{name}</span>

                    {!showCalendarSelection && (
                        <span className="connection-text">
                            {isConnected ? 'Connected' : 'Not connected'}
                        </span>
                    )}

                    {showCalendarSelection && (
                        <ChangeCalendarForm
                            selectCalendar={selectCalendar}
                            setShowCalendarSelection={setShowCalendarSelection}
                            calendarInfo={calendarInfo}
                        />
                    )}
                </div>

                {!isConnected && (
                    <div className="button-control-area">
                        <button onClick={connectFunction}>Connect</button>
                    </div>
                )}

                {isConnected && !showCalendarSelection && (
                    <div className="button-control-area">
                        <DropdownMenu
                            disconnectCalendar={disconnectCalendar}
                            setShowCalendarSelection={setShowCalendarSelection}
                        />
                    </div>
                )}
            </div>
        )
    },
)
