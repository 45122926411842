import Autocompletemulti from '../_components/Autocompletemulti'
function PrefessionalExp(props: any) {
    const { values, handleChange, setFieldValue } = props
    const { errors } = props
    const { touched } = props

    return (
        <>
            {' '}
            <h5 className="text-center">Professional experience</h5>
            <div className="form-group">
                <label htmlFor="yearsofexp">
                    Total years of professional experience
                </label>
                <input
                    id="experienceTotalYear"
                    placeholder="0"
                    type="number"
                    value={values.experienceTotalYear}
                    onChange={handleChange}
                    className="form-control half-number"
                />
                {errors.experienceTotalYear && touched.experienceTotalYear && (
                    <div className="error">{errors.experienceTotalYear}</div>
                )}
            </div>
            <div className="form-group">
                <label>Industry experience</label>
                <Autocompletemulti
                    options={props?.statics.industries}
                    selected={values.profIndexp}
                    onSelectCallback={(v: any) => {
                        setFieldValue('profIndexp', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.profIndexp && touched.profIndexp && (
                    <div className="error">{errors.profIndexp}</div>
                )}
            </div>
            <div className="form-group">
                <label>Functional experience</label>
                <Autocompletemulti
                    options={props?.statics.functions}
                    selected={values.profFunexp}
                    onSelectCallback={(v: any) => {
                        setFieldValue('profFunexp', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.profFunexp && touched.profFunexp && (
                    <div className="error">{errors.profFunexp}</div>
                )}
            </div>
            <div className="form-group">
                <label>Countries worked in</label>
                <Autocompletemulti
                    options={props?.statics.countries}
                    selected={values.experienceCountry}
                    onSelectCallback={(v: any) => {
                        setFieldValue('experienceCountry', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.experienceCountry && touched.experienceCountry && (
                    <div className="error">{errors.experienceCountry}</div>
                )}
            </div>
        </>
    )
}
export { PrefessionalExp }
