import { LegacyRef, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { TrackingService } from '../_services/tracking.service.js'
import Notifications from '../Notifications/notifications-view.js'
import { useStores } from '../utils/stores.js'

function Header(props: any) {
    const menuRef = useRef(null)
    const location = useLocation()
    const [isOpen, setOpen] = useState(false)
    const [isOpenNotifications, setOpenNotifications] = useState(false)
    const [isDetails, setDetails] = useState(
        location.pathname.includes('/details') ||
            location.pathname.includes('/change'),
    )
    const [isProfile, setProfile] = useState(location.pathname === '/profile')
    const { coach } = useStores()
    const onOpen = () => {
        setOpen((isOpen) => !isOpen)
    }

    const { picture } = props?.picture
    const { user } = props?.userreducer?.userinfo || {}

    useEffect(() => {
        if (coach.id)
            coach.getCoachNotifications().subscribe({
                next() {
                    setOpenNotifications(coach.hasCoachNotifications)
                },
            })

        TrackingService.send('screen_viewed', {
            Name: location.pathname.slice(1),
        })

        setDetails(
            location.pathname.includes('/details') ||
                location.pathname.includes('/change'),
        )
        setProfile(location.pathname === '/profile')

        /**
         * detectes when clicked on outside of element
         */
        function onClickOutsideMenuHandler(event: any) {
            // @ts-ignore
            if (menuRef && !menuRef?.current?.contains(event.target)) {
                setOpen(false)
                setOpenNotifications(false)
            }
        }
        // Bind the event listener
        document.addEventListener('click', onClickOutsideMenuHandler)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', onClickOutsideMenuHandler)
        }
    }, [menuRef, location])

    return (
        <header className={isDetails ? 'navbar nav no-access' : 'navbar nav'}>
            <div className="brand">
                <img
                    src="/assets/logo-dark.svg"
                    width="120"
                    alt="logo"
                    className="logo"
                />
            </div>
            {isProfile && (
                <div>
                    <h6>
                        <a href="/home">
                            <span className="fa fa-home text-orange"></span>{' '}
                            Back to dashboard
                        </a>
                    </h6>
                </div>
            )}

            <div className="navbar-inner">
                {isOpenNotifications && (
                    <div className="relative z-10 w-[450px] mr-[160px]">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>

                        <div className="absolute inset-0  mt-[55px]">
                            <Notifications showAllButton={true} limit={5} />
                        </div>
                    </div>
                )}
                <ul className="nav pull-right">
                    <li
                        className={isOpen ? 'dropdown show' : 'dropdown'}
                        ref={menuRef}
                    >
                        <button
                            type="button"
                            className="btn inline-flex bg-[#fafafa] hover:bg-[#f2f2f2] justify-center items-center rounded w-10 h-10"
                            onClick={() =>
                                setOpenNotifications(!isOpenNotifications)
                            }
                        >
                            <img
                                className="w-4 h-4"
                                src="/assets/images/bell.png"
                            />

                            {coach.hasCoachNotifications && (
                                <div className="mt-2 ml-4 absolute bg-[#FF8300] border-[1px] border-[#fafafa] rounded-full w-2 h-2"></div>
                            )}
                        </button>
                        <button
                            style={{ display: 'inline-flex' }}
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                            onClick={onOpen}
                        >
                            <p
                                style={{
                                    paddingTop: '12px',
                                    paddingRight: '4px',
                                }}
                            >
                                Hello {user?.firstName}
                            </p>
                            <img
                                className="userlogo"
                                src={picture}
                                alt="usrlogo"
                            />
                        </button>
                        <ul
                            className={
                                isOpen ? 'dropdown-menu show' : 'dropdown-menu'
                            }
                        >
                            <a href="/profile" className="dropdown-item">
                                {' '}
                                My profile
                            </a>
                            <a href="/login" className="dropdown-item">
                                {' '}
                                Log out
                            </a>
                        </ul>
                    </li>
                </ul>
            </div>
        </header>
    )
}
export { Header }
