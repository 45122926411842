import { createRoot } from 'react-dom/client'
import './_helpers/httpInterceptors'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { store } from './_helpers/store'
import { App } from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

Sentry.init({
    dsn: 'https://6ba9738a709f456fada1d56d75440f53@o784619.ingest.sentry.io/5950720',
    integrations: [],
    environment: import.meta.env.VITE_APP_NODE_ENV,
    release: import.meta.env.VITE_APP_SENTRY_RELEASE_NAME ?? 'unknown',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
})

let newNonce = null
const getNonce = (element: Element) => {
    const contentArray = element.getAttribute('content')?.split('style-src ')
    if (contentArray?.length === 2) {
        const styleSrc = contentArray[1].split(';')
        const content = styleSrc[0].split(' ')
        const contentString = content[content.length - 1]
            .replace('nonce-', '')
            .replace("'", '')

        return contentString.substring(0, contentString.length - 1)
    }
}

const metaElements = document.getElementsByTagName('META')
for (let element of metaElements) {
    if (element.getAttribute('http-equiv') === 'Content-Security-Policy') {
        newNonce = getNonce(element)
    }
}

const myCache = createCache({
    key: 'sama-coach-key',
    nonce: newNonce ?? '',
    prepend: false,
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <CacheProvider value={myCache}>
            <App />
        </CacheProvider>
    </Provider>,
)
