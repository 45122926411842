import { useState, useEffect } from 'react'
import { CommonService } from '../_services/common.service'
import CoachNoteView from './coach-note-view'
import CoachNotesQuestions from './coach-notes-questions'
import SamaListPopupView from '../_components/SamaListPopupView'

import moment from 'moment'

interface Props {
    coacheeId: any
    coacheeName: any
}

const CoachNotes = ({ coacheeId, coacheeName }: Props) => {
    const [notesData, setNotesData] = useState([])
    const [prevCoachNotes, setPrevCoachNotes] = useState()
    const [isCoachNoteViewOpen, openCoachNoteView] = useState(false)
    const [currentItem, setCurrentItem] = useState<any>()
    const [isPriorCoachListOpen, openPriorCoachList] = useState(false)
    const [isPriorNoteViewOpen, openPriorNoteView] = useState(false)
    const [currentPriorNote, setCurrentPriorNote] = useState({})

    useEffect(() => {
        if (coacheeId) {
            getNotes()
        }
    }, [coacheeId])

    function getNotes() {
        CommonService.getAllNotes(coacheeId, function (res: any) {
            setNotesData(res.data)
        })
        CommonService.getPrevCoachNotes(coacheeId, function (res: any) {
            res.data.sort(
                (a: any, b: any) =>
                    new Date(b._createdAt).getTime() -
                    new Date(a._createdAt).getTime(),
            )

            let sortedData: any = []
            let currentCoach = null
            let count = 0
            for (const note of res.data) {
                if (currentCoach != note.fromCoach) {
                    count = count + 1
                }
                note.id = count
                sortedData.push(note)
            }

            setPrevCoachNotes(sortedData)
        })
    }

    function noteSaved() {
        getNotes()
    }

    function openNote(item: any) {
        setCurrentItem(item)
        openCoachNoteView(true)
    }

    function openPriorNote(item: any) {
        openPriorNoteView(true)
        setCurrentPriorNote(item)
    }

    return (
        <div className="custom-list">
            {coacheeId && <CoachNotesQuestions coacheeId={coacheeId} />}

            <div
                style={{
                    fontSize: '11px',
                    fontWeight: '500',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '1.22px',
                    textAlign: 'left',
                    color: '#74758b',
                    marginLeft: '10px',
                    paddingTop: '40px',
                }}
            >
                NOTES
            </div>
            {notesData.length > 0 && (
                <>
                    {notesData.map((item: any, i: number) => (
                        <span key={i}>
                            <div
                                style={{ height: '68px' }}
                                className={i == 0 ? 'note-box top' : 'note-box'}
                                onClick={() => openNote(item)}
                            >
                                <i className="note-box-icon"></i>
                                <p
                                    style={{
                                        width: '95%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginBottom: '2px',
                                    }}
                                >
                                    {CommonService.getSummaryLine(
                                        item.main,
                                        160,
                                    )}
                                </p>
                                <p className="date">
                                    {moment(new Date(item._createdAt)).format(
                                        'DD MMM YYYY',
                                    )}
                                </p>
                            </div>
                        </span>
                    ))}
                </>
            )}
            {notesData.length < 1 && (
                <div className="no-notes">
                    <h3>No notes added</h3>
                </div>
            )}
            <br />
            <br />
            <button className="orangeButton" onClick={openNote}>
                Create note
            </button>
            <CoachNoteView
                keyIndex="2"
                mainTitle={currentItem?.main ? 'Update note' : 'Create note'}
                subTitle={`${coacheeName}'s notes`}
                item={currentItem}
                open={isCoachNoteViewOpen}
                close={openCoachNoteView}
                coacheeId={coacheeId}
                goBack={() => {}}
                onSave={noteSaved}
            />

            <p
                onClick={() => openPriorCoachList(true)}
                style={{
                    fontSize: '15px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    color: '#ff7558',
                    cursor: 'pointer',
                }}
            >
                Notes from prior coaches
            </p>

            <SamaListPopupView
                list={prevCoachNotes}
                title="Notes from prior coaches"
                emptyTitle="No previous notes"
                emptyMessage="Notes are helpful to keep track of the progress the coachee is making."
                open={isPriorCoachListOpen}
                onItemClick={openPriorNote}
                close={openPriorCoachList}
            />
            <CoachNoteView
                mainTitle={`${coacheeName}'s prior note`}
                close={openPriorNoteView}
                open={isPriorNoteViewOpen}
                keyIndex="1"
                item={currentPriorNote}
                subTitle=""
                coacheeId={coacheeId}
                readonly={true}
                goBack={() => openPriorNoteView(false)}
                onSave={() => {}}
            />
        </div>
    )
}

export default CoachNotes
