import moment from 'moment'
import { useRef, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {
    HALF_HOURS_AM,
    HALF_HOURS_PM,
    formatDateWeekDay,
    isTimeRange,
} from '../misc/dates'
import { RescheduleBookingModel } from '../models/request/booking/reschedule-booking.model'
import { useStores } from '../utils/stores'
import CoachCancelQuestionView from './coach-cancel-question-view'
import { BookingCancelDetails } from './types/Booking'

const RescheduleTimesDisplayComponant = (
    HOURS: any,
    value: any,
    handleChange: any,
) => {
    return (
        <div>
            <h6>At what time?</h6>
            <div className="custom-radio custom--box-radio timeslotspoints">
                {HOURS.map(function (option: any, i: number) {
                    return (
                        <label
                            htmlFor={'inptime_' + i}
                            key={'timeslots_' + i}
                            className={
                                isTimeRange(option, value) ? '' : 'd-none'
                            }
                        >
                            <input
                                type="radio"
                                id={'inptime_' + i}
                                name="radio-time"
                                value={option}
                                onChange={handleChange}
                            />
                            <span>{option}</span>
                        </label>
                    )
                })}
            </div>
        </div>
    )
}

interface Props {
    date: any
    _id: string
    isOpen: boolean
    setIsOpen: any
    participants: any
}

export const RescheduleSessions = ({
    date,
    _id,
    isOpen,
    setIsOpen,
    participants,
}: Props) => {
    const { coach } = useStores()
    const inputId = useRef(null)
    const [value, onChange] = useState(null)
    const [isDateSelected, setIsDateSelected] = useState(false)
    const [scheduleDetails, setScheduleDetails] = useState<any>({})
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false)
    const [confirmReschedule, setConfirmReschedule] = useState(false)
    const [openCancelQuestion, setOpenCancelQuestion] = useState(false)

    const onDateChange = (value: any) => {
        setIsDateSelected(true)
        onChange(value)
    }

    const handleChange = (e: any) => {
        let name = e.target.name
        let value = e.target.value
        var obj: any = {}
        obj[name] = value
        setScheduleDetails({ ...scheduleDetails, ...obj })

        let copy: any = { ...scheduleDetails }
        if (name === 'median') {
            delete copy['radio-time']
            delete copy['call-type']
            setScheduleDetails({ ...copy, ...obj })
        }
    }

    const bookingDate = new Date(date)
    const now = new Date()
    const less_24hours =
        bookingDate.getTime() - now.getTime() < 24 * 60 * 60 * 1000

    const handleFormSubmit = (BookingCancelDetails?: BookingCancelDetails) => {
        // event.preventDefault()
        //@ts-ignore
        const id = inputId?.current?.value?.toString()
        var time =
            scheduleDetails['radio-time'] + ' ' + scheduleDetails['median']
        var d = moment(value).format('YYYY-MM-DD')
        var momentObj = moment(d + '' + time, 'YYYY-MM-DDLTHH:mm:ss')
        var dateTime = moment(
            momentObj.format('YYYY-MM-DDTHH:mm:ss'),
        ).toISOString()
        scheduleDetails['date'] = dateTime
        scheduleDetails['duration'] = Number(scheduleDetails['duration'])
        scheduleDetails['isVideoSession'] =
            scheduleDetails['call-type'] == 'Video' ? true : false

        if (less_24hours && BookingCancelDetails) {
            const rescheduleBooking = new RescheduleBookingModel(
                id,
                scheduleDetails.date,
                scheduleDetails.duration,
                scheduleDetails.isVideoSession,
                BookingCancelDetails.whoNeededChange,
                BookingCancelDetails.reasonForChange,
                BookingCancelDetails.customReason,
                BookingCancelDetails.reachOutToCoachee,
            )
            coach.rescheduleBooking(rescheduleBooking).subscribe()
        } else {
            const rescheduleBooking = new RescheduleBookingModel(
                id,
                scheduleDetails.date,
                scheduleDetails.duration,
                scheduleDetails.isVideoSession,
            )
            coach.rescheduleBooking(rescheduleBooking).subscribe()
        }

        setScheduleDetails({})
        setIsOpen(false)
        setIsConfirmationModalOpen(false)
        setConfirmReschedule(false)
    }

    function showConfirmationPopup(event: any) {
        event.preventDefault()
        setIsConfirmationModalOpen(true)
        if (confirmReschedule) {
            handleFormSubmit()
            setIsConfirmationModalOpen(false)
        }
    }

    const handleSubmit = (event: any) => {
        // event.preventDefault()
        if (less_24hours) {
            setOpenCancelQuestion(true)
        } else {
            if (participants.length > 0) {
                showConfirmationPopup(event)
            } else {
                handleFormSubmit()
            }
        }
    }

    return (
        <>
            {isOpen && (
                <>
                    <Popup
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        modal
                        nested
                        className="datepopup"
                        lockScroll
                    >
                        {
                            // @ts-ignore
                            (close: any) => (
                                <div className="modal reschd mdsdisp">
                                    {isConfirmationModalOpen && (
                                        <div className="fixed inset-0 flex items-center justify-center z-10">
                                            <div className="fixed inset-0 transition-opacity z-10">
                                                <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                                            </div>
                                            <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
                                                <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
                                                    <div className="md:flex items-center">
                                                        <div className="mt-4 md:mt-0 md:ml-6 text-center">
                                                            <p className="text-md text-gray-700 mt-1">
                                                                Have you
                                                                confirmed that
                                                                all relevant
                                                                parties have
                                                                been informed
                                                                about the change
                                                                in date?
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleFormSubmit()
                                                            }
                                                            className="block w-full md:inline-block md:w-auto px-4 py-3 mt-4 md:py-2 bg-[#004e72] text-white rounded-lg font-semibold text-sm md:ml-2 md:order-2 hover:bg-[#00628f]"
                                                        >
                                                            Reschedule
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                setIsConfirmationModalOpen(
                                                                    false,
                                                                )
                                                            }
                                                            className="block w-full md:inline-block md:w-auto px-4 py-3 mt-4 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm hover:bg-gray-300"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <CoachCancelQuestionView
                                        mainTitle={'Reschedule session'}
                                        open={openCancelQuestion}
                                        close={setOpenCancelQuestion}
                                        handleFormSubmit={handleFormSubmit}
                                        scheduleDetails={scheduleDetails}
                                    />

                                    <div className="modal-header">
                                        <span className="header">
                                            {' '}
                                            Reschedule{' '}
                                        </span>
                                        <button
                                            className="close"
                                            onClick={close}
                                        >
                                            &times;
                                        </button>
                                    </div>

                                    <div className="content">
                                        <h6>Select date</h6>
                                        <Calendar
                                            onChange={onDateChange}
                                            value={value}
                                            showNavigation={true}
                                            formatShortWeekday={(
                                                _locale,
                                                date,
                                            ) => formatDateWeekDay(date)}
                                            showNeighboringMonth={false}
                                            minDate={new Date()}
                                            maxDate={moment()
                                                .add(3, 'months')
                                                .toDate()}
                                        />
                                        {isDateSelected && (
                                            <div className="mt-3">
                                                <h6>Session duration</h6>
                                                <div className="custom-radio">
                                                    <label
                                                        htmlFor="duration25"
                                                        className="p-0"
                                                    >
                                                        <input
                                                            id="duration25"
                                                            name="duration"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="radio"
                                                            value="25"
                                                        />
                                                        <span>25 min</span>
                                                    </label>
                                                    <label
                                                        htmlFor="duration50"
                                                        className="p-0"
                                                    >
                                                        <input
                                                            id="duration50"
                                                            name="duration"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="radio"
                                                            value="50"
                                                        />
                                                        <span>50 min</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {scheduleDetails?.duration && (
                                            <div>
                                                <h6>
                                                    Select session start time
                                                </h6>
                                                <div className="custom-radio">
                                                    <label
                                                        htmlFor="inpam"
                                                        className="p-0"
                                                    >
                                                        <input
                                                            id="inpam"
                                                            name="median"
                                                            type="radio"
                                                            value="am"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span>AM</span>
                                                    </label>
                                                    <label
                                                        htmlFor="inppm"
                                                        className="p-0"
                                                    >
                                                        <input
                                                            id="inppm"
                                                            name="median"
                                                            type="radio"
                                                            value="pm"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span>PM</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {scheduleDetails?.median === 'am' &&
                                            RescheduleTimesDisplayComponant(
                                                HALF_HOURS_AM,
                                                value,
                                                handleChange,
                                            )}
                                        {scheduleDetails?.median === 'pm' &&
                                            RescheduleTimesDisplayComponant(
                                                HALF_HOURS_PM,
                                                value,
                                                handleChange,
                                            )}

                                        {scheduleDetails?.median &&
                                            scheduleDetails?.['radio-time'] && (
                                                <div>
                                                    <h6>
                                                        Select type of session
                                                    </h6>
                                                    <div className="custom-radio btn-call-type">
                                                        <label htmlFor="inp-video">
                                                            <input
                                                                id="inp-video"
                                                                name="call-type"
                                                                type="radio"
                                                                value="Video"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            <span>
                                                                <i className="fa fa-video-camera"></i>
                                                                <br />
                                                                Video
                                                            </span>
                                                        </label>
                                                        <label htmlFor="inp-call">
                                                            <input
                                                                id="inp-call"
                                                                name="call-type"
                                                                type="radio"
                                                                value="Call"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            <span>
                                                                <i className="fa fa-phone"></i>
                                                                <br />
                                                                Call
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        <div className="form-group mt-4 text-center">
                                            <input
                                                type="hidden"
                                                name="ses-id"
                                                ref={inputId}
                                                value={_id}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group text-center btn-place-bottom">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-medium"
                                            disabled={
                                                Object.keys(scheduleDetails)
                                                    .length !== 4
                                            }
                                            onClick={handleSubmit}
                                        >
                                            Reschedule session
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </Popup>
                </>
            )}
        </>
    )
}
