import { Field, Formik } from 'formik'
import { classNames } from '../../../utils/misc'

interface Props {
    selectCalendar: any
    setShowCalendarSelection: any
    calendarInfo: any
}

export const ChangeCalendarForm = ({
    selectCalendar,
    setShowCalendarSelection,
    calendarInfo,
}: Props) => {
    const validate = (values: any) => {
        if (!values.calendar) {
            return { calendar: 'Please select a calendar' }
        }

        return {}
    }

    const onSubmit = (values: any) => {
        if (values.calendar === calendarInfo.calendar?.id) {
            return setShowCalendarSelection(false)
        }

        const calendar = calendarInfo.calendars.find(
            ({ id }: any) => id === values.calendar,
        )

        selectCalendar(calendar)
    }

    return (
        <Formik
            validate={validate}
            validateOnChange
            initialValues={{ calendar: calendarInfo.calendar?.id ?? '' }}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isValid, isSubmitting }) => (
                <form
                    className={classNames('w-full grid mt-[15px]')}
                    onSubmit={handleSubmit}
                >
                    <Field
                        as="select"
                        className={classNames(
                            'h-[50px] w-full mb-[10px]',
                            'color-[#10123a] overflow-hidden',
                            'bg-[#f4f5f8] pl-[18px] whitespace-pre',
                            'rounded-[6px] border-none outline-none',
                            'text-[15px] text-ellipsis',
                        )}
                        name="calendar"
                    >
                        <option value="" disabled>
                            Select calendar to sync
                        </option>
                        {calendarInfo.calendars?.map(
                            ({ id, name }: any, i: number) => (
                                <option key={i} value={id}>
                                    {name}
                                </option>
                            ),
                        )}
                    </Field>
                    <button
                        className={classNames(
                            'h-[50px] pl-[30px] ml-[30px]',
                            'border-none outline-none',
                            'flex rounded-[6px] bg-[#ff7558]',
                            'items-center justify-center',
                            'disabled:bg-[#d3d6db]',
                        )}
                        type="submit"
                        disabled={isSubmitting || !isValid}
                    >
                        <span>Save</span>
                    </button>
                </form>
            )}
        </Formik>
    )
}
