import React, { useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { getCategories, getResources } from '../_services/resources.service'
import OrangeDownArrow from '../assets/icons/orange_down_arrow.svg'
import CategoryIcon from '../assets/icons/category.svg'
import FilesIcon from '../assets/icons/files-icon.svg'
import LinkIcon from '../assets/icons/link-icon.svg'
import LinkOrangeIcon from '../assets/icons/link-orange-icon.svg'
import DefaultArticleImage from '../assets/images/article.svg'
import DefaultDocumentImage from '../assets/images/document.svg'
import DefaultVideoImage from '../assets/images/video.svg'
import DefaultPodcastImage from '../assets/images/podcast.svg'
import DefaultBookImage from '../assets/images/book.svg'
import DefaultLinkImage from '../assets/images/link.svg'

import SearchIcon from '../assets/icons/search-icon.svg'
import { withStyles } from 'tss-react/mui'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CommonService } from '../_services/common.service'
import './resources.css'

const defaultImage = {
    article: DefaultArticleImage,
    document: DefaultDocumentImage,
    video: DefaultVideoImage,
    podcast: DefaultPodcastImage,
    link: DefaultLinkImage,
    book: DefaultBookImage,
}

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'left',
    marginTop: '27px',
    boxShadow: '0 4px 14px 0 rgba(16, 18, 58, 0.1)',
    borderRadius: '12px',
    color: theme.palette.text.secondary,
}))

const CopyTooltip = withStyles(Tooltip, (theme) => ({
    tooltip: {
        color: '#FFFFF',
        backgroundColor: '#10123a',
    },
    arrow: {
        '&:before': {
            border: '1px solid #10123a',
            backgroundColor: '#10123a',
        },
    },
}))

const theme = createTheme({
    typography: {
        fontFamily: 'FoundersGroteskWeb-Regular',
    },
})

const colours = {
    article: {
        bgColour: '#EAFFF9',
        textColour: '#2CC798',
    },
    video: {
        bgColour: '#F3F0FC',
        textColour: '#523BA0',
    },
    link: {
        bgColour: '#EBF1FF',
        textColour: '#012F8C',
    },
    document: {
        bgColour: '#ECF9F9',
        textColour: '#007188',
    },
    podcast: {
        bgColour: '#FFF3F1',
        textColour: '#FF5931',
    },
    book: {
        bgColour: '#FFF7EB',
        textColour: '#FFBC5A',
    },
}

function Resources(props: any) {
    const defaultTypeText = 'All Files'
    const defaultCategoryText = 'All Categories'
    const defaultCategoryId = ''

    const [selectedCategoryId, setSelectedCategoryId] =
        React.useState(defaultCategoryId)
    const [selectedCategoryName, setSelectedCategoryName] =
        React.useState(defaultCategoryText)
    const [selectedType, setSelectedType] = React.useState(defaultTypeText)
    const [openCategoryList, setOpenCategoryList] = React.useState(false)
    const [openTypeList, setOpenTypeList] = React.useState(false)
    const [originalCategoryData, setOriginalCategoryData] = React.useState([])
    const [categoryData, setCategoryData] = React.useState([])
    const [origonalResources, setOriginalResources] = React.useState([])
    const [resources, setResources] = React.useState([])

    const checkKeyPress = useCallback((e: any) => {
        const { key, keyCode } = e
        if (keyCode === 27) {
            setOpenCategoryList(false)
            setOpenTypeList(false)
        }
    }, [])

    const checkMousePress = useCallback((event: any) => {
        if (
            !event.target.className.includes('MuiTypography-root') &&
            !event.target.className.includes('MuiListItemButton-root') &&
            !event.target.className.includes('MuiListItemIcon-root') &&
            !event.target.className.includes('search-box') &&
            !event.target.className.includes('select-arrow')
        ) {
            setOpenCategoryList(false)
            setOpenTypeList(false)
        }
    }, [])

    useEffect(() => {
        getCategories().then((categories) => {
            setOriginalCategoryData(categories)
            setCategoryData(categories)
        })

        getResources()
            .then(async (resources) => {
                let filteredResources = []
                for (let i = 0; i < resources.length; i++) {
                    let resource = resources[i]
                    if (resource.image?._id) {
                        await CommonService.getFile(
                            resource.image._id,
                            '500x150',
                        ).then((file) => {
                            resource.imageFile = file
                        })
                    } else {
                        resource.defaultImage =
                            defaultImage[
                                resource.type as keyof typeof defaultImage
                            ]
                    }

                    // make sure the document type has a document
                    if (
                        resource.type == 'document' &&
                        !resource?.document?.bucket
                    ) {
                        continue
                    }
                    filteredResources.push(resource)
                }
                return filteredResources
            })
            .then((resources: any) => {
                setResources(resources)
                setOriginalResources(resources)
            })

        document.addEventListener('keydown', checkKeyPress)
        document.addEventListener('mousedown', checkMousePress)
        return () => {
            window.removeEventListener('keydown', checkKeyPress)
            document.removeEventListener('mousedown', checkMousePress)
        }
    }, [checkKeyPress, checkMousePress])

    const getDocumentUrl = (document: any) => {
        if (document?.bucket && document?.folder && document?.name) {
            return (
                'https://' +
                document.bucket +
                '/' +
                document.folder +
                '/' +
                document.name
            )
        }
    }

    const handleTypesListClick = (name: any) => {
        if (name == selectedType) {
            name = defaultTypeText
        }

        setSelectedType(name)
        filterResources(name, selectedCategoryId)
    }

    const filterResources = (selectedType: any, selectedCategoryId: string) => {
        let resources = origonalResources
        if (selectedType !== defaultTypeText) {
            resources = filterByType(origonalResources, selectedType)
        }
        if (selectedCategoryId !== defaultCategoryId) {
            resources = filterByCategory(resources, selectedCategoryId)
        }
        setResources(resources)
    }

    const filterByType = (resources: any, typeName: string) => {
        return resources.filter(
            (resource: any) =>
                resource.type.toLowerCase() == typeName.toLowerCase(),
        )
    }

    const filterByCategory = (resources: any, selectedCategoryId: string) => {
        return resources.filter((resource: any) => {
            return (
                resource.categories.filter(
                    (category: any) => category._id == selectedCategoryId,
                ).length > 0
            )
        })
    }

    const clearFilters = () => {
        setSelectedCategoryId(defaultCategoryId)
        setSelectedCategoryName(defaultCategoryText)
        setSelectedType(defaultTypeText)
        filterResources(defaultTypeText, defaultCategoryId)
        setOpenCategoryList(false)
        setOpenTypeList(false)
    }

    const handleCategoryListClick = (id: string, name: string) => {
        if (id == selectedCategoryId) {
            name = defaultCategoryText
            id = ''
        }
        setSelectedCategoryId(id)
        setSelectedCategoryName(name)

        filterResources(selectedType, id)
    }

    const copyLink = (content: string) => {
        navigator.clipboard.writeText(content)
    }

    const openTheCategoryList = () => {
        setOpenCategoryList(!openCategoryList)
        setOpenTypeList(false)
    }

    const openTheTypeList = () => {
        setOpenCategoryList(false)
        setOpenTypeList(!openTypeList)
    }

    const searchCategory = (categoryList: any, searchTerm: string): any => {
        if (searchTerm != '') {
            let foundCategories = []
            for (let i = 0; i < categoryList.length; i++) {
                const node = { ...categoryList[i] }
                if (
                    node.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    const newNode = { ...node }
                    newNode.subCategories = []
                    foundCategories.push(newNode)
                }
                // next check children
                if (node.subCategories) {
                    foundCategories = foundCategories.concat(
                        searchCategory([...node.subCategories], searchTerm),
                    )
                }
            }
            return foundCategories
        } else {
            return categoryList
        }
    }

    const searchFilter = (event: any) => {
        setCategoryData(
            searchCategory([...originalCategoryData], event.target.value),
        )
    }

    const cards = resources.map((item: any, i: number) => {
        return (
            <Grid
                key={i}
                item
                sm={4}
                md={4}
                lg={4}
                xl={3}
                style={{ paddingTop: '0px' }}
            >
                <a
                    target="_blank"
                    href={item.link ? item.link : getDocumentUrl(item.document)}
                    style={{ textDecoration: 'none' }}
                >
                    <Item className="card-box">
                        <div className="card-img-box">
                            <img
                                style={{
                                    left: '50%',
                                    transform:
                                        'translateX(-50%) translateY(0%)',
                                    position: 'relative',
                                }}
                                height="150px"
                                src={
                                    item.imageFile
                                        ? item.imageFile
                                        : item.defaultImage
                                }
                            />
                            <CopyTooltip
                                title="Copy link to clipboard"
                                placement="top"
                                arrow
                            >
                                <img
                                    src={LinkIcon}
                                    className="copyIcon"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = LinkOrangeIcon)
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = LinkIcon)
                                    }
                                    onClick={(e) => {
                                        copyLink(
                                            item.link
                                                ? item.link
                                                : getDocumentUrl(item.document),
                                        )
                                        e.preventDefault()
                                    }}
                                />
                            </CopyTooltip>
                        </div>
                        <div
                            style={{
                                backgroundColor:
                                    colours[
                                        item.type.toLowerCase() as keyof typeof colours
                                    ]?.bgColour,
                                color: colours[
                                    item.type.toLowerCase() as keyof typeof colours
                                ]?.textColour,
                            }}
                            className="type"
                        >
                            {item.type.charAt(0).toUpperCase() +
                                item.type.slice(1)}
                        </div>
                        <h2 className="title">{item.title}</h2>
                        <p className="content">
                            {item.authors?.join(', ')}
                            {item.authors && <br />}
                            {item.summary
                                ?.split('\n')
                                .map((item: any, key: number) => {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    )
                                })}
                        </p>
                        <div className="categories">
                            <div className="dash"></div>
                            {item.categories.map(
                                (cat: any, i: number, rows: any[]) =>
                                    rows.length == i + 1
                                        ? cat.name
                                        : cat.name + ', ',
                            )}
                        </div>
                    </Item>
                </a>
            </Grid>
        )
    })

    const getTypeListHtml = () => {
        return ['Article', 'Book', 'Document', 'Link', 'Podcast', 'Video'].map(
            (item: string, i: number) => {
                return (
                    <ListItemButton
                        key={i}
                        className="list-button"
                        onClick={() => handleTypesListClick(item)}
                        selected={selectedType == item}
                    >
                        <ListItemText primary={item} />
                    </ListItemButton>
                )
            },
        )
    }

    const getCategoriesHtml = (list: any, sxOptions: any | null = null) => {
        return list.map((item: any, i: number, row: any[]) => {
            return (
                <div key={i}>
                    <ListItemButton
                        sx={{
                            ...sxOptions,
                            ...{
                                paddingLeft: 5 * sxOptions.pl + 'px !important',
                            },
                        }}
                        className="list-button"
                        onClick={() =>
                            handleCategoryListClick(item._id, item.name)
                        }
                        selected={selectedCategoryId == item._id}
                    >
                        {item.subCategories && i == 1 && sxOptions.pl > 4 && (
                            <span className="shortUpDash"></span>
                        )}
                        <span
                            className={sxOptions.pl == 1 ? '' : 'list-box'}
                            style={{
                                height:
                                    row.length === i + 1 && sxOptions.pl != 1
                                        ? '26px'
                                        : '47px',
                                top:
                                    row.length === i + 1 && sxOptions.pl != 1
                                        ? '-14px'
                                        : '0px',
                            }}
                        ></span>
                        {sxOptions.pl == 1 ? (
                            ''
                        ) : (
                            <ListItemIcon>
                                <span className="thickDash"></span>
                            </ListItemIcon>
                        )}
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                    {item.subCategories && (
                        <>
                            <Collapse
                                key={i}
                                in={true}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List key={i} component="div" disablePadding>
                                    {getCategoriesHtml(item.subCategories, {
                                        pl: sxOptions.pl + 4,
                                    })}
                                </List>
                            </Collapse>
                        </>
                    )}
                </div>
            )
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="about">
                <h1>Resources</h1>
                <Box sx={{ minWidth: 120, paddingBottom: '4px' }}>
                    <div className="filters">
                        <div className="category-search-box">
                            <img
                                src={CategoryIcon}
                                style={{
                                    height: '19px',
                                    paddingRight: '14px',
                                    paddingLeft: '20px',
                                }}
                            />
                            <span style={{ width: '65%' }}>
                                {selectedCategoryName}
                            </span>
                            <img
                                src={OrangeDownArrow}
                                className="select-arrow"
                                style={{
                                    filter: openCategoryList
                                        ? 'invert(98%) sepia(23%) saturate(0%) hue-rotate(95deg) brightness(105%) contrast(102%)'
                                        : '',
                                }}
                                onClick={openTheCategoryList}
                            />
                            <Collapse
                                className="category-list-box"
                                in={openCategoryList}
                                timeout="auto"
                                unmountOnExit
                            >
                                <span style={{ display: 'inline-block' }}>
                                    <input
                                        autoComplete="off"
                                        onKeyPress={searchFilter}
                                        onChange={searchFilter}
                                        className="search-box"
                                        type="text"
                                        id="category-search"
                                        name="category-search"
                                        style={{
                                            position: 'absolute',
                                            paddingLeft: '38px',
                                            marginBottom: '40px',
                                        }}
                                    />
                                    <img
                                        src={SearchIcon}
                                        style={{
                                            position: 'relative',
                                            paddingTop: '16px',
                                            paddingLeft: '8px',
                                            marginBottom: '10px',
                                        }}
                                    />
                                </span>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    {getCategoriesHtml(categoryData, { pl: 1 })}
                                </List>
                            </Collapse>
                        </div>
                        <div className="type-search-box">
                            <img
                                src={FilesIcon}
                                style={{
                                    paddingRight: '14px',
                                    paddingLeft: '20px',
                                }}
                            />
                            <span style={{ width: '65%' }}>{selectedType}</span>
                            <img
                                src={OrangeDownArrow}
                                className="select-arrow"
                                style={{
                                    filter: openTypeList
                                        ? 'invert(98%) sepia(23%) saturate(0%) hue-rotate(95deg) brightness(105%) contrast(102%)'
                                        : '',
                                }}
                                onClick={openTheTypeList}
                            />
                            <Collapse
                                className="type-list-box"
                                in={openTypeList}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    {getTypeListHtml()}
                                </List>
                            </Collapse>
                        </div>
                        <span
                            style={{
                                cursor: 'pointer',
                                paddingLeft: '20px',
                                paddingTop: '15px',
                                color: '#ff7558',
                            }}
                            onClick={clearFilters}
                        >
                            clear filters
                        </span>
                    </div>
                </Box>
                <Grid
                    key="0"
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    {cards.length > 0 && cards}
                    {cards.length < 1 && (
                        <div
                            style={{
                                width: '100%',
                                paddingTop: '100px',
                                paddingBottom: '100px',
                            }}
                        >
                            <p style={{ textAlign: 'center' }}>
                                There are currently no resources to view.
                                <br />
                                Please try again later.
                            </p>
                        </div>
                    )}
                </Grid>
            </div>
        </ThemeProvider>
    )
}
export { Resources }
