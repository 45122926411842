import { Component } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { alertActions } from './_actions/alert.actions'
import { PrivateRoute } from './_components/PrivateRoute'
import { Header } from './Header/Header'
import { LoginPage } from './LoginPage/login.page'
import { ForgotPassword } from './account/ForgotPassword'
import { ResetPassword } from './account/ResetPassword'
import { ChangePassword } from './account/PasswordChange'
import ProfilePage from './account/profile/profile.page'
import { Home } from './HomePage/home.page'
import MultiStepForm from './Stepform/MultiStepForm'
import {
    CalendarLogin,
    GoogleReturnUrl,
    CalendarSave,
    AzureReturnUrl,
} from './CalendarSync/CalendarSync'
import { dehydrateToStorage, hydrateFromStorage } from './utils/misc/stoage'

class App extends Component<any, any> {
    constructor(props: any) {
        super(props)

        this.state = {
            screenToSmall: false,
        }
    }

    componentDidMount = () => {
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)

        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)

        const generateDeviceId = () => {
            return crypto.randomUUID();
        };

        const getDeviceId = async () => {
            let storedDeviceId: string | null = hydrateFromStorage('deviceId')

            if (!storedDeviceId) {
                storedDeviceId = generateDeviceId()
                dehydrateToStorage('deviceId', storedDeviceId)
                return storedDeviceId
            }
        };

        getDeviceId();
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        if (
            !window.location.pathname.includes('/reset') &&
            !window.location.pathname.includes('/forgot')
        ) {
            if (window.screen.width < 830) {
                this.setState({ screenToSmall: true })
            } else {
                this.setState({ screenToSmall: false })
            }
        }
    }

    render() {
        const { alert, userreducer }: any = this.props
        return (
            <div className="height-vh">
                {alert.message && (
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                )}

                {this.state.screenToSmall && (
                    <div className="pink-container bg-pink">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="semi-article">
                                    <figure>
                                        <img
                                            src="assets/logo-dark.svg"
                                            width="120"
                                            alt="logo"
                                            className="logo"
                                        />
                                    </figure>
                                    <h2 className="m-t-60">
                                        Welcome to the <br /> Sama team!
                                    </h2>
                                    <p>
                                        window.screen.availWidth:{' '}
                                        {window.screen.availWidth}
                                    </p>
                                    <p>
                                        window.screen.width:{' '}
                                        {window.screen.width}
                                    </p>
                                    <p>screen.width: {screen.width}</p>
                                    <p>
                                        window.orientation: {window.orientation}
                                    </p>

                                    <p>
                                        The web version is intended for use
                                        landscape tablets and larger screen
                                        sizes.
                                    </p>
                                    <p>
                                        If you are would like to use a smaller
                                        size device please download one of our
                                        apps
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!this.state.screenToSmall && (
                    <>
                        <Router>
                            <div
                                className={
                                    userreducer?.isHeader ? '' : 'd-none'
                                }
                            >
                                <Header {...this.props} />
                            </div>
                            <Routes>
                                <Route
                                    path="/home/*"
                                    element={
                                        <PrivateRoute>
                                            <Home />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="/change"
                                    element={
                                        <PrivateRoute>
                                            <ChangePassword />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="/details"
                                    element={
                                        <PrivateRoute>
                                            <MultiStepForm />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="/profile"
                                    element={
                                        <PrivateRoute>
                                            <ProfilePage />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/login" element={<LoginPage />} />
                                <Route
                                    path="/forgot"
                                    element={<ForgotPassword />}
                                />
                                <Route
                                    path="/reset"
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path="/calendar-sync"
                                    // @ts-ignore
                                    element={<CalendarLogin />}
                                />
                                <Route
                                    path="/calendar-save"
                                    element={<CalendarSave />}
                                />
                                <Route
                                    path="/google-return"
                                    element={<GoogleReturnUrl />}
                                />
                                <Route
                                    path="/auth/azure"
                                    element={<AzureReturnUrl />}
                                />
                                <Route
                                    path="/"
                                    element={
                                        <Navigate to="/home" replace={true} />
                                    }
                                />
                            </Routes>
                        </Router>
                    </>
                )}
            </div>
        )
    }
}

function mapState(state: any) {
    const { alert, userreducer, picture } = state
    return { alert, userreducer, picture }
}

const actionCreators = {
    clearAlerts: alertActions.clear,
}

const connectedApp = connect(mapState, actionCreators)(App)
export { connectedApp as App }
