import { useState, useEffect } from 'react'
import { userConstants } from '../_constants'
import SamaDropdownBox from '../_components/SamaDropdownBox'
import SamaMultiSelectInline from '../_components/SamaMultiSelectInline'
import SamaFixedDatePicker from '../_components/SamaFixedDatePicker'
import { CommonService } from '../_services/common.service'

import EditIcon from '../assets/icons/edit-icon.svg'

import './styles/coach-notes-questions.css'
import moment from 'moment'

interface StaticsValues {
    name: string
    key: string
}

interface Statics {
    competencies: StaticsValues[]
}

const CoachNotesQuestions = ({ coacheeId }: { coacheeId: string }) => {
    const statics = localStorage.getItem('statics')
    const competencies: Statics['competencies'] = statics
        ? JSON.parse(statics).competencies
        : {}
    const [competencyValues, setCompetencyValues] = useState()

    useEffect(
        function effectFunction() {
            if (coacheeId && competencyValues) {
                getQuestionsData(coacheeId, competencyValues)
            }
        },
        [coacheeId],
    )

    const [competencyNames, setCompetencyNames] = useState<string[]>([])
    const [initialCompetency, setInitialCompetency] = useState<any>()
    const [initialAtrritionRisk, setInitialAtrritionRisk] = useState<any>()
    const [initialProposedDate, setInitialProposedDate] = useState<any>()
    const [isDateOptionsOpen, openDateOptions] = useState(false)

    useEffect(() => {
        let compValues: any = []
        let compNames = []
        for (const [key, value] of Object.entries(competencies)) {
            compNames.push(value.name)
            compValues[value.key] = value.name
        }
        setCompetencyNames(compNames)
        setCompetencyValues(compValues)
        getQuestionsData(coacheeId, compValues)
    }, [])

    function getQuestionsData(coacheeId: string, competencyValues: any) {
        return CommonService.getCoachNotesQuestion(coacheeId).then(
            (questionData) => {
                if (questionData) {
                    setInitialProposedDate(questionData.proposedNextSession)
                    setInitialCompetency(
                        competencyValues[questionData.coachingArea],
                    )
                    setInitialAtrritionRisk(
                        questionData?.attritionRisk?.charAt(0).toUpperCase() +
                            questionData?.attritionRisk?.slice(1),
                    )
                } else {
                    setInitialProposedDate(null)
                    setInitialCompetency(null)
                    setInitialAtrritionRisk(null)
                }
            },
        )
    }

    function saveAreaOfCoaching(data: any) {
        let compKey = null
        for (const [key, value] of Object.entries(competencies)) {
            if (value.name === data) {
                compKey = value.key
            }
        }
        setInitialCompetency(data)
        if (compKey) {
            const object = {
                coachingArea: compKey,
            }
            CommonService.saveCoachNotesQuestion(coacheeId, object)
        }
    }

    function saveAtrittionRisk(data: any) {
        setInitialAtrritionRisk(data)
        const object = {
            attritionRisk: data.toLowerCase(),
        }
        CommonService.saveCoachNotesQuestion(coacheeId, object)
    }

    function savePropsedNextDate(data: any) {
        setInitialProposedDate(data)
        const object = {
            proposedNextSession: data?.toISOString() ?? null,
        }
        CommonService.saveCoachNotesQuestion(coacheeId, object)
    }

    return (
        <div className="coach-questions-box">
            <span className="skew-i-icon"></span>
            <h3>Please review</h3>
            <div className="questionBox">
                <p>Proposed next session</p>
                <div
                    className="s-input-box"
                    onClick={() => openDateOptions(!isDateOptionsOpen)}
                >
                    <span style={{ paddingLeft: '14px' }}>
                        {initialProposedDate
                            ? moment(initialProposedDate).format(
                                  userConstants.DATE_FORMAT,
                              )
                            : 'Please enter a proposed date'}
                    </span>
                    <img
                        src={EditIcon}
                        className="select-cal-icon"
                        style={{
                            filter: isDateOptionsOpen
                                ? 'invert(98%) sepia(23%) saturate(0%) hue-rotate(95deg) brightness(105%) contrast(102%)'
                                : '',
                        }}
                    />
                </div>
            </div>
            <SamaFixedDatePicker
                open={isDateOptionsOpen}
                close={() => openDateOptions(false)}
                onChange={savePropsedNextDate}
                title="Proposed next session"
            />

            <div className="questionBox">
                <p>Area of coaching</p>
                <SamaDropdownBox
                    options={competencyNames}
                    value={initialCompetency}
                    initialValue={initialCompetency}
                    emptyText="Select area of coaching"
                    onChange={saveAreaOfCoaching}
                />
            </div>
            <div className="questionBox">
                <p>Employee considering a career change?</p>
                <SamaMultiSelectInline
                    initialValue={initialAtrritionRisk}
                    value={initialAtrritionRisk}
                    options={['Yes', 'No', 'Maybe', 'Unknown']}
                    onChange={saveAtrittionRisk}
                />
            </div>
        </div>
    )
}

export default CoachNotesQuestions
