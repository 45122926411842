import moment from 'moment'
import { useEffect, useState } from 'react'
import CalendarSmall from 'react-calendar'

import { Value } from 'react-calendar/dist/cjs/shared/types'
import { userConstants } from '../_constants'
import RadioTickIcon from '../assets/icons/radio-tick.svg'

interface Props {
    initialValue?: string
    onChange: any
    open: boolean
    close: any
    title: string
}

const SamaFixedDatePicker = ({
    initialValue,
    onChange,
    open,
    close,
    title,
}: Props) => {
    const [currentCustomDate, setCurrentCustomDate] =
        useState<null | moment.Moment>(null)
    const [selectedValue, setSelectedValue] = useState<null | string>(null)
    const [customDateOpened, openCustomDate] = useState(false)
    const [isDataSaved, savedData] = useState(false)

    useEffect(
        function effectFunction() {
            setSelectedValue(null)
        },
        [open],
    )

    const handleChange = (name: string, date: moment.Moment | Date | null) => {
        setSelectedValue(name)

        if (name === 'Custom date' && !customDateOpened) {
            openCustomDate(true)
        } else if (date) {
            saveTheDate(date)
        }
    }

    const onCalenderChange = (date: Value) => {
        let momentDate = moment(date as Date)
        saveTheDate(momentDate)
        setCurrentCustomDate(momentDate)
        openCustomDate(false)
    }

    const saveTheDate = (date: moment.Moment | Date) => {
        openCustomDate(false)
        onChange(date)
        savedData(true)
        setTimeout(
            function () {
                savedData(false)
            }.bind(this),
            5000,
        )
    }

    function getDateOption() {
        let today = moment(new Date())
        return [
            {
                title: 'In 2 weeks',
                date: today.clone().add(2, 'weeks'),
            },
            {
                title: 'In 3 weeks',
                date: today.clone().add(3, 'weeks'),
            },
            {
                title: 'In a month',
                date: today.clone().add(1, 'months'),
            },
            {
                title: 'In 2 months',
                date: today.clone().add(2, 'months'),
            },
            {
                title: 'In 3 months',
                date: today.clone().add(3, 'months'),
            },
            {
                title: 'Custom date',
                type: 'custom',
                date: null,
                text: 'DD/MM/YYYY',
            },
            {
                title: 'No date decided',
                date: null,
                empty: true,
            },
        ]
    }

    const getCalendarHtml = (date: any = null) => {
        return (
            <div style={{ position: 'absolute' }}>
                <CalendarSmall
                    onChange={onCalenderChange}
                    value={date ? new Date(date) : null}
                    minDetail={'month'}
                    showNavigation={true}
                    showNeighboringMonth={false}
                    minDate={new Date()}
                    next2Label={null}
                    prev2Label={null}
                />
            </div>
        )
    }

    return (
        <>
            {open && (
                <div className="goal-view-container">
                    <div className="goal-view-box" style={{ padding: '55px' }}>
                        <div
                            className="coach-question-box"
                            style={{
                                maxHeight: 'none',
                            }}
                        >
                            <div
                                className="btn-close-solid"
                                onClick={() => close(false)}
                            ></div>
                            <h2
                                style={{
                                    paddingBottom: '45px',
                                }}
                            >
                                {title}
                            </h2>
                            {getDateOption().map((item, i) => (
                                <div
                                    key={i}
                                    onClick={() =>
                                        handleChange(item.title, item.date)
                                    }
                                    style={{
                                        paddingTop: '15.6px',
                                        cursor: 'pointer',
                                        paddingLeft: '0px',
                                    }}
                                    className={
                                        i == 0 ? 'note-box top' : 'note-box'
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            width: '92%',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: '20px',
                                                letterSpacing: '0.2px',
                                                textAlign: 'left',
                                                color: '#111237',
                                                marginBottom: '1.8px',
                                            }}
                                        >
                                            {item.title}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: '17px',
                                                letterSpacing: '0.2px',
                                                color: '#74758b',
                                                marginBottom: '9.6px',
                                            }}
                                        >
                                            {item.type == 'custom' &&
                                                (currentCustomDate?.format(
                                                    userConstants.DATE_FORMAT,
                                                ) ??
                                                    item.text)}

                                            {item.type != 'custom' &&
                                                (item?.date?.format(
                                                    userConstants.DATE_FORMAT,
                                                ) ??
                                                    item.text)}
                                        </p>
                                        {item.type == 'custom' &&
                                            customDateOpened &&
                                            getCalendarHtml(
                                                currentCustomDate ??
                                                    initialValue,
                                            )}
                                    </div>

                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {selectedValue !== item.title && (
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: '30px',
                                                    height: '30px',
                                                    flexGrow: '0',
                                                    borderRadius: '43px',
                                                    border: 'solid 1.5px rgba(17, 18, 55, 0.2)',
                                                    backgroundColor: '#fff',
                                                }}
                                            >
                                                {' '}
                                            </span>
                                        )}
                                        {selectedValue === item.title && (
                                            <img src={RadioTickIcon} />
                                        )}
                                    </div>
                                </div>
                            ))}
                            {isDataSaved && (
                                <div className="data-saved-box">
                                    <span>Date saved!</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SamaFixedDatePicker
