import React, { useState, useEffect } from 'react'
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { CoacheeService } from '../_services/coachee.service.js'
import './trackers.css'
import { formatDate } from '../misc/dates.js'

export const Trackers = (props: any) => {
    const [graphData, setGraphData] = useState({ datasets: [] } as any)
    const [origGraphData, setOrigGraphData] = useState({ datasets: [] } as any)
    const [legends, setLegends] = useState([])
    const [currentCoachee, setCurrentCoachee] = useState()

    useEffect(() => {
        if (graphData.datasets.length === 0) {
            setCurrentCoachee(props.coacheeId)
            CoacheeService.getTrackerData(props.coacheeId).then((data) => {
                _setGraphData(data)
            })
        }
    }, [])

    if (props.coacheeId) {
        if (currentCoachee !== props.coacheeId) {
            setCurrentCoachee(props.coacheeId)
            CoacheeService.getTrackerData(props.coacheeId).then((data) => {
                _setGraphData(data)
            })
        }
    }

    const _getCategoryColour = (category: string, visible: boolean) => {
        if (!visible) {
            return 'rgb(255,255,255)'
        } else {
            switch (category) {
                case 'adaptability':
                    return 'rgb(46,123,139)'
                case 'alignment':
                    return 'rgb(80,145,174)'
                case 'motivation':
                    return 'rgb(130,8,59)'
                case 'resilience':
                    return 'rgb(255,103,67)'
                case 'self-efficacy':
                    return 'rgb(61,41,127)'
            }
        }
    }

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 5,
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                },
                gridLines: {
                    display: true,
                    color: '#E4E4EA',
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Rating',
                },
            },
            x: {},
        },
    }

    const _setGraphData = (data: any) => {
        let dataSets = []
        let labels: Array<string> = []
        const categories = Object.keys(data)
        let legends: any = []

        if (data['resilience']) {
            labels = data['resilience'].map((responses: any) => {
                const dateArray = responses.date.split('-')
                return formatDate(
                    new Date(dateArray[2], dateArray[1] - 1, dateArray[0]),
                    'dd MMM yyyy',
                )
            })
        }

        for (let i = 0; i < categories.length; i++) {
            dataSets.push({
                label: categories[i],
                spanGaps: true,
                fill: false,
                lineTension: 0.2,
                backgroundColor: _getCategoryColour(categories[i], true),
                borderColor: _getCategoryColour(categories[i], true),
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: _getCategoryColour(categories[i], true),
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: _getCategoryColour(
                    categories[i],
                    true,
                ),
                pointHoverBorderColor: _getCategoryColour(categories[i], true),
                pointBackgroundColor: _getCategoryColour(categories[i], true),
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                data: data[categories[i]].map((values: any) => values.value),
            })

            legends.push({ label: categories[i], visible: true })
        }

        const dataObject = {
            labels: labels,
            datasets: dataSets,
        }

        setLegends(legends)
        setGraphData(dataObject)

        const origDataObject = {
            labels: labels,
            datasets: dataSets,
        }

        setOrigGraphData(origDataObject)
    }

    const _legendIsVisible = (legend: string, newLegends: any) => {
        for (let i = 0; i < newLegends.length; i++) {
            if (newLegends[i].label == legend) {
                if (newLegends[i].visible) {
                    return newLegends[i].label
                } else {
                    return ''
                }
            }
        }
    }

    const showLines = (name: string) => {
        const newLegends: any = []
        for (let i = 0; i < legends.length; i++) {
            if (legends[i]) {
                const legend = legends[i] as any
                if (legend.label == name) {
                    newLegends.push({
                        label: name,
                        visible: !legend.visible,
                    })
                } else {
                    newLegends.push(legend)
                }
            }
        }
        setLegends(newLegends)

        const newDataSets = origGraphData.datasets.filter((data: any) =>
            _legendIsVisible(data.label, newLegends),
        )
        const newDatas = {
            labels: graphData.labels,
            datasets: newDataSets,
            options: graphData.options,
        }

        setGraphData(newDatas)
    }

    const labelsData = legends.map((_: any, i: number) => (
        <span
            className={
                _.visible ? 'trackerGraphButton' : 'trackerGraphButton inactive'
            }
            style={{ backgroundColor: _getCategoryColour(_.label, _.visible) }}
            key={i}
            onClick={() => showLines(_.label)}
        >
            {_.label.charAt(0).toUpperCase() + _.label.slice(1)}
        </span>
    ))

    return (
        <div id="trackers" className="tab-pane container active">
            <h6 className="semi-underline mt-4">Trackers</h6>
            <div id="legendBox">
                <span style={{ marginRight: '20px', marginLeft: '30px' }}>
                    Filter:{' '}
                </span>
                <span>{labelsData}</span>
            </div>
            <Chart
                type="line"
                data={graphData}
                options={options}
                height="100px"
            />
        </div>
    )
}

export default Trackers
