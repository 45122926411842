import { Component } from 'react'
import PropTypes from 'prop-types'
import { Message } from '../Message/message'
import './MessageList.css'
import { getStaticsValue } from '../_helpers/statics'

export class MessageList extends Component {
    static propTypes = {
        messages: PropTypes.arrayOf(PropTypes.object),
        channel: PropTypes.any,
        isTyping: PropTypes.bool,
        videoView: PropTypes.any,
        messageRef: PropTypes.any,
    }

    static defaultProps = {
        messages: [],
    }

    state = {
        coachId: window.localStorage.getItem('userinfo')
            ? JSON.parse(
                  window.localStorage.getItem('userinfo') ?? '{}',
              )._id.toString()
            : null,
    }

    node: any
    componentDidUpdate = () => {
        this.node.scrollTop = this.node.scrollHeight
    }
    componentDidMount = () => {
        this.node.scrollTop = this.node.scrollHeight
    }

    render() {
        return (
            <>
                <div className="chat-search-container">
                    <div className="columns">
                        {
                            // @ts-ignore
                            this.props.channel.image && (
                                <img
                                    className="thumbnail"
                                    src={
                                        // @ts-ignore
                                        this.props.channel.image
                                    }
                                    alt="pic"
                                    width="49px"
                                    height="49px"
                                />
                            )
                        }
                        <span className="chat-title">
                            {
                                // @ts-ignore
                                this.props.channel.coachee &&
                                    // @ts-ignore
                                    this.props.channel?.coachee?.user
                                        .firstName +
                                        ' ' +
                                        // @ts-ignore
                                        this.props.channel?.coachee?.user
                                            ?.lastName
                            }
                            {
                                // @ts-ignore
                                this.props.channel.coachee &&
                                    // @ts-ignore
                                    this.props.channel.coachee
                                        ?.coachingLanguage &&
                                    // @ts-ignore
                                    this.props.channel.coachee
                                        ?.coachingLanguage !== 'en' && (
                                        <>
                                            {' ('}
                                            {getStaticsValue(
                                                'languages',
                                                // @ts-ignore
                                                this.props.channel?.coachee
                                                    ?.coachingLanguage,
                                            )}{' '}
                                            speaking{')'}
                                        </>
                                    )
                            }
                        </span>
                    </div>
                    <div className="columns"></div>
                </div>
                <div className="messages-container">
                    <div
                        className={
                            // @ts-ignore
                            this.props.videoView
                                ? 'MessageListVideo'
                                : 'MessageList'
                        }
                        ref={(node) => {
                            if (node) {
                                this.node = node
                                ;(this.props as any).messageRef.current = node
                            }
                        }}
                    >
                        {
                            // @ts-ignore
                            this.props.messages.map(
                                (message: any, i: number) => (
                                    <Message
                                        canDelete={false}
                                        key={i}
                                        {...message}
                                        videoView={
                                            // @ts-ignore
                                            this.props.videoView
                                        }
                                        image={
                                            // @ts-ignore
                                            this.props.channel?.image
                                        }
                                    />
                                ),
                            )
                        }
                    </div>
                    {
                        // @ts-ignore
                        this.props.isTyping && (
                            <img
                                className="bouncing-dots"
                                src="/assets/typing-dots.gif"
                                width="35px"
                            />
                        )
                    }
                </div>
            </>
        )
    }
}
