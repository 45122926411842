import { userConstants } from '../_constants'

let userinfo = JSON.parse(window.localStorage.getItem('userinfo') ?? '{}')

const userinitialState = userinfo
    ? { isHeader: true, userinfo }
    : { isHeader: false, userinfo: {} }
export function userreducer(
    state = userinitialState,
    action: { type: string; payload: any },
) {
    switch (action.type) {
        case userConstants.GET_ME_SUCCESS:
            return {
                isHeader: true,
                userinfo: action.payload,
            }
        case userConstants.GET_ME_FAILURE:
            return {}
        case userConstants.GET_ME_LOGOUT:
            return { isHeader: false, userinfo: {} }
        default:
            return state
    }
}
