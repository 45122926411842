import { userConstants } from "../_constants";
import axios from "axios";

const enabled = (userConstants.ENV === 'production' || userConstants.ENV === 'staging')
const coachId = window.localStorage.getItem("userinfo") ? JSON.parse(window.localStorage.getItem("userinfo"))._id.toString() : null;

import { detect } from 'detect-browser'
const browser = detect();

const _capitalize = (s) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

const _getDefaultPayloadDetails = (payload) => {
	payload.distinct_id = coachId;
	payload.platform = "Web";
	payload.$browser = _capitalize(browser.name);
	payload.$browser_version = browser.version;
	payload.$os = _capitalize(browser.os);
	payload.$screen_height = window.innerHeight;
	payload.$screen_width = window.innerWidth;
	payload.$current_url = window.location.href
	return payload;
}

export const send = (event, payload) => {
	const token = JSON.parse(localStorage.getItem('user'))?.accessToken
	if (token) {
		payload = _getDefaultPayloadDetails(payload)
		payload.event = event;

		if (enabled) {
			axios.post(`${userConstants.BASE_URL}sama/mix`, payload)
		} else {
			console.log('No tracking enabled');
			console.log("event: " + event);
			console.log("payload: " + JSON.stringify(payload));
		}
	}
}


export const sendLogEvent = (payload) => {
	axios.post(`${userConstants.BASE_URL}sama/l/event`, payload)
};

export const TrackingService = {
	send,
	sendLogEvent
};

