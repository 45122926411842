import { ImageStore } from '../types/image-store.t'
import { CommonService } from '../_services/common.service'
import { dataURItoBlob } from '../misc/images'

export const getCoachImage = () => {
    const user: any = JSON.parse(
        window.localStorage.getItem('userinfo') ?? '{}',
    )
    let picutreFromStore: ImageStore | null = null
    try {
        picutreFromStore = JSON.parse(
            window.localStorage.getItem('picture') ?? '{}',
        )
    } catch {}

    if (
        !picutreFromStore?.expires ||
        !picutreFromStore?.image ||
        picutreFromStore?.expires < new Date()
    ) {
        CommonService.getProfilePicture(user._id)
    }
    let objectURL = ''
    if (picutreFromStore?.image) {
        const blob = dataURItoBlob(picutreFromStore?.image)
        objectURL = URL.createObjectURL(blob)
    }
    return objectURL
}
