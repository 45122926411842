import { addDays, format } from 'date-fns'
import subDays from 'date-fns/subDays'
import { useEffect, useState } from 'react'
import { Calendar } from 'react-calendar'
import { CommonService } from '../_services/common.service'
import calendarIcon from '../assets/icons/calendar-filter.svg'
import checkIcon from '../assets/icons/check.svg'
import chevronIcon from '../assets/icons/chevron-down.svg'
import { formatDayDate } from '../misc/dates'

interface ISessionProps {
    coacheeId: string
    sessionsData?: any[]
}

export default function SessionHistory({ coacheeId }: ISessionProps) {
    const [sessionsData, setSessionsData] = useState([])
    const [isFilterOn, setIsFilterOn] = useState(false)
    const [datesFilter, setDatesFilter] = useState<{
        startDate: Date | null
        endDate: Date | null
    }>({
        startDate: null,
        endDate: null,
    })
    const [dates, setDates] = useState<Date[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

    useEffect(() => {
        loadSessionHistory()
    }, [coacheeId, datesFilter])

    useEffect(() => {
        //if calendar is open and two dates are selected, then filter by dates
        if (isDatePickerOpen && dates.length === 2 && isFilterOn) {
            setIsDatePickerOpen(false)

            setDatesFilter({
                startDate: dates[0],
                endDate: dates[1],
            })

            setDates([])
        }
    }, [dates])

    function isDateSelected(datesRange: Date[], value: Date) {
        return !!datesRange.find((item) => {
            return item.getTime() === value.getTime()
        })
    }

    function onClickDay(date: Date) {
        //select the first two days clicked
        if (!isDateSelected(dates, date) && dates.length < 2) {
            const sortedDates = [...dates, date].sort((a: Date, b: Date) => {
                return a.getTime() - b.getTime()
            })
            setDates(sortedDates)
            setIsFilterOn(true)
        }
    }

    function toggleDatePicker() {
        setIsDatePickerOpen(!isDatePickerOpen)
    }

    async function loadSessionHistory() {
        let object = {
            startDate: datesFilter.startDate,
            endDate: datesFilter.endDate,
        }
        setIsLoading(true)
        CommonService.getCoacheeSessions(
            coacheeId,
            object,
            function (res: any) {
                if (!res.data.empty) {
                    res.data.reverse()
                    setSessionsData(res.data)
                    setIsLoading(false)
                } else {
                    setSessionsData([])
                }
            },
        )
    }

    return (
        <div id="sessions" className="tab-pane container active">
            <div className="flex items-center justify-between">
                <div>
                    <h6 className="semi-underline pt-8">
                        <div className="font-bold mb-8">Session history</div>
                    </h6>
                </div>

                <div>
                    <button
                        onClick={toggleDatePicker}
                        className={`flex border-[1px] border-gray-300 py-2 px-4 items-center justify-evenly rounded-full h-[36px] gap-2  hover:border-[#004e72] ${
                            isFilterOn ? 'text-[#ff7d48]' : 'text-[#004e72]'
                        }`}
                    >
                        <img src={calendarIcon} className="h-5 w-5" />
                        <div>
                            {datesFilter.startDate === null
                                ? format(subDays(new Date(), 7), 'MMM dd yyyy')
                                : format(datesFilter.startDate, 'MMM dd yyyy')}
                            {' - '}
                            {datesFilter.endDate === null
                                ? format(new Date(), 'MMM dd yyyy')
                                : format(datesFilter.endDate, 'MMM dd yyyy')}
                        </div>
                        <img src={chevronIcon} className="h-2 w-2" />
                    </button>

                    {isDatePickerOpen && (
                        <div className="flex absolute pt-2 -ml-32">
                            <Calendar
                                value={[
                                    datesFilter.startDate,
                                    addDays(new Date(datesFilter.endDate!), 1),
                                ]}
                                onClickDay={onClickDay}
                            />
                        </div>
                    )}
                </div>
            </div>

            {isLoading ? (
                'Loading sessions...'
            ) : (
                <div>
                    {sessionsData?.map((session: any) => {
                        return (
                            <div
                                key={session._id}
                                className="flex items-center justify-between h-[50px] border-b-[1px] border-gray-200 py-8"
                            >
                                <div className="text-base flex">
                                    <div
                                        className={`font-bold text-white rounded-full ${
                                            session.state === 'cancel'
                                                ? `bg-[#ff5353]`
                                                : session.state === 'close'
                                                ? `bg-[#008aa6] ]`
                                                : session.state === 'planned'
                                                ? `bg-transparent border-2 border-gray-300`
                                                : ``
                                        } flex items-center justify-center h-10 w-10 mr-4`}
                                    >
                                        <div>
                                            {session.state === 'cancel' ? (
                                                <div className="font-bold text-xl">
                                                    X
                                                </div>
                                            ) : session.state === 'planned' ? (
                                                ''
                                            ) : (
                                                <>
                                                    {' '}
                                                    <img
                                                        src={checkIcon}
                                                        alt="React Logo"
                                                        className="h-4 w-4"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div>
                                            {formatDayDate(
                                                new Date(session.date),
                                            )}
                                        </div>
                                        <div className="text-gray-500 text-sm">
                                            {' '}
                                            {session.isVideoSession
                                                ? 'Video'
                                                : 'Call'}
                                            {' - ' + session.duration + ' min'}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${
                                        session.state === 'cancel'
                                            ? `bg-red-100 text-[#ff5353]`
                                            : session.state === 'close'
                                            ? `bg-[#f0f8fe] text-[#008aa6]`
                                            : session.state === 'planned'
                                            ? `bg-transparent border-dashed border-2 border-gray-300`
                                            : ``
                                    } rounded-full px-4 py-2 text-xs`}
                                >
                                    {session.state === 'cancel'
                                        ? 'LATE CANCEL'
                                        : session.state === 'planned'
                                        ? 'UPCOMING'
                                        : 'COMPLETED'}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            {sessionsData?.length === 0 && !isLoading && (
                <div>No sessions yet.</div>
            )}
        </div>
    )
}
