import { IsBoolean, IsOptional, IsString } from 'class-validator'
import { BaseModel } from '../base.model'

export class RescheduleBookingModel implements BaseModel {
    constructor(
        _id: string,
        date: string,
        duration: number,
        isVideoSession: boolean,
        whoNeededChange: string = '',
        reasonForChange: string = '',
        customReason: string = '',
        reachOutToCoachee: boolean = false,
    ) {
        ;(this._id = _id),
            (this.date = date),
            (this.duration = duration),
            (this.isVideoSession = isVideoSession),
            (this.whoNeededChange = whoNeededChange),
            (this.reasonForChange = reasonForChange),
            (this.customReason = customReason),
            (this.reachOutToCoachee = reachOutToCoachee)
    }

    @IsOptional()
    public _id: string | undefined

    @IsString()
    public date: string | undefined

    @IsString()
    public duration: number | undefined

    @IsBoolean()
    public isVideoSession: boolean

    @IsOptional()
    public whoNeededChange: string | ''

    @IsOptional()
    public reasonForChange: string | ''

    @IsOptional()
    public customReason: string | ''

    @IsOptional()
    public reachOutToCoachee: boolean

    public getRequestBody() {
        return {
            _id: this._id,
            date: this.date,
            duration: this.duration,
            isVideoSession: this.isVideoSession,
            whoNeededChange: this.whoNeededChange,
            reasonForChange: this.reasonForChange,
            customReason: this.customReason,
            reachOutToCoachee: this.reachOutToCoachee,
        }
    }
}
