import axios from 'axios'
import { isEmail } from 'class-validator'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { alertActions } from '../../../_actions/alert.actions'
import { userConstants } from '../../../_constants'
import { getClassNames } from '../../../_helpers/get-class-names'
import { AppleConnectionForm } from './apple-connection-form'
import { ChangeCalendarForm } from './change-calendar-form'
import { DropdownMenu } from './dropdown-menu'

const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
})

interface Props {
    coach: any
    calendarInfo: any
    selectCalendar: any
    disconnectCalendar: any
    isEnteringCredentials: any
    setIsEnteringCredentials: any
    dispatch: any
}

export const AppleCalendarManager = connect(mapStateToProps)(
    ({
        coach,
        calendarInfo,
        selectCalendar,
        disconnectCalendar,
        isEnteringCredentials,
        setIsEnteringCredentials,
        dispatch,
    }: Props) => {
        const isConnected = calendarInfo.provider === 'apple'
        const calendarSelected = calendarInfo.calendar !== null
        const [showCalendarSelection, setShowCalendarSelection] =
            useState(false)
        const navigate = useNavigate()

        const openEntry = () => {
            setIsEnteringCredentials(true)
        }

        const signIn = async (values: any) => {
            const user = localStorage.getItem('user')
            const token = user ? JSON.parse(user).accessToken : null
            const _axios = axios.create() // Free of interceptors
            try {
                await _axios.put(
                    `${userConstants.BASE_URL}coach/${coach._id}/apple/credentials`,
                    values,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )
                navigate(0)
            } catch (error: any) {
                let message = ''

                if (
                    error.response?.data &&
                    typeof error.response.data === 'string'
                ) {
                    message = error.response.data
                }

                return dispatch(alertActions.error(message))
            }
        }

        const validate = (values: any) => {
            const errors: any = {}

            if (!isEmail(values.email)) {
                errors.email = 'Email is not valid'
            }

            if (!values.password) {
                errors.password = 'App-specific password is required'
            }

            return errors
        }

        useEffect(() => {
            if (isConnected && !calendarSelected) {
                setShowCalendarSelection(true)
            }
        }, [isConnected, calendarSelected, setShowCalendarSelection])

        return (
            <div
                className={getClassNames(
                    'calendar-manager',
                    isConnected && 'connected',
                    (isEnteringCredentials || showCalendarSelection) && 'open',
                )}
            >
                <div className="icon-area">
                    <img
                        src="/assets/images/logos/apple/Vector.png"
                        className="icon-area-logo"
                    />
                </div>

                <div className="text-area">
                    <span>Apple iCloud Calendar</span>

                    {!isEnteringCredentials && !showCalendarSelection && (
                        <span className="connection-text">
                            {isConnected ? 'Connected' : 'Not connected'}
                        </span>
                    )}

                    {showCalendarSelection && (
                        <ChangeCalendarForm
                            selectCalendar={selectCalendar}
                            setShowCalendarSelection={setShowCalendarSelection}
                            calendarInfo={calendarInfo}
                        />
                    )}

                    {isEnteringCredentials && (
                        <AppleConnectionForm
                            validate={validate}
                            signIn={signIn}
                        />
                    )}
                </div>

                {!isConnected && !isEnteringCredentials && (
                    <div className="button-control-area">
                        <button onClick={openEntry}>Connect</button>
                    </div>
                )}

                {isConnected && !showCalendarSelection && (
                    <div className="button-control-area">
                        <DropdownMenu
                            disconnectCalendar={disconnectCalendar}
                            setShowCalendarSelection={setShowCalendarSelection}
                        />
                    </div>
                )}
            </div>
        )
    },
)
