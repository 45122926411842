import { Dialog, Transition } from '@headlessui/react'
import React, { useCallback, Fragment } from 'react'
import { classNames } from '../utils/misc/class-names'
import { ModalCloseButton } from '../_components/buttons/model-close-buttons'
import { ModalProps } from './modal-props'

interface Props extends ModalProps {
    className?: string
    mainClassNames?: string
}

export const CenterModal: React.FC<Props> = ({
    children,
    className,
    mainClassNames,
    isOpen,
    setIsOpen,
    initialFocus,
}) => {
    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo=" translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom=" translate-y-0 sm:scale-100"
            leaveTo="translate-y-4 sm:translate-y-0 sm:scale-95"
            as={Fragment}
        >
            <Dialog
                open={isOpen}
                onClose={close}
                className="z-50 flex isolate fixed inset-0 justify-center p-2 overflow-hidden h-screen"
                initialFocus={initialFocus}
            >
                <div
                    className="w-screen fixed inset-0 bg-[#030412]/30"
                    aria-hidden="true"
                />

                <Dialog.Panel
                    className={classNames(
                        'justify-center m-auto',
                        'items-center rounded-md relative mt-[1px]',
                        className,
                    )}
                >
                    <ModalCloseButton
                        onClick={close}
                        className="absolute right-3 top-3"
                    />
                    <main className={classNames('h-full', mainClassNames)}>
                        {children}
                    </main>
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}
