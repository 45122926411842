import React, { ReactElement } from 'react'
import tooltip from '@mui/material/Tooltip'
import { withStyles } from 'tss-react/mui'

interface Props {
    title: string
    children: ReactElement<any, any>
}

const TooltipWrap = withStyles(tooltip, () => ({
    tooltip: {
        color: '#FFFFF',
        backgroundColor: '#10123a',
    },
    arrow: {
        '&:before': {
            border: '1px solid #10123a',
            backgroundColor: '#10123a',
        },
    },
}))

export const Tooltip: React.FC<Props> = ({ title, children }) => {
    return (
        <TooltipWrap title={title} placement="top" arrow>
            {children}
        </TooltipWrap>
    )
}
