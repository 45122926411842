import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { userConstants } from '../_constants/user.constants'
import { useLocation } from 'react-router-dom'

function getRedirectUrl() {
    return axios
        .get(`${userConstants.BASE_URL}auth/login/google`)
        .then(function (res) {
            return res.data.redirectUrl
        })
        .catch(function (error) {
            Sentry.captureException(error)
            // handle error
            console.log(error)
        })
}

async function CalendarLogin(props: any) {
    const redirectUrl = await getRedirectUrl()
    if (redirectUrl.includes('https://accounts.google.com/')) {
        return (window.location = redirectUrl)
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function GoogleReturnUrl(props: any) {
    const query = useQuery()

    useEffect(() => {
        const id = JSON.parse(
            window.localStorage.getItem('userinfo') ?? '{}',
        )._id.toString()
        if (id) {
            axios
                .put(`${userConstants.BASE_URL}coach/${id}/google/tokens`, {
                    code: query.get('code'),
                })
                .then((response) => {
                    window.location.replace('/profile?success=true')
                })
                .catch((error) => {
                    Sentry.captureException(error)
                    window.location.replace(
                        '/profile?success=false&error=' + error.response.data,
                    )
                })
        }
    }, [])
    return <p>Waiting to redirect...</p>
}

function AzureReturnUrl(props: any) {
    const query = useQuery()

    useEffect(() => {
        const id = JSON.parse(
            window.localStorage.getItem('userinfo') ?? '{}',
        )._id.toString()
        if (id) {
            axios
                .put(`${userConstants.BASE_URL}coach/${id}/azure/tokens`, {
                    code: query.get('code'),
                })
                .then(() => {
                    window.location.replace('/profile?success=true')
                })
                .catch((error) => {
                    Sentry.captureException(error)
                    window.location.replace('/profile?success=false')
                })
        }
    }, [])
    return <p>Waiting to redirect...</p>
}

function CalendarSave(props: any) {
    const [calendars, setCalendars] = useState([])
    const id = JSON.parse(
        window.localStorage.getItem('userinfo') ?? '{}',
    )._id.toString()
    useEffect(() => {
        axios
            .get(`${userConstants.BASE_URL}coach/${id}/google/calendars`)
            .then(function (res) {
                setCalendars(res.data)
            })
    }, [])

    const Card = ({
        friend,
        id,
        name,
    }: {
        friend: any
        id: any
        name: any
    }) => <option value="{id}}">{name}</option>

    const calItems = calendars.map((item: any, i: number) => {
        return <Card key={i} {...item} />
    })

    return (
        <div>
            <select name="calendars" id="calendars">
                {calItems}
            </select>
        </div>
    )
}

export { CalendarLogin, GoogleReturnUrl, CalendarSave, AzureReturnUrl }
