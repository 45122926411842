import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import * as Sentry from '@sentry/browser'
import { CenterModal } from '../modals/center-modal'
import { SelectInput } from '../_components/select-input'
import { Formik, FormikHelpers, FormikValues, useField } from 'formik'
import { classNames, Option } from '../utils/misc'
import playIcon from '../assets/icons/sound-icon.svg'
import refreshIcon from '../assets/icons/refresh-icon.svg'
import { useStores } from '../utils/stores'
import { DEVICE_TYPES } from '../stores'
import { first } from 'lodash'
import { detect } from 'detect-browser'

const browser = detect()

export interface OptionList {
    speakerList: any
    microphoneList: any
}

const VideoSection = ({ cameraList }: { cameraList: Option[] }) => {
    const { settings } = useStores()
    const videoPreivewRef = useRef() as MutableRefObject<any>
    const [error, setError] = useState<string | null>()

    useEffect(() => {
        setCamera()

        return () => {
            settings.stopStream(DEVICE_TYPES.Camera)
        }
    }, [])

    const getCameraInitial = useCallback(() => {
        const id = settings.getDeviceId(DEVICE_TYPES.Camera)
        const opt = first(
            cameraList.filter((option: Option) => option.value === id),
        )
        return opt
    }, [cameraList])

    const setCamera = useCallback(
        async (option?: Option) => {
            setError(null)

            let deviceId: string | null = null
            if (option) {
                deviceId = option.value as string
                settings.setDeviceId(DEVICE_TYPES.Camera, deviceId)
            } else if (settings.getDeviceId(DEVICE_TYPES.Camera)) {
                deviceId = settings.cameraDeviceId
            }

            let videoSettings: any = { video: true }
            if (deviceId) {
                videoSettings = { video: { deviceId: deviceId } }
            }
            await navigator.mediaDevices
                .getUserMedia(videoSettings)
                .then((stream) => {
                    settings.setStream(DEVICE_TYPES.Camera, stream)
                    if (videoPreivewRef.current) {
                        videoPreivewRef.current.srcObject = stream
                        videoPreivewRef.current.play()
                    }
                })
                .catch((e) => {
                    Sentry.captureException(e)
                    settings.stopStream(DEVICE_TYPES.Camera)
                    settings.removeDeviveType(DEVICE_TYPES.Camera)
                    setError(e.message)
                })
        },
        [settings.cameraDeviceId],
    )

    return (
        <div>
            <h2>Video settings</h2>
            <div>
                <Formik
                    initialValues={{
                        cameras: getCameraInitial(),
                    }}
                    onSubmit={function (
                        values: FormikValues,
                        formikHelpers: FormikHelpers<FormikValues>,
                    ): void | Promise<any> {
                        throw new Error('Function not implemented.')
                    }}
                >
                    <form>
                        <SelectInput
                            name="cameras"
                            className="flex-1"
                            options={cameraList}
                            onChange={setCamera}
                            placeholder={'Default'}
                        />
                    </form>
                </Formik>
                {!error && (
                    <div className="m-auto w-[200px] h-full pt-4">
                        {videoPreivewRef && (
                            <video muted ref={videoPreivewRef} />
                        )}
                    </div>
                )}
                {error && (
                    <div className="m-auto w-[80%] h-full pt-4">
                        <h3>Unable to use Camera</h3>
                        <p>
                            Something went wrong when trying to use your camera.
                            Make sure that no other apps are using your camera.
                            If this error persists, please contact{' '}
                            <a
                                className="font-semi-bold"
                                href="support@sama.io"
                            >
                                support@sama.io
                            </a>
                            .
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

const mp3 = new Audio('/sounds/sama-jingle.mp3')

const AudioSection = ({
    microphoneList,
    speakerList,
    refreshList,
}: {
    microphoneList: Option[]
    speakerList: Option[]
    refreshList: any
}) => {
    const { settings } = useStores()
    const defaultDeviceOption: Option = {
        value: 'default',
        display: 'Default',
    }

    const getMicrophone = useCallback(
        (id: string) => {
            const opt = first(
                microphoneList.filter((option: Option) => option.value === id),
            )
            return opt
        },
        [microphoneList],
    )

    const getSpeaker = useCallback(
        (id: string) => {
            const opt = first(
                speakerList.filter((option: Option) => option.value === id),
            )
            return opt
        },
        [speakerList],
    )

    const [defaultMicrophone, setDefaultMicrophone] = useState<Option>(
        getMicrophone(settings.getDeviceId(DEVICE_TYPES.Microphone) ?? '') ??
            defaultDeviceOption,
    )

    const [defaultSpeaker, setDefaultSpeaker] = useState<Option>(
        getSpeaker(settings.getDeviceId(DEVICE_TYPES.Speaker) ?? '') ??
            defaultDeviceOption,
    )

    const micBounceRef = useRef<HTMLInputElement>(null)
    const mounted = useRef(false)

    // this has to be a state so it is unique to the componant
    // I promise do not change this from a state param
    const [audioSound, setAudio] = useState<HTMLAudioElement>(mp3)
    const [soundPlaying, setSoundPlaying] = useState(false)

    const onFrame = useCallback(
        (analyserNode: any, pcmData: any) => {
            analyserNode.getFloatTimeDomainData(pcmData)
            let sumSquares = 0.0
            for (const amplitude of pcmData) {
                sumSquares += amplitude * amplitude
            }
            const value = Math.sqrt(sumSquares / pcmData.length)
            if (micBounceRef.current) {
                let height = '0px'
                if (value > 0.1) {
                    height = '50px'
                } else if (value > 0.01) {
                    height = '35px'
                } else if (value > 0.02) {
                    height = '25px'
                } else if (value > 0.002) {
                    height = '10px'
                }
                micBounceRef.current.style.height = height
            }

            mounted.current
                ? window.requestAnimationFrame(() =>
                      onFrame(analyserNode, pcmData),
                  )
                : null
        },
        [defaultMicrophone],
    )

    const testVolume = useCallback(
        (stream: MediaStream) => {
            const audioContext = new AudioContext()
            const mediaStreamAudioSourceNode =
                audioContext.createMediaStreamSource(stream)
            const analyserNode = audioContext.createAnalyser()
            mediaStreamAudioSourceNode.connect(analyserNode)

            const pcmData = new Float32Array(analyserNode.fftSize)
            window.requestAnimationFrame(() => onFrame(analyserNode, pcmData))
        },

        [defaultMicrophone],
    )

    const playSound = useCallback(() => {
        setSoundPlaying(true)
        audioSound.play()
        audioSound.onended = () => {
            setSoundPlaying(false)
        }
    }, [defaultMicrophone])

    const stopSound = useCallback(() => {
        setSoundPlaying(false)

        audioSound.pause()
    }, [defaultMicrophone])

    useEffect(() => {
        mounted.current = true
        let audioSettings: { audio: boolean | {} } = {
            audio: true,
        }
        if (defaultMicrophone) {
            audioSettings = {
                audio: {
                    deviceId: defaultMicrophone.value as ConstrainDOMString,
                },
            }
        }

        try {
            navigator.mediaDevices
                .getUserMedia(audioSettings)
                .then((stream) => {
                    settings.setStream(DEVICE_TYPES.Microphone, stream)
                })
                .then(() => {
                    if (settings.streams['MICROPHONE']) {
                        testVolume(settings.streams['MICROPHONE'])
                    }
                })
                .catch((e) => {
                    Sentry.captureException(e)
                })
        } catch (e) {
            Sentry.captureException(e)
        }

        return () => {
            mounted.current = false
            settings.stopStream(DEVICE_TYPES.Microphone)
        }
    }, [defaultMicrophone])

    const setSpeakerDevice = useCallback(
        (option: Option) => {
            settings.setDeviceId(DEVICE_TYPES.Speaker, option.value as string)
            setDefaultSpeaker(option)
        },
        [settings.speakerDeviceId],
    )

    const setMicrophoneDevice = useCallback(
        (option: Option) => {
            settings.setDeviceId(
                DEVICE_TYPES.Microphone,
                option.value as string,
            )
            setDefaultMicrophone(option)
        },
        [settings.speakerDeviceId],
    )

    useEffect(() => {
        if (speakerList.length > 0) {
            const didUpdate = settings.refreshSavedDeviceId(
                DEVICE_TYPES.Speaker,
                speakerList,
            )

            if (didUpdate) {
                setDefaultSpeaker(
                    getSpeaker(
                        settings.getDeviceId(DEVICE_TYPES.Speaker) ?? '',
                    ) ?? defaultDeviceOption,
                )
            }
        }
        if (microphoneList.length > 0) {
            const didUpdate = settings.refreshSavedDeviceId(
                DEVICE_TYPES.Microphone,
                microphoneList,
            )

            if (didUpdate) {
                setDefaultMicrophone(
                    getMicrophone(
                        settings.getDeviceId(DEVICE_TYPES.Microphone) ?? '',
                    ) ?? defaultDeviceOption,
                )
            }
        }
    }, [speakerList, microphoneList])

    useEffect(() => {
        stopSound()
        if (browser && browser.name !== 'safari') {
            let audioSettings: { audio: boolean | {} } = {
                audio: true,
            }
            const speakerId = settings.getDeviceId(DEVICE_TYPES.Speaker)
            if (speakerId) {
                audioSettings = {
                    audio: {
                        deviceId: speakerId,
                    },
                }
            }

            navigator.mediaDevices
                .getUserMedia(audioSettings)
                .then((stream) => {
                    stream.getTracks().map((track) => {
                        if (track.kind === 'audio') {
                            settings.setStream(DEVICE_TYPES.Speaker, stream)
                            if (speakerId) {
                                // @ts-ignore
                                audioSound.setSinkId(speakerId)
                            }
                        }
                    })
                })
                .catch((e) => {
                    Sentry.captureException(e)
                })
        }
        return () => {
            stopSound()
            settings.stopStream(DEVICE_TYPES.Speaker)
        }
    }, [defaultSpeaker])

    return (
        <>
            {defaultMicrophone && defaultSpeaker && (
                <div className="h-full">
                    <h2>Audio settings</h2>
                    <div>
                        <Formik
                            initialValues={{
                                microphones:
                                    getMicrophone(
                                        settings.getDeviceId(
                                            DEVICE_TYPES.Microphone,
                                        ) ?? '',
                                    ) ?? defaultDeviceOption,
                                speakers:
                                    getSpeaker(
                                        settings.getDeviceId(
                                            DEVICE_TYPES.Speaker,
                                        ) ?? '',
                                    ) ?? defaultDeviceOption,
                            }}
                            onSubmit={() => {}}
                        >
                            <form>
                                <div className="flex">
                                    <SelectInput
                                        name="microphones"
                                        label="Microphone"
                                        value={defaultMicrophone}
                                        className="flex-1"
                                        options={microphoneList}
                                        onChange={setMicrophoneDevice}
                                        placeholder={'Default'}
                                    />
                                    <img
                                        className={classNames(
                                            'pointer mt-4 mr-[1px] ml-2',
                                            'self-center w-3 h-3',
                                        )}
                                        title="Refresh micprophone list"
                                        src={refreshIcon}
                                        onClick={refreshList}
                                    />
                                    <div
                                        className={classNames(
                                            'relative w-[6px]',
                                            'mx-2 h-12 self-end h-[50px]',
                                            'border-[1px] border-[#434567]',
                                            'flex items-center flex-column justify-between',
                                        )}
                                    >
                                        <div
                                            ref={micBounceRef}
                                            className={classNames(
                                                'absolute bottom-0',
                                                'w-[6px] bg-[#000000]',
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="flex pt-3">
                                    <SelectInput
                                        name="speakers"
                                        label="Speakers"
                                        value={defaultSpeaker}
                                        className="flex-1"
                                        options={speakerList}
                                        onChange={setSpeakerDevice}
                                        placeholder={'Default'}
                                    />
                                    <img
                                        className={classNames(
                                            'pointer mt-4 mr-[1px] ml-2',
                                            'self-center w-3 h-3',
                                        )}
                                        title="Refresh speaker list"
                                        src={refreshIcon}
                                        onClick={refreshList}
                                    />
                                    <img
                                        className={classNames(
                                            soundPlaying ? 'opacity-50' : '',
                                            'pointer mt-4 ml-2 ',
                                            'self-center w-5 h-5 ',
                                        )}
                                        src={playIcon}
                                        onClick={playSound}
                                    />{' '}
                                    <span
                                        onClick={playSound}
                                        title="Test the active speaker"
                                        className={classNames(
                                            soundPlaying ? 'opacity-50' : '',
                                            'pointer mt-3 mr-6 ml-[2px]',
                                            'self-center w-5 h-5 ',
                                        )}
                                    >
                                        {soundPlaying && <>Playing</>}
                                        {!soundPlaying && <>Test</>}
                                    </span>
                                </div>
                            </form>
                        </Formik>
                    </div>
                </div>
            )}
        </>
    )
}

const SECTION_TYPES = {
    Video: 'Video',
    Audio: 'Audio',
}

export const RoomSettings = ({
    open,
    setOpen,
}: {
    open: boolean
    setOpen: any
}) => {
    const { settings } = useStores()

    const sections = [SECTION_TYPES.Audio, SECTION_TYPES.Video]

    const [selectedSection, setSelectedSection] = useState<string>(
        SECTION_TYPES.Video,
    )

    const [microphoneList, setMicrophoneList] = useState<Option[]>([
        { value: '', display: '' },
    ])
    const [cameraList, setCameraList] = useState<Option[]>([
        { value: '', display: 'Default' },
    ])
    const [speakerList, setSpeakerList] = useState<Option[]>([
        { value: '', display: 'Default' },
    ])

    useEffect(() => {
        if (open) {
            getOptions()

            navigator.mediaDevices.ondevicechange = function () {
                getOptions()
            }
        }

        return () => {
            settings.stopStream(DEVICE_TYPES.Camera)
            settings.stopStream(DEVICE_TYPES.Microphone)
            settings.stopStream(DEVICE_TYPES.Speaker)
        }
    }, [open])

    const getOptions = useCallback(async () => {
        const audioList: Option[] = []
        const microphoneList: Option[] = []
        const cameraList: Option[] = []
        try {
            let audioStream: MediaStream | undefined =
                await navigator.mediaDevices.getUserMedia({
                    audio: true,
                })

            return await navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    devices.forEach((device) => {
                        if (device.kind === 'audioinput') {
                            microphoneList.push({
                                value: device.deviceId,
                                display: device.label,
                            })
                        }
                        if (device.kind === 'audiooutput') {
                            audioList.push({
                                value: device.deviceId,
                                display: device.label,
                            })
                        }
                        if (device.kind === 'videoinput') {
                            const exists = cameraList.filter(
                                (cam) => cam.value === device.deviceId,
                            )
                            if (exists.length === 0) {
                                cameraList.push({
                                    value: device.deviceId,
                                    display: device.label,
                                })
                            }
                        }
                    })
                    setCameraList(cameraList)
                    setSpeakerList(audioList)
                    setMicrophoneList(microphoneList)
                })
                .then(() => {
                    if (audioStream) {
                        audioStream.getTracks().forEach((track) => {
                            track.stop
                            audioStream?.removeTrack(track)
                        })
                    }
                    audioStream = undefined
                })
                .catch(function (err) {
                    Sentry.captureMessage(
                        'getOptions() - cameraList "' +
                            JSON.stringify(cameraList) +
                            '"',
                    )
                    Sentry.captureMessage(
                        'getOptions() - microphoneList "' +
                            JSON.stringify(microphoneList) +
                            '"',
                    )
                    Sentry.captureMessage(
                        'getOptions() - audioList "' +
                            JSON.stringify(audioList) +
                            '"',
                    )

                    Sentry.captureException(err)
                    /* handle the error */
                    throw err
                })
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [open, microphoneList, cameraList, speakerList])

    return (
        <CenterModal
            isOpen={open}
            setIsOpen={() => setOpen(false)}
            className="w-[600px] h-1/2 bg-[#FFFFFF] h-[300px] inline-block"
        >
            <div className="flex h-full">
                <div className="block  min-w-[20%] p-4">
                    {sections.map((section, index) => (
                        <li
                            key={index}
                            className={classNames(
                                'pointer list-none text-center px-4 py-2',
                                section === selectedSection
                                    ? 'bg-[#cde7fb] text-[#004e72] rounded-xl  '
                                    : '',
                            )}
                            onClick={() => setSelectedSection(section)}
                        >
                            {section}
                        </li>
                    ))}
                </div>
                <div className="inline-block min-w-[78%] pt-3 pl-3 pr-3 h-full border-l-2 border-l-grey">
                    {selectedSection === SECTION_TYPES.Video &&
                        (cameraList.length > 1 ||
                            microphoneList.length > 1) && (
                            <VideoSection cameraList={cameraList} />
                        )}
                    {selectedSection === SECTION_TYPES.Audio && (
                        <AudioSection
                            microphoneList={microphoneList}
                            speakerList={speakerList}
                            refreshList={getOptions}
                        />
                    )}
                </div>
            </div>
        </CenterModal>
    )
}

export default RoomSettings
