import moment from 'moment'

import './styles/goals-view.css'
import './styles/goals.css'

import Timer from '../assets/icons/timer.svg'
import Rocket from '../assets/icons/rocket.svg'
import Smiley from '../assets/icons/smily-face.svg'
import Arm from '../assets/icons/arm-muscle.svg'
import Celebrate from '../assets/icons/celebrate-cone.svg'

interface Props {
    close: any
    open: any
    key: any
    item: any
}

const GoalsView = ({ close, open, key, item }: Props) => {
    const icons = {
        0: Timer,
        25: Rocket,
        50: Smiley,
        75: Arm,
        100: Celebrate,
    }

    const progressText = {
        0: 'Not started',
        25: 'Started',
        50: 'Halfway there!',
        75: 'Nearly done',
        100: 'Completed! Well done!',
    }

    return (
        <>
            {open && (
                <div key={key} className="goal-view-container">
                    <div className="goal-view-box">
                        <div className="gb-title-box">
                            <div
                                className="btn-close-solid"
                                onClick={() => close(false)}
                            ></div>
                            <div className="gb-title">Goal details</div>
                        </div>
                        <div className="gb-content-box">
                            <p className="title-text">Description</p>
                            <div style={{ display: 'flex' }}>
                                <div className="gb-description"></div>
                                <p className="gb-description-text">
                                    {item.label}
                                </p>
                            </div>
                            <br />
                            <p className="title-text">Progress</p>
                            <img
                                className="icon"
                                src={
                                    icons[item.progress as keyof typeof icons]
                                        ? icons[
                                              item.progress as keyof typeof icons
                                          ]
                                        : icons[0]
                                }
                            />
                            <p style={{ textAlign: 'center' }}>
                                <strong>
                                    {
                                        progressText[
                                            item.progress as keyof typeof progressText
                                        ]
                                    }
                                </strong>
                            </p>
                            <div className="goal-progress-bar">
                                <div
                                    className={`goal-progress-${item.progress}`}
                                ></div>
                            </div>
                            <div className="due-date">
                                {item.completedAt && (
                                    <span>
                                        <p>Completed on:</p>
                                        <p className="completed-date">
                                            {moment(item.completedAt).format(
                                                'DD MMM YYYY',
                                            )}
                                            &nbsp;&nbsp;
                                        </p>
                                    </span>
                                )}
                                {!item.completedAt && (
                                    <span>
                                        <p>Due date:</p>
                                        <p
                                            className={
                                                item.dueDate
                                                    ? 'date'
                                                    : 'no-date'
                                            }
                                        >
                                            {item.dueDate
                                                ? moment(item.dueDate).format(
                                                      'DD MMM YYYY',
                                                  )
                                                : 'No due date set'}
                                            &nbsp;&nbsp;
                                        </p>
                                    </span>
                                )}
                                <i className="calendar" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default GoalsView
