import React, { useState } from 'react'
import './rating.css'
function RatingBar(props: any) {
    const [rateVlaue, setRateVlaue] = useState(0)
    const onRateMe = (value: any) => {
        if (typeof props.onRateMe !== 'undefined') {
            props.onRateMe(value)
        }
        setRateVlaue(value)
    }
    return (
        <div className="rating-component-wrap">
            <div className="rating">
                <span
                    onClick={() => onRateMe(5)}
                    className={rateVlaue === 5 ? 'rated' : ''}
                ></span>
                <span
                    onClick={() => onRateMe(4)}
                    className={rateVlaue === 4 ? 'rated' : ''}
                ></span>
                <span
                    onClick={() => onRateMe(3)}
                    className={rateVlaue === 3 ? 'rated' : ''}
                ></span>
                <span
                    onClick={() => onRateMe(2)}
                    className={rateVlaue === 2 ? 'rated' : ''}
                ></span>
                <span
                    onClick={() => onRateMe(1)}
                    className={rateVlaue === 1 ? 'rated' : ''}
                ></span>
            </div>
        </div>
    )
}
export { RatingBar }
