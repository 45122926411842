import { Box, Skeleton } from '@mui/material'

const ChatSkeleton = () => {
    return (
        <Box display="flex" width="100%" marginTop={5}>
            <Box flex={1}>
                <Skeleton variant="text" width="100%" height={70} />
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    marginTop={1}
                >
                    <Skeleton variant="text" width="70%" height={60} />
                    <Skeleton
                        variant="text"
                        width="70%"
                        height={70}
                        className="ml-auto"
                    />
                    <Skeleton variant="text" width="80%" height={60} />
                    <Skeleton
                        variant="text"
                        width="80%"
                        height={60}
                        className="ml-auto"
                    />
                    <Skeleton variant="text" width="80%" height={60} />
                    <Skeleton
                        variant="text"
                        width="80%"
                        height={60}
                        className="ml-auto"
                    />
                    <Skeleton variant="text" width="80%" height={60} />
                    <Skeleton
                        variant="text"
                        width="80%"
                        height={60}
                        className="ml-auto"
                    />
                </Box>
                <Skeleton
                    variant="text"
                    width="100%"
                    height={50}
                    className="mt-auto"
                />
            </Box>
        </Box>
    )
}

export default ChatSkeleton
