import React, {
    useEffect,
    useRef,
    MutableRefObject,
    useState,
    useCallback,
} from 'react'
import {
    LocalParticipant,
    LocalTrack,
    NetworkQualityLevel,
    NetworkQualityStats,
    RemoteParticipant,
    RemoteTrack,
    createLocalVideoTrack,
    createLocalAudioTrack,
} from 'twilio-video'
import { classNames } from '../utils/misc/class-names'
import { useStores } from '../utils/stores'
import { observer } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import { DEVICE_TYPES } from '../stores'
import microphoneOff from '../assets/icons/microphone-off.svg'

type Track = RemoteTrack | LocalTrack

/**
 * NOTE TO ALL
 * This has just been refactored but it needs it again :(
 * We need to split this into 2 componants 1 for RemoteParticipants
 * And one for LocalParticipant. It is doable the way it is here
 * but it is adding debug complexity that does not need to exist.
 *
 */

const networkLevelIndicator = {
    0: '/assets/signal/signal-no-connection.svg',
    1: '/assets/signal/signal-1-bar.svg',
    2: '/assets/signal/signal-2-bars.svg',
    3: '/assets/signal/signal-3-bars.svg',
    4: '/assets/signal/signal-4-bars.svg',
    5: '/assets/signal/signal-4-bars.svg',
}
interface Props {
    participant?: LocalParticipant | RemoteParticipant
    isLocal: Boolean
    participantImage: string
    shownImage?: Boolean
}

const Participant: React.FC<Props> = observer(
    ({ participant, isLocal, participantImage, shownImage = true }) => {
        const { settings } = useStores()
        const videoRef = useRef() as MutableRefObject<any>
        const audioRef = useRef() as MutableRefObject<any>
        const [isSelfHidden, hideSelf] = useState(false)
        const [displayImage, showImage] = useState(shownImage)
        const [networkLevel, setNetworkLevel] = useState(5)
        const [connected, setConnected] = useState(false)
        const [isMuted, setIsMuted] = useState(false)

        /**
         *  This is needed to re attach thew video after isSelfHidden = true
         */
        useEffect(() => {
            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'visible') {
                    showImage(false)
                }
            })

            if (!displayImage && isLocal) {
                processTracks()
            }
        }, [participant, isSelfHidden, displayImage])

        useEffect(() => {
            if (settings.cameraDeviceId && isLocal && participant) {
                createLocalVideoTrack({
                    deviceId: { exact: settings.cameraDeviceId },
                })
                    .then((localVideoTrack) => {
                        const local = participant as LocalParticipant
                        local.videoTracks.forEach((videoTrack) => {
                            local.unpublishTrack(videoTrack.track)
                        })

                        localVideoTrack.attach(videoRef.current)
                        local.publishTrack(localVideoTrack)
                    })
                    .catch((e) => {
                        Sentry.captureException(e)
                        throw e
                    })
            }
        }, [settings.cameraDeviceId])

        useEffect(() => {
            settings.getDeviceId(DEVICE_TYPES.Speaker)
            if (settings.speakerDeviceId && isLocal) {
                settings.room?.participants.forEach((participant) => {
                    participant.audioTracks.forEach((publication) => {
                        if (publication && publication.track) {
                            const audioElement = publication.track.attach()
                            // @ts-ignore
                            audioElement.setSinkId(
                                settings.getDeviceId(DEVICE_TYPES.Speaker),
                            )
                        }
                    })
                })
            }
        }, [settings.speakerDeviceId])

        useEffect(() => {
            if (settings.microphoneDeviceId && isLocal && participant) {
                const local = participant as LocalParticipant
                local.audioTracks.forEach((publication) => {
                    publication.track.stop()
                    local.unpublishTrack(publication.track)
                })
                createLocalAudioTrack({
                    deviceId: { exact: settings.microphoneDeviceId },
                }).then((track) => {
                    track.attach(audioRef.current)
                    local.publishTrack(track)
                    settings.setMuted(false)
                })
            }
        }, [settings.microphoneDeviceId])

        const trackSwitchedOff = useCallback((track: Track) => {
            console.log('trackSwitchedOff')
            if (track.kind === 'video') {
                showImage(true)
            } else if (track.kind === 'audio') {
                setIsMuted(true)
            }
        }, [])

        const trackSwitchedOn = useCallback((track: Track) => {
            console.log('trackSwitchedOn')
            if (track.kind === 'video') {
                showImage(false)
            } else if (track.kind === 'audio') {
                setIsMuted(false)
            }
        }, [])

        const trackUnsubscribed = useCallback((track: Track) => {
            console.log('trackUnsubscribed')
            if (track.kind === 'video') {
                showImage(true)
                track.detach()
            } else if (track.kind === 'audio') {
                track.detach()
            }
        }, [])

        const trackSubscribed = useCallback((track: Track) => {
            if (track.kind === 'video' && videoRef.current) {
                console.log('trackSubscribed video')
                showImage(false)
                shownImage = false
                track.attach(videoRef.current)
            } else if (track.kind === 'audio') {
                const mediaTrack = track.attach(audioRef.current)
                try {
                    const device = settings.getDeviceId(DEVICE_TYPES.Speaker)
                    if (device) {
                        mediaTrack
                            // @ts-ignore
                            .setSinkId(device)
                    }
                } catch {
                    // the try catch is for firefox that does not yet implement setSinkId
                }
            }
        }, [])

        const processTracks = useCallback(() => {
            console.log('processTracks')
            if (participant) {
                participant.tracks.forEach((track) => {
                    if (track.track) {
                        trackSubscribed(track.track)
                    }
                })
            }
        }, [participant])

        const printNetworkQuality = (
            networkQualityLevel: NetworkQualityLevel,
            networkQualityStats: NetworkQualityStats,
        ) => {
            setNetworkLevel(networkQualityLevel)
        }

        const participantDisconnected = useCallback(() => {
            setConnected(false)
        }, [])

        useEffect(() => {
            if (participant && !connected) {
                processTracks()
                setConnected(true)
                participant.on('disconnected', participantDisconnected)
                participant.on('trackSubscribed', trackSubscribed)
                participant.on('trackUnsubscribed', trackUnsubscribed)
                participant.on('trackSwitchedOff', trackSwitchedOff)
                participant.on('trackSwitchedOn', trackSwitchedOn)
                participant.on('trackEnabled', trackSwitchedOn)
                participant.on('trackDisabled', trackSwitchedOff)
                participant.on(
                    'networkQualityLevelChanged',
                    printNetworkQuality,
                )
            }
        }, [participant])

        return (
            <>
                {!isSelfHidden && (
                    <div
                        className={classNames(
                            isLocal
                                ? 'group border-[#FFFFFF] border-[5px] rounded-md'
                                : '',
                        )}
                    >
                        <div
                            className={classNames(
                                'absolute flex w-[40px] z-[2] pt-[1px] pl-[1px]',
                            )}
                        >
                            <img
                                className="inline-flex"
                                src={
                                    networkLevelIndicator[
                                        networkLevel as keyof typeof networkLevelIndicator
                                    ]
                                }
                            />
                            {isMuted && !isLocal && (
                                <img
                                    className={classNames('inline-flex pl-2')}
                                    src={microphoneOff}
                                />
                            )}
                        </div>
                        {displayImage && (
                            <>
                                {!connected && !isLocal && (
                                    <span
                                        className={classNames(
                                            'bg-white p-2 rounded-xl',
                                            'absolute z-[100] left-[47%] top-1/2 text-black',
                                        )}
                                    >
                                        Waiting for coachee to join...
                                    </span>
                                )}

                                <img
                                    width={isLocal ? '210px' : undefined}
                                    height={isLocal ? '120px' : undefined}
                                    className={classNames(
                                        isLocal
                                            ? 'min-w-[150px] w-[210px] h-[120px]'
                                            : 'h-[98vh] max-h-[720px]',
                                        'blur-sm z-[200] ',
                                        'border-1 border-[#554343]',
                                    )}
                                    src={participantImage}
                                />
                            </>
                        )}
                        <video
                            className={classNames(
                                displayImage ? 'absolute z-[-1]' : '',
                                isLocal ? 'min-w-[150px]' : 'h-[84vh]',
                            )}
                            ref={videoRef}
                            autoPlay={true}
                        ></video>
                        <audio
                            ref={audioRef}
                            autoPlay
                            style={{ display: 'none' }}
                        />
                        {isLocal && (
                            <span
                                onClick={() => hideSelf(true)}
                                className={classNames(
                                    'pointer text-center',
                                    'hidden group-hover:block group-hover:absolute',
                                    'bottom-[5px] w-[96%] bg-[#FFFFFF] h-7',
                                )}
                            >
                                Hide self view
                            </span>
                        )}
                    </div>
                )}
                {isSelfHidden && (
                    <span
                        onClick={() => hideSelf(false)}
                        className={classNames(
                            'absolute pointer right-0 bottom-6',
                            'bg-[#ffffff] rounded-full px-[16px] py-[16px] ml-2',
                            'fa fa-video-camera',
                        )}
                    />
                )}
            </>
        )
    },
)

export default Participant
