import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Formik } from 'formik'
import { userConstants } from '../_constants'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'

function ResetPassword(props: any) {
    const location = useLocation()
    const query = new URLSearchParams(location.search)

    const [hasToken, setToken] = useState(true)
    const [hasPasswordBeenReset, setPasswordReset] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!query.has('token')) {
            setToken(false)
        }
    })

    const resetPassword = (values: any, setSubmitting: any) => {
        let data = {
            token: query.get('token'),
            password: values.password,
        }

        const requestOptions = {
            method: 'post',
            url: `${userConstants.BASE_URL}auth/reset-my-password`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }
        axios(requestOptions)
            .then(function (res) {
                setPasswordReset(true)
            })
            .catch(function (error) {
                setSubmitting(false)
                if (error.response.status == 411) {
                    setError(
                        'The password you entered is not sufficiently secure. Please try another one.',
                    )
                } else if (error.response.status == 429) {
                    setError(
                        'You have tried to reset your password too many times please wait 15 minutes and try again.',
                    )
                } else {
                    Sentry.captureException(error)
                    setToken(false)
                }
            })
    }

    return (
        <div className="pink-container bg-pink">
            <div className="row">
                <div className="col-sm-6 col-md-6">
                    <div className="semi-article">
                        <figure>
                            <img
                                src="assets/Logo3@2x.png"
                                width="120"
                                alt="logo"
                                className="logo"
                            />
                        </figure>
                        <h2 className="m-t-60">
                            Welcome to the <br /> Sama team!
                        </h2>
                        <p>
                            We are excited that you are part of our mission
                            <br /> to make the world an engaged and inspired
                            <br /> place.
                        </p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6">
                    <div className="login-form">
                        {!hasToken && (
                            <p>
                                Your link appears to be invalid. Please use the{' '}
                                <a href="/forgot">forgot password</a> page to
                                recieve a valid link
                            </p>
                        )}
                        {hasPasswordBeenReset && (
                            <div>
                                <p>
                                    Your password has been reset please{' '}
                                    <a href="/login"> click here to login</a>
                                </p>
                            </div>
                        )}
                        {hasToken && !hasPasswordBeenReset && (
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    resetPassword(values, setSubmitting)
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string()
                                        .required(
                                            'Your password must contain a minimum of 10 characters and consist of at least 1 uppercase letter, lowercase letter, number and a special character',
                                        )
                                        .matches(
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[—\-!#$%§±¡™£¢∞¶•ªº–“‘æ«…¬˚≤≥µ˜√ç≈Ωåß∂`ƒ©~˙∆πøˆ¨¥†®´^&*()_+{}|"?><:,.\/'\\;\]@\[=])[A-Za-z\d—\-!#$%§±¡™£¢∞¶•ªº–“‘æ«…¬˚≤≥µ˜√ç≈Ωåß∂`ƒ©~˙∆πøˆ¨¥†®´^&*()_+{}|"?><:,.'\\;\]@\[=]{10,}$/,
                                            'Your password must contain a minimum of 10 characters and consist of at least 1 uppercase letter, lowercase letter, number and a special character',
                                        ),
                                    confirmPassword: Yup.string()
                                        .required()
                                        .test(
                                            'passwords-match',
                                            'Passwords must match',
                                            function (value) {
                                                return (
                                                    this.parent.password ===
                                                    value
                                                )
                                            },
                                        ),
                                })}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isValid,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    } = props
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <h3>Change your password</h3>
                                            {error && (
                                                <div className="error">
                                                    {error}
                                                    <br />
                                                    <br />
                                                </div>
                                            )}
                                            <p style={{ fontSize: '10px' }}>
                                                Your password must contain a
                                                minimum of 10 characters and
                                                consist of at least 1 uppercase
                                                letter, lowercase letter, number
                                                and a special character
                                            </p>
                                            <div className="form-group">
                                                <label htmlFor="password">
                                                    New password
                                                </label>
                                                <input
                                                    id="password"
                                                    placeholder="Type your new password"
                                                    type="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control"
                                                />
                                                {errors.password &&
                                                    touched.password && (
                                                        <div className="error">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="confirmPassword">
                                                    Retype password
                                                </label>
                                                <input
                                                    id="confirmPassword"
                                                    placeholder="Retype password"
                                                    type="password"
                                                    value={
                                                        values.confirmPassword
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control"
                                                />
                                                {errors.confirmPassword &&
                                                    touched.confirmPassword && (
                                                        <div className="error">
                                                            {
                                                                errors.confirmPassword
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-group btn-block-bottom">
                                                <button
                                                    disabled={
                                                        !(isValid && dirty)
                                                    }
                                                    className="relative btn btn-primary btn-bolck"
                                                >
                                                    {isSubmitting ? (
                                                        <div>
                                                            <span className="loading-spin"></span>
                                                            <span>
                                                                In progress
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        'Save and continue'
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export { ResetPassword }
