import axios from 'axios'
import { userConstants } from '../_constants'
import * as Sentry from '@sentry/react'
import { useStores } from '../utils/stores'
import { dataURItoBlobString } from '../misc/images'
const id = window.localStorage.getItem('userinfo')
    ? JSON.parse(window.localStorage.getItem('userinfo'))._id.toString()
    : null
let coacheeDetails = null
let coacheeDetailsCachedDate = new Date()
const CACHE_LENGTH = 3 * 60 * 60 * 1000 // 3 hours

const { coach } = useStores()

/**
 * NOTE this function only returns active channels
 *
 */
const getCoacheeDetails = () => {
    const cacheTime = new Date() - coacheeDetailsCachedDate

    if (!coacheeDetails || cacheTime > CACHE_LENGTH) {
        coacheeDetails = new Promise((resolve, reject) => {
            axios
                .get(`${userConstants.BASE_URL}coach/${id}/channel`)
                .then(async (channelList) => {
                    let activeChannels = []
                    if (channelList.data) {
                        for (let i = 0; i < channelList.data.length; i++) {
                            if (channelList.data[i].currentlyOpen) {
                                const coacheeId =
                                    channelList.data[i].coachee._id
                                coach
                                    .getCoacheeProfilePicture(coacheeId)
                                    .subscribe({
                                        next(response) {
                                            if (response) {
                                                channelList.data[i].image =
                                                    dataURItoBlobString(
                                                        response,
                                                    )
                                            }
                                            activeChannels.push(
                                                channelList.data[i],
                                            )
                                        },
                                    })
                            }
                        }
                        coacheeDetailsCachedDate = new Date()
                        resolve(activeChannels)
                    }
                })
        })
    }
    return coacheeDetails
}

const getTrackerData = (coacheeId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${userConstants.BASE_URL}coachee/${coacheeId}/experience/trackers?listDates=true`,
            )
            .then((data) => {
                resolve(data.data)
            })
    })
}

export const CoacheeService = {
    getCoacheeDetails,
    getTrackerData,
}
