const setArrayString = (v) => {
    if(!v) return '';
    return v.join(', ');
}; 

function arrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 70
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(String(i))
    }
    return years
  }

  function arrayOfNumbers(max) {
    var min = 1
    var numbers = []
  
    for (var i = max; i >= min; i--) {
        numbers.unshift(String(i))
    }
    return numbers
  }

  function getMonthName(monthNumber) {
    return arrayOfMonthNames()[monthNumber];
  } 

  function getMonthNumber(month) {
    const monthNumbers = arrayOfMonthNames();
    for(let i=0; i < monthNumbers.length; i++){
        if (monthNumbers[i] == month){
            return i;
        }
    }
  }
  
  function arrayOfMonthNames() {
    return ['January', 
    'Febuary', 
    'March', 
    'April', 
    'May', 
    'June', 
    'July', 
    'August', 
    'September', 
    'October', 
    'November', 
    'December']
  }

export const utilsService = {
    setArrayString,
    arrayOfYears,
    arrayOfNumbers,
    arrayOfMonthNames,
    getMonthName,
    getMonthNumber
};