import { forEach, isFunction, values } from 'lodash'
import { CoachStore } from '../stores'
import { SettingsStore } from '../stores'

type Stores = {
    coach: CoachStore
    settings: SettingsStore

    // Store functions
    reset: () => void
}

export const stores: Stores = {
    coach: new CoachStore(),
    settings: new SettingsStore(),

    reset(): void {
        forEach(values(this), (value: any) => {
            const reset = value.reset

            if (reset && isFunction(reset)) {
                reset()
            }
        })
    },
}

export const useStores = () => {
    return stores
}
