import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ChanelItem } from '../Chat/ChanelItem'
import '../Chat/chat.css'
import NotesTaskGoals from '../Sessions/notesTasksGoals.tabs'
import { CoacheeService } from '../_services/coachee.service'
import { dataURItoBlobString } from '../misc/images'

function Coachee(props: any) {
    const [activeCoachees, setActiveCoachees] = useState<any[]>([])
    const [pausedCoachees, setPausedCoachees] = useState<any[]>([])
    const [currentCoachee, setCurrentCoachee] = useState<any>({})

    useEffect(() => {
        getCoachees()
    }, [])

    function getCoachees() {
        CoacheeService.getCoacheeDetails().then((coachees: any) => {
            const activeCoacheeDetails = []
            const pausedCoacheeDetails = []

            for (let i = 0; i < coachees.length; i++) {
                coachees[i].coachee.image = dataURItoBlobString(
                    coachees[i].image,
                )
                coachees[i].coachee.fromCoachee = {}
                coachees[i].coachee.fromCoachee._id = coachees[i].coachee._id

                if (coachees[i].coachee.status === 'paused') {
                    pausedCoacheeDetails.push(coachees[i].coachee)
                } else {
                    activeCoacheeDetails.push(coachees[i].coachee)
                }
            }

            sortActiveCoachees(activeCoacheeDetails)
            setActiveCoachees(activeCoacheeDetails)
            setPausedCoachees(pausedCoacheeDetails)
            setCurrentCoachee(activeCoacheeDetails[0])
        })
    }

    function sortActiveCoachees(data: any) {
        data.sort((a: any, b: any) => {
            return a.user.lastName > b.user.lastName ? 1 : -1
        })
    }

    function onItemSelect(coachee: any) {
        setCurrentCoachee(coachee)
    }

    const activeListItems = activeCoachees.map((item: any, i: number) => {
        item.channelItem = {}
        item.channelItem._id = currentCoachee._id
        return (
            <ChanelItem
                key={i}
                {...item}
                coachee={item}
                onSelect={onItemSelect}
            />
        )
    })

    const pausedListItems = pausedCoachees.map((item, i) => {
        item.channelItem = {}
        item.channelItem._id = currentCoachee._id
        return (
            <ChanelItem
                key={i}
                {...item}
                coachee={item}
                onSelect={onItemSelect}
                paused
            />
        )
    })

    return (
        <section className="row">
            {(Object.keys(activeListItems).length !== 0 ||
                Object.keys(pausedListItems).length !== 0) && (
                <div className="col-md-5">
                    <div className="chanels-container">
                        <div className="chanels-grid">
                            <h6>My coachees</h6>
                            <div>
                                <ul className="list-group">
                                    {activeListItems}
                                </ul>
                            </div>
                        </div>

                        <div className="chanels-grid">
                            {Object.keys(pausedListItems).length !== 0 && (
                                <div>
                                    <h6>Paused coachee(s)</h6>
                                    <div className="opacity-70">
                                        <ul className="list-group">
                                            {pausedListItems}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {(Object.keys(activeListItems).length !== 0 ||
                Object.keys(pausedListItems).length !== 0) && (
                <div className="col-md-7">
                    {<NotesTaskGoals {...currentCoachee} />}
                </div>
            )}

            {Object.keys(activeListItems).length === 0 &&
                Object.keys(pausedListItems).length === 0 && (
                    <div className="col-md-12">
                        <div className="chanels-container">
                            <h6>My coachees</h6>
                            <div
                                style={{
                                    position: 'relative',
                                    minHeight: '60vh',
                                    width: '100%',
                                    height: '80%',
                                    paddingTop: '10%',
                                }}
                            >
                                <div
                                    style={{
                                        margin: 'auto',
                                        top: '50%',
                                        width: '30%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <p style={{ color: '#CFCFCF' }}>
                                        You have not yet matched with a Sama
                                        Coachee
                                    </p>
                                    <p>
                                        Coachees will appear here when you are
                                        matched
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </section>
    )
}

function mapState(state: any) {
    const { userreducer } = state
    return { userreducer }
}

const connectedSessions = connect(mapState)(Coachee)
export { connectedSessions as Coachee }
