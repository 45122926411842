import { useState, useEffect } from 'react'

interface Props {
    options: any
    value: any
    initialValue: any
    onChange: any
}

const SamaMultiSelectInline = ({
    options,
    value,
    initialValue,
    onChange,
}: Props) => {
    const [currentItem, setCurrentItem] = useState()

    useEffect(
        function effectFunction() {
            setCurrentItem(value)
        },
        [value],
    )

    function handleClick(data: any) {
        setCurrentItem(data)
        onChange(data)
    }

    return (
        <div className="tddype-search-box">
            {options.map((item: any, i: number) => {
                return (
                    <div
                        key={i}
                        style={{
                            display: 'inline-block',
                            width: '102px',
                            height: '39px',
                            margin: '0px 10px 0 0',
                            padding: '13px 0 4px',
                            borderRadius: '74px',
                            boxShadow: '0 4px 6px 0 rgba(16, 18, 58, 0.05)',
                            backgroundColor:
                                currentItem === item ||
                                (!currentItem && initialValue === item)
                                    ? '#10123a'
                                    : '#fff',
                            color:
                                currentItem === item ||
                                (!currentItem && initialValue === item)
                                    ? '#fff'
                                    : '#ff7558',
                            textAlign: 'center',
                            cursor: 'pointer',
                            lineHeight: '12px',
                        }}
                        onClick={() => handleClick(item)}
                    >
                        {item}
                    </div>
                )
            })}
        </div>
    )
}

export default SamaMultiSelectInline
