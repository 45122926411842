import { Resettable } from '../utils/misc'
import { action, makeAutoObservable } from 'mobx'
import {
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
} from '../utils/misc/stoage'
import { addHours } from 'date-fns'
import { IsISO8601 } from 'class-validator'
import { Option } from '../utils/misc'

import Video from 'twilio-video'

const COACH_SETTINGS_DEVICE_ID = 'SAMA:COACH-SETTINGS-DEVICE-ID/'
const HOURS_TO_CACHE_DEFAULT = 1

class CacheInformation {
    public data!: string | null

    @IsISO8601()
    public expires!: string
}

export const DEVICE_TYPES = {
    Camera: 'CAMERA',
    Speaker: 'SPEAKER',
    Microphone: 'MICROPHONE',
}

export class SettingsStore implements Resettable {
    public cameraDeviceId: string | null = null
    public microphoneDeviceId: string | null = null
    public speakerDeviceId: string | null = null

    public microphoneMuted = false

    public room?: Video.Room

    public streams: {
        CAMERA: MediaStream | null
        SPEAKER: MediaStream | null
        MICROPHONE: MediaStream | null
    } = {
        CAMERA: null,
        SPEAKER: null,
        MICROPHONE: null,
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public setMuted(mutted: boolean) {
        if (this.streams['MICROPHONE']) {
            this.streams['MICROPHONE'].getAudioTracks().forEach((track) => {
                track.enabled = !mutted
            })
        }

        if (this.room?.localParticipant) {
            this.room?.localParticipant.audioTracks.forEach((track) => {
                !mutted ? track.track.enable() : track.track.disable()
            })
        }

        this.microphoneMuted = mutted
    }

    @action
    public stopStream(type: string) {
        let previousStream = this.streams[type as keyof typeof this.streams]

        if (previousStream) {
            previousStream.getTracks().forEach((track) => {
                track.stop()
                previousStream?.removeTrack(track)
            })
        }
        previousStream = null
    }

    @action
    public setStream(type: string, stream: MediaStream) {
        this.stopStream(type)
        this.streams[type as keyof typeof this.streams] = stream
    }

    @action
    public setDeviceId(type: string, id: string) {
        if (type === DEVICE_TYPES.Camera) {
            this.cameraDeviceId = id
        } else if (type === DEVICE_TYPES.Microphone) {
            this.microphoneDeviceId = id
        } else if (type === DEVICE_TYPES.Speaker) {
            this.speakerDeviceId = id
        }

        dehydrateToStorage(COACH_SETTINGS_DEVICE_ID + type, {
            data: id,
            expires: addHours(new Date(), HOURS_TO_CACHE_DEFAULT),
        })
    }

    @action
    public getDeviceId(type: string) {
        const deviceId = hydrateFromStorage<CacheInformation>(
            COACH_SETTINGS_DEVICE_ID + type,
        )

        if (deviceId) {
            if (type === DEVICE_TYPES.Camera) {
                this.cameraDeviceId = deviceId.data
            } else if (type === DEVICE_TYPES.Microphone) {
                this.microphoneDeviceId = deviceId.data
            } else if (type === DEVICE_TYPES.Speaker) {
                this.speakerDeviceId = deviceId.data
            }
            return deviceId.data
        }
        return null
    }

    @action
    public refreshSavedDeviceId(type: string, listOfDevices: Option[]) {
        let id: string | null = null
        if (type === DEVICE_TYPES.Camera) {
            id = this.cameraDeviceId
        } else if (type === DEVICE_TYPES.Microphone) {
            id = this.microphoneDeviceId
        } else if (type === DEVICE_TYPES.Speaker) {
            id = this.speakerDeviceId
        }

        if (id) {
            const deviceInList = listOfDevices.filter(
                (device) => device.value === id,
            )
            if (deviceInList.length === 0) {
                this.removeDeviveType(type)
                return true
            }
        }
        return false
    }

    @action
    public removeDeviveType(type: string) {
        removeFromStorage(COACH_SETTINGS_DEVICE_ID + type)
    }

    public reset(): void {}
}
