export const userConstants = {
    ENV: "production",
    BASE_URL: 'https://api.sama.io/api/',

    ONE_SIGNAL_APP_ID: "8be20483-18b6-4d59-a5ef-88320f4d03a5",
    MIX_PANEL_TOKEN: "f219edc3994925e79490dbb6908b2979",

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
    
    GET_ME_REQUEST: 'GET_ME_REQUEST',
    GET_ME_SUCCESS: 'GET_ME_SUCCESS',
    GET_ME_FAILURE: 'GET_ME_FAILURE',
    GET_ME_LOGOUT: 'GET_ME_LOGOUT',
    
    GET_STATICS: 'GET_STATICS',
    GET_PICTURE: "GET_PICTURE",

    GET_COACHEE_PROFILE: "GET_COACHEE_PROFILE",
  
    DATE_FORMAT: 'dddd, Do MMM YYYY'
};
