import React, { useState, useEffect, useMemo } from 'react'
import Video, { RemoteParticipant } from 'twilio-video'
import Participant from './Participant'
import { TrackingService } from '../_services/tracking.service.js'
import './room.css'
import { Booking } from '../Sessions/types/Booking'
import classNames from 'classnames'
import microphoneOn from '../assets/icons/microphone-on.svg'
import microphoneOff from '../assets/icons/microphone-off.svg'
import closeIcon from '../assets/icons/close.svg'
import cameraOn from '../assets/icons/camera-on.svg'
import cameraOff from '../assets/icons/camera-off.svg'
import { RoomSettings } from './room-settings'
import { observer } from 'mobx-react'
import { useStores } from '../utils/stores'
import { DEVICE_TYPES } from '../stores'
import settingsIcon from '../assets/icons/settings.svg'
import * as Sentry from '@sentry/browser'

interface Props {
    roomName: any
    booking: Booking
    token: string
    handleLogout: any
    isVideoEnabled: boolean
}

interface SamaRemoteParticpant {
    id: string
    participant?: RemoteParticipant
    image?: string
}
const Room: React.FC<Props> = observer(
    ({ roomName, booking, token, handleLogout, isVideoEnabled }) => {
        const { settings } = useStores()
        const [room, setRoom] = useState<Video.Room>()
        const [micOn, setMicOn] = useState(true)
        const [isCameraOn, setCameraOn] = useState(booking.isVideoSession)
        const [areSettingOpen, setSettingsOpen] = useState(false)
        const [errorMessage, setErrorMessage] = useState<string | null>(null)

        const [roomRemotePaticipents, setRoomRemotePaticipents] = useState<
            SamaRemoteParticpant[]
        >([
            {
                id: booking.fromCoachee._id,
                image: booking.fromCoachee.image,
            },
        ])

        const participantConnected = (participant: RemoteParticipant) => {
            const newPart = [...roomRemotePaticipents]
            newPart[0].participant = participant
            setRoomRemotePaticipents(newPart)
        }

        const participantDisconnected = (participant: RemoteParticipant) => {
            roomRemotePaticipents[0].participant = undefined
            setRoomRemotePaticipents(roomRemotePaticipents)
        }

        useEffect(() => {
            configureTwilioLogging(booking)

            let videoSettings: any = { height: 720, frameRate: 20, width: 1280 }
            if (settings.getDeviceId(DEVICE_TYPES.Camera)) {
                videoSettings.deviceId = settings.getDeviceId(
                    DEVICE_TYPES.Camera,
                )
            }

            Video.connect(token, {
                name: roomName,
                audio: true,
                video: videoSettings,
                maxAudioBitrate: 16000,
                bandwidthProfile: {
                    video: {
                        mode: 'grid',
                    },
                },
                networkQuality: {
                    local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
                    remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
                },
            })
                .then((room) => {
                    settings.room = room
                    setRoom(room)
                    room.on('participantConnected', participantConnected)
                    room.on('participantDisconnected', participantDisconnected)
                    room.on('disconnected', () => {
                        // room could not connect again so show message
                        handleDisconect()
                    })
                    room.participants.forEach(participantConnected)
                    if (!booking.isVideoSession) {
                        setCameraOn(false)
                        room.localParticipant.videoTracks.forEach(
                            (videoTrack) => {
                                videoTrack.track.disable()
                            },
                        )
                    }
                })
                .catch((e) => {
                    if (e.message === 'Could not start video source') {
                        setErrorMessage(
                            'Sorry we could not connect to a video source so where unable to connect you to the session. ' +
                                'Please make sure you have a Camera connected and it is not being used by another program. ' +
                                'Then log back into the session.',
                        )
                    } else {
                        setErrorMessage(
                            'There was a problem connecting your webcam or microphone.' +
                                'Please check they are enabled an not being used by another service. ' +
                                'If you see this message please report to support@sama.io after your session. Thank you.',
                        )
                    }
                    Sentry.captureMessage('VideoSettings', videoSettings)
                    Sentry.captureException(e)
                })
        }, [roomName, token])

        function configureTwilioLogging(booking: Booking) {
            const { Logger } = Video
            const logger = Logger.getLogger('twilio-video')

            const originalFactory = logger.methodFactory
            logger.methodFactory = function (methodName, level, loggerName) {
                const method = originalFactory(methodName, level, loggerName)

                return function (datetime, logLevel, component, message, data) {
                    method(datetime, logLevel, component, message, data)
                    try {
                        const logMessage = {
                            datetime,
                            logLevel,
                            component,
                            message,
                            data,
                            booking: {},
                            session: {},
                        }

                        if (booking) {
                            logMessage.booking = {
                                _id: booking._id,
                                duration: booking.duration,
                            }
                        }

                        logMessage.session = {
                            roomName: roomName,
                        }

                        TrackingService.sendLogEvent(logMessage)
                    } catch (error) {
                        console.log(error)
                        // Sentry.captureException(error)
                    }
                }
            }
            logger.setLevel('info')
        }

        const remoteParticipants = useMemo(() => {
            return roomRemotePaticipents.map((remote: any, index: number) => {
                return (
                    <Participant
                        key={index}
                        isLocal={false}
                        participant={remote.participant}
                        participantImage={booking.fromCoachee.image}
                    />
                )
            })
        }, [roomRemotePaticipents, booking])

        useEffect(() => {
            setMicOn(!settings.microphoneMuted)
        }, [settings.microphoneMuted])

        const toggleVoice = () => {
            if (room) {
                settings.setMuted(micOn)
            }
        }

        const toggleVideo = () => {
            if (room) {
                if (isCameraOn) {
                    room.localParticipant.videoTracks.forEach((videoTrack) => {
                        videoTrack.track.disable()
                    })
                } else {
                    room.localParticipant.videoTracks.forEach((videoTrack) => {
                        videoTrack.track.enable()
                    })
                }
                setCameraOn(!isCameraOn)
            }
        }

        const handleDisconect = () => {
            if (room) {
                room.disconnect()
            }
            handleLogout()
        }

        return (
            <>
                <div>
                    {errorMessage && (
                        <span className="flex text-red-600 w-[30%] m-auto text-xl">
                            <p>{errorMessage}</p>
                        </span>
                    )}
                    {!errorMessage && (
                        <>
                            <div>{remoteParticipants}</div>
                            <div
                                className={classNames(
                                    'backdrop-blur-md bg-[rgba(0,0,0,.1)]',
                                    'absolute bottom-0 left-0 w-full inline-flex justify-center items-center',
                                )}
                            >
                                <div
                                    className={classNames(
                                        'w-[200px] pb-2 h-[90px] pt-2 ml-[46%]',
                                    )}
                                >
                                    <button
                                        className={classNames(
                                            'w-[52px] h-[52px]  ',
                                        )}
                                        style={{
                                            backgroundImage: `url(${
                                                micOn
                                                    ? microphoneOn
                                                    : microphoneOff
                                            })`,
                                        }}
                                        onClick={toggleVoice}
                                    />
                                    <button
                                        className={classNames(
                                            'w-[72px] h-[72px] mx-2',
                                        )}
                                        style={{
                                            backgroundImage: `url(${closeIcon})`,
                                        }}
                                        onClick={handleDisconect}
                                    />
                                    <button
                                        className={classNames(
                                            'w-[52px] h-[52px]',
                                        )}
                                        style={{
                                            backgroundImage: `url(${
                                                isCameraOn
                                                    ? cameraOn
                                                    : cameraOff
                                            })`,
                                        }}
                                        onClick={toggleVideo}
                                    />
                                </div>
                                <img
                                    className="pointer w-12 ml-auto mr-10"
                                    title="Settings"
                                    onClick={() => setSettingsOpen(true)}
                                    src={settingsIcon}
                                />

                                <RoomSettings
                                    open={areSettingOpen}
                                    setOpen={setSettingsOpen}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={classNames(
                        'absolute bottom-[80px] right-10',
                        'w-[16vw] max-w-[220px] inline-flex',
                    )}
                >
                    <Participant
                        isLocal={true}
                        shownImage={!booking.isVideoSession}
                        participant={room ? room.localParticipant : undefined}
                        participantImage={booking.fromCoachee.image}
                    />
                </div>
            </>
        )
    },
)

export default Room
