import * as Sentry from '@sentry/react'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { alertActions } from '../../../_actions/alert.actions'
import { userConstants } from '../../../_constants'
import { AppleCalendarManager } from './apple-calendar-manager'
import { GenericCalendarManager } from './generic-calendar-manager'

const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
})

const defaultCalendarInfo = {
    provider: 'none',
    calendar: null,
    calendars: [],
}

export const CalendarManager = connect(mapStateToProps)(
    ({ coach, dispatch }: { coach: any; dispatch: any }) => {
        const navigate = useNavigate()
        const [searchParams, setSearchParams] = useSearchParams()
        const [calendarInfo, setCalendarInfo] = useState(defaultCalendarInfo)
        const [ready, setReady] = useState(false)

        const isNone = calendarInfo.provider === 'none'
        const isGoogle = calendarInfo.provider === 'google'
        const isOutlook = calendarInfo.provider === 'outlook'
        const isApple = calendarInfo.provider === 'apple'

        const [isEnteringCredentials, setIsEnteringCredentials] =
            useState(false)

        const updateUser = async () => {
            axios.get(`${userConstants.BASE_URL}me/coach`).then(function (res) {
                localStorage.setItem('userinfo', JSON.stringify(res.data))

                dispatch({
                    type: userConstants.GET_ME_SUCCESS,
                    payload: res.data,
                })

                navigate(0)
            })
        }

        const selectCalendar = async (calendar: any) => {
            try {
                await axios.put(`${userConstants.BASE_URL}me/coach/`, {
                    user: { calendar },
                })

                updateUser()
            } catch (error: any) {
                Sentry.captureException(error)
                return dispatch(alertActions.error(error.response.data))
            }
        }

        const disconnectCalendar = async () => {
            try {
                axios.delete(
                    `${userConstants.BASE_URL}coach/${coach._id}/external-calendars`,
                )
                updateUser()
            } catch (error: any) {
                Sentry.captureException(error)
                return dispatch(alertActions.error(error.response.data))
            }
        }

        const getUserCalendarInformation = useCallback(async () => {
            try {
                const res = await axios.get(
                    `${userConstants.BASE_URL}coach/${coach._id}/external-calendars`,
                )
                setCalendarInfo(res.data)
                setReady(true)
            } catch (error) {
                if (error) {
                    Sentry.captureException(error)
                    return
                }
            }
        }, [coach, setCalendarInfo, setReady])

        const googleSignIn = (e: any) => {
            e.preventDefault()
            navigate('/calendar-sync')
        }

        const azureSignIn = async (e: any) => {
            e.preventDefault()

            try {
                const res = await axios.get(
                    `${userConstants.BASE_URL}auth/login/azure`,
                )
                window.location = res.data.redirectUrl
            } catch (error) {
                Sentry.captureException(error)
                console.log(error)
                return
            }
        }

        useEffect(() => {
            if (coach) {
                getUserCalendarInformation()
            }
        }, [coach])

        return ready ? (
            <div className="grid grid-cols-1">
                {searchParams.get('error') && (
                    <p style={{ color: 'red' }}>{searchParams.get('error')}</p>
                )}
                {!isEnteringCredentials && (isNone || isGoogle) && (
                    <GenericCalendarManager
                        iconPath="/assets/images/logos/google/Image.png"
                        name="Google Calendar"
                        provider="google"
                        connectFunction={googleSignIn}
                        calendarInfo={calendarInfo}
                        selectCalendar={selectCalendar}
                        disconnectCalendar={disconnectCalendar}
                    />
                )}

                {!isEnteringCredentials && (isNone || isOutlook) && (
                    <GenericCalendarManager
                        iconPath="/assets/images/logos/outlook/Vector.png"
                        name="Outlook Calendar"
                        provider="outlook"
                        connectFunction={azureSignIn}
                        calendarInfo={calendarInfo}
                        selectCalendar={selectCalendar}
                        disconnectCalendar={disconnectCalendar}
                    />
                )}

                {(isNone || isApple) && (
                    <AppleCalendarManager
                        calendarInfo={calendarInfo}
                        selectCalendar={selectCalendar}
                        disconnectCalendar={disconnectCalendar}
                        isEnteringCredentials={isEnteringCredentials}
                        setIsEnteringCredentials={setIsEnteringCredentials}
                    />
                )}
            </div>
        ) : (
            <div className="p-4 d-flex justify-content-center">
                <span>Loading...</span>
            </div>
        )
    },
)
