import moment from 'moment'

import { differenceInDays, format } from 'date-fns'

export const HALF_HOURS_AM = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
]
export const HALF_HOURS_PM = [
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
]

export const formatDateWeekDay = (date: Date) => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
}

export const formatDayDate = (date: number | Date) => {
    return format(date, 'E, dd MMM yyyy')
}

export const formatDate = (
    date: number | Date,
    formatString = 'MMMM d, yyyy',
) => {
    return format(date, formatString)
}

export const isTimeRange = (time: string, date: number | Date) => {
    var d = moment(date).format('YYYY-MM-DD')
    var momentObj = moment(d + '' + time, 'YYYY-MM-DDLTHH:mm:ss')
    var momentObj1 = moment(new Date())
    var start = moment(momentObj.format('YYYY-MM-DDTHH:mm:ss')).toISOString()
    var end = moment(momentObj1.format('YYYY-MM-DDTHH:mm:ss')).toISOString()
    return start > end
}
export const datesDifferent = (
    firstDate: number | Date,
    secondData: number | Date | null,
    type: string,
) => {
    if (!secondData) {
        return true
    } else if (type === 'days') {
        return differenceInDays(firstDate, secondData) != 0
    } else {
        throw Error('Unkown difference type')
    }
}
