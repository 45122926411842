import React from 'react'
import Autocompletemulti from '../_components/Autocompletemulti'
function CoachingExp(props: any) {
    const { values, handleChange, setFieldValue } = props
    const { errors } = props
    const { touched } = props
    return (
        <>
            {' '}
            <h5 className="text-center">Coaching experience</h5>
            <div className="form-group">
                <label htmlFor="bio">Bio (Will be visible to coachees)</label>
                <textarea
                    id="bio"
                    placeholder="Say something about yourself..."
                    value={values.bio}
                    onChange={handleChange}
                    className="light-textarea form-control"
                    rows={4}
                    maxLength={650}
                ></textarea>
                <span className="carslenth">
                    {values.bio?.length ? values.bio?.length : '0'}/500
                </span>
                {errors.bio && touched.bio && (
                    <div className="error">{errors.bio}</div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="yearprice">Year started coaching</label>
                <input
                    id="startCoachingYear"
                    placeholder="2000"
                    type="number"
                    value={values.startCoachingYear}
                    onChange={handleChange}
                    className="form-control half-number"
                />
                {errors.startCoachingYear && touched.startCoachingYear && (
                    <div className="error">{errors.startCoachingYear}</div>
                )}
            </div>
            <div className="form-group">
                <label>Areas of coaching competencies</label>
                <Autocompletemulti
                    options={props?.statics.competencies}
                    selected={values.areasCompetencies}
                    onSelectCallback={(v: any) => {
                        setFieldValue('areasCompetencies', v, false)
                    }}
                    placeholder="Choose multiple"
                />
                {errors.areasCompetencies && touched.areasCompetencies && (
                    <div className="error">{errors.areasCompetencies}</div>
                )}
            </div>
            <div className="form-group">
                <label>Coaching Accreditations/Certifications</label>
                <Autocompletemulti
                    options={props?.statics.accreditations}
                    selected={values.coachingAccreditations}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingAccreditations', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingAccreditations &&
                    touched.coachingAccreditations && (
                        <div className="error">
                            {errors.coachingAccreditations}
                        </div>
                    )}
            </div>
            <div className="form-group">
                <label>Memberships</label>
                <Autocompletemulti
                    options={props?.statics.memberships}
                    selected={values.coachingMemberships}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingMemberships', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingMemberships && touched.coachingMemberships && (
                    <div className="error">{errors.coachingMemberships}</div>
                )}
            </div>
            <div className="form-group">
                <label>Industry experience (coaching in)</label>
                <Autocompletemulti
                    options={props?.statics.industries}
                    selected={values.coachingIndustries}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingIndustries', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingIndustries && touched.coachingIndustries && (
                    <div className="error">{errors.coachingIndustries}</div>
                )}
            </div>
            <div className="form-group">
                <label>Functional experience (coaching in)</label>
                <Autocompletemulti
                    options={props?.statics.functions}
                    selected={values.coachingFunctions}
                    onSelectCallback={(v: any) => {
                        setFieldValue('coachingFunctions', v, false)
                    }}
                    placeholder="Type or select"
                />
                {errors.coachingFunctions && touched.coachingFunctions && (
                    <div className="error">{errors.coachingFunctions}</div>
                )}
            </div>
            <div className="clearfix">
                <label>Experience working with</label>
                <small className="pull-right">Number of years</small>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            C-Suite / Exectutives
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[0].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[0]?.years
                                ? values.experienceYearIn[0]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            Senior professionals
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[1].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[1]?.years
                                ? values.experienceYearIn[1]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">Managers</span>
                    </div>
                    <input
                        id="experienceYearIn[2].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[2]?.years
                                ? values.experienceYearIn[2]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">Associates</span>
                    </div>
                    <input
                        id="experienceYearIn[3].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[3]?.years
                                ? values.experienceYearIn[3]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group left-addon mb-5">
                <div className="input-group">
                    <div className="input-group-append">
                        <span className="input-group-text">
                            Operations and Administration
                        </span>
                    </div>
                    <input
                        id="experienceYearIn[4].years"
                        placeholder="0"
                        type="number"
                        value={
                            values.experienceYearIn[4]?.years
                                ? values.experienceYearIn[4]?.years
                                : ''
                        }
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
        </>
    )
}
export { CoachingExp }
