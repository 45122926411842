import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as Sentry from '@sentry/react'
import './Message.css'
import axios from 'axios'
import Linkify from 'react-linkify'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import md5 from 'md5'
import Popup from 'reactjs-popup'

export class Message extends Component<any, any> {
    state = {
        play: null,
        player: 'stopped',
        slider: { value: 0 },
        openMessageRemove: false,
        hide: false,
    }

    player: any
    static propTypes = {
        author: PropTypes.bool,
        body: PropTypes.string,
        me: PropTypes.bool,
        isUrgent: PropTypes.bool,
    }

    getTime(time: any) {
        if (!isNaN(time)) {
            return (
                Math.floor(time / 60) +
                ':' +
                ('0' + Math.floor(time % 60)).slice(-2)
            )
        }
    }

    componentWillUnmount() {
        this.player.removeEventListener('timeupdate', () => {})
    }

    componentDidMount() {
        if (this.props.media?.duration) {
            this.setState({ currentTime: this.props.media.duration })
        }

        this.player.addEventListener('timeupdate', (e: any) => {
            this.setState({
                currentTime: e.target.duration - e.target.currentTime,
                duration: e.target.duration,
                slider: { value: Math.round(e.target.currentTime) },
            })
        })
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        // @ts-ignore
        if (this.state.player != 'stopped' && this.state.currentTime == 0) {
            this.setState({ player: 'stopped' })
        } else if (this.state.player !== prevState.player) {
            if (this.state.player === 'paused') {
                this.player.pause()
            } else if (this.state.player === 'stopped') {
                this.player.pause()
                this.player.currentTime = 0
            } else if (this.state.player === 'playing') {
                if (this.player.src != this.props.media.url) {
                    this._getMp3(this.props.media.url).then((blobUrl) => {
                        this.player.src = blobUrl
                        this.player.play()
                    })
                } else {
                    this.player.play()
                }
            }
        }
    }

    _getMp3(url: string) {
        return axios
            .get(url, { responseType: 'arraybuffer' })
            .then(function (res) {
                var blob = new Blob([res.data], { type: 'audio/mp3' })
                var objectURL = URL.createObjectURL(blob)
                return objectURL
            })
            .catch(function (error) {
                Sentry.captureException(error)
                console.log('Error: ', error)
            })
    }

    render() {
        const fileNameLength = this.props.videoView ? 20 : 200

        const classes = classNames('Message', {
            log: !this.props.author,
            me: this.props.me,
        })
        const classList = classNames('messagelistitem', {
            coachee: !this.props.author,
            coach: this.props.author,
            me: this.props.me,
            audio: this.props?.media?.isMp3,
            media: this.props?.media && !this.props?.media?.isMp3,
            isUrgent: this.props.isUrgent,
        })

        const importantClassList = classNames('important', {
            coachee: !this.props.author,
            coach: this.props.author,
        })

        const onSelectMessage = (item: any) => {
            if (item.media && !item.media.isMp3) {
                window.open(item.media.url, '_blank')
            }
        }
        // @ts-ignore
        const currentTime = this.getTime(this.state.currentTime)

        return (
            <>
                {!this.state.hide && (
                    <div className={classes}>
                        {!this.props.author && this.props.isfirst && (
                            <img
                                className="small-thumbnail"
                                src={this.props?.image}
                                alt="coachee thumbnail"
                            />
                        )}

                        {this.props.body && (
                            <div
                                className={classList}
                                onClick={() => {
                                    onSelectMessage(this.props)
                                }}
                            >
                                {this.props.author && this.props.canDelete && (
                                    <>
                                        <span
                                            className="bin-icon w-[5px] inline-flex pr-[10px] pointer"
                                            onClick={() => {
                                                this.setState({
                                                    openMessageRemove: true,
                                                })
                                            }}
                                        ></span>
                                        <Popup
                                            open={this.state.openMessageRemove}
                                            onClose={() =>
                                                this.setState({
                                                    openMessageRemove: false,
                                                })
                                            }
                                            className="alertBox"
                                            modal={true}
                                            nested
                                        >
                                            <div>
                                                <div>
                                                    Are you sure you wish to
                                                    delete this message? It can
                                                    NOT be undone
                                                </div>
                                                <button
                                                    className="relative btn btn-light btn-bolck"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            hide: true,
                                                        })
                                                        this.setState({
                                                            openMessageRemove:
                                                                false,
                                                        })
                                                        this.props.message.remove()
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className="relative btn btn-light btn-bolck"
                                                    type="button"
                                                    onClick={() =>
                                                        this.setState({
                                                            openMessageRemove:
                                                                false,
                                                        })
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Popup>
                                    </>
                                )}
                                <span style={{ wordWrap: 'break-word' }}>
                                    {this.props.body
                                        .split('\n')
                                        .map((str: string) => (
                                            <p key={md5(str)}>
                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                        key,
                                                    ) => (
                                                        <a
                                                            target="blank"
                                                            href={decoratedHref}
                                                            key={key}
                                                        >
                                                            {' '}
                                                            {decoratedText}{' '}
                                                        </a>
                                                    )}
                                                >
                                                    {str}
                                                </Linkify>
                                                &nbsp;
                                            </p>
                                        ))}{' '}
                                </span>
                                {this.props.isUrgent && (
                                    <span className={importantClassList}>
                                        !
                                    </span>
                                )}
                            </div>
                        )}

                        {this.props?.media?.isMp3 && (
                            <div className={classList}>
                                {this.props.isUrgent && (
                                    <span className={importantClassList}>
                                        !
                                    </span>
                                )}
                                {this.state.player === 'paused' && (
                                    <img
                                        src="/assets/audio/play.png"
                                        onClick={() =>
                                            this.setState({ player: 'playing' })
                                        }
                                    />
                                )}
                                {this.state.player === 'stopped' && (
                                    <img
                                        src="/assets/audio/play.png"
                                        onClick={() =>
                                            this.setState({ player: 'playing' })
                                        }
                                    />
                                )}
                                {this.state.player === 'playing' && (
                                    <img
                                        src="/assets/audio/pause.png"
                                        onClick={() =>
                                            this.setState({ player: 'paused' })
                                        }
                                    />
                                )}

                                <div className="slider">
                                    <Slider
                                        onChange={(newValue) =>
                                            (this.player.currentTime = newValue)
                                        }
                                        min={0}
                                        step={1}
                                        max={
                                            // @ts-ignore
                                            this.state.duration
                                        }
                                        value={this.state.slider.value}
                                    />
                                    {/* <Range /> */}
                                    {/* <Slider
                    defaultValue={0}
                    value={this.state.slider.value}
                    aria-labelledby="discrete-slider-small-steps"
                    min={0}
                    step={1}
                    max={this.state.duration}
                    valueLabelDisplay="auto"
                    onChange={(event, newValue) => this.player.currentTime = newValue }
                  /> */}
                                </div>
                                <div className="time">{currentTime}</div>
                            </div>
                        )}

                        {this.props?.media?.fileName &&
                            !this.props?.media?.isMp3 && (
                                <div className={classList}>
                                    {this.props.isUrgent && (
                                        <span className={importantClassList}>
                                            !
                                        </span>
                                    )}
                                    <div
                                        className="media-top"
                                        onClick={() => {
                                            onSelectMessage(this.props)
                                        }}
                                    >
                                        <img src="/assets/images/attachment-logo.png" />

                                        {this.props.media.fileName.length >
                                        fileNameLength
                                            ? this.props.media.fileName.substring(
                                                  0,
                                                  fileNameLength,
                                              ) + '...'
                                            : this.props.media.fileName}
                                    </div>
                                    <div
                                        className="media-bottom"
                                        onClick={() => {
                                            onSelectMessage(this.props)
                                        }}
                                    >
                                        {this.props.media.size}
                                        {this.props.media.measure}M |{' '}
                                        {this.props.media.type}
                                    </div>
                                </div>
                            )}
                        <audio ref={(ref) => (this.player = ref)} />
                    </div>
                )}
            </>
        )
    }
}
