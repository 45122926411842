import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { userActions } from '../_actions/user.actions'
function ChangePassword(props: any) {
    const { changepwd } = props
    const { login } = props
    const [error, setError] = useState('')
    const email = window.localStorage.getItem('email')

    return (
        <div className="pink-container bg-pink">
            <div className="row">
                <div className="col-sm-6 col-md-6">
                    <div className="semi-article">
                        <figure>
                            <img
                                src="assets/Logo3@2x.png"
                                width="120"
                                alt="logo"
                                className="logo"
                            />
                        </figure>
                        <h2 className="m-t-60">
                            Welcome to the <br /> Sama team!
                        </h2>
                        <p>
                            We are excited that you are part of our mission
                            <br /> to make the world an engaged and inspired
                            <br /> place.
                        </p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6">
                    <div className="login-form">
                        <Formik
                            initialValues={{
                                oldPassword: '',
                                password: '',
                                confirmPassword: '',
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                changepwd(values)
                                    .then((data: any) => {
                                        data.email = email
                                        login(data)
                                    })
                                    .catch(function (error: any) {
                                        setSubmitting(false)
                                        if (error.response?.status == 411) {
                                            setError(
                                                'The password you entered is not sufficiently secure. Please try another one.',
                                            )
                                        } else if (
                                            error.response?.status == 429
                                        ) {
                                            setError(
                                                'You have tried to reset your password too many times please wait 15 minutes and try again.',
                                            )
                                        } else {
                                            Sentry.captureException(error)
                                        }
                                    })
                            }}
                            validationSchema={Yup.object().shape({
                                oldPassword: Yup.string()
                                    .required(
                                        'Password must be 10 characters minimum',
                                    )
                                    .min(
                                        10,
                                        'Password must be 10 characters minimum',
                                    ),
                                password: Yup.string()
                                    .required(
                                        'Your password must contain a minimum of 10 characters and consist of at least 1 uppercase letter, lowercase letter, number and a special character',
                                    )
                                    .matches(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[—\-!#$%§±¡™£¢∞¶•ªº–“‘æ«…¬˚≤≥µ˜√ç≈Ωåß∂`ƒ©~˙∆πøˆ¨¥†®´^&*()_+{}|"?><:,.\/'\\;\]@\[=])[A-Za-z\d—\-!#$%§±¡™£¢∞¶•ªº–“‘æ«…¬˚≤≥µ˜√ç≈Ωåß∂`ƒ©~˙∆πøˆ¨¥†®´^&*()_+{}|"?><:,.'\\;\]@\[=]{10,}$/,
                                        'Your password must contain a minimum of 10 characters and consist of at least 1 uppercase letter, lowercase letter, number and a special character',
                                    ),
                                confirmPassword: Yup.string()
                                    .required('Please retype your password')
                                    .test(
                                        'passwords-match',
                                        'Passwords must match',
                                        function (value) {
                                            return (
                                                this.parent.password === value
                                            )
                                        },
                                    )
                                    .test(
                                        'passwords-different',
                                        'New password can not match current password',
                                        function (value) {
                                            return (
                                                this.parent.oldPassword != value
                                            )
                                        },
                                    ),
                            })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isValid,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                } = props
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <h3>Change your password</h3>
                                        {error && (
                                            <div className="error">
                                                {error}
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        <p style={{ fontSize: '10px' }}>
                                            Your password must contain a minimum
                                            of 10 characters and consist of at
                                            least 1 uppercase letter, lowercase
                                            letter, number and a special
                                            character
                                        </p>
                                        <div className="form-group">
                                            <label htmlFor="oldPassword">
                                                Current password
                                            </label>
                                            <input
                                                id="oldPassword"
                                                placeholder="Type your current password"
                                                type="password"
                                                value={values.oldPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control"
                                            />
                                            {errors.oldPassword &&
                                                touched.oldPassword && (
                                                    <div className="error">
                                                        {errors.oldPassword}
                                                    </div>
                                                )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">
                                                New password
                                            </label>
                                            <input
                                                id="password"
                                                placeholder="Type your new password"
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control"
                                            />
                                            {errors.password &&
                                                touched.password && (
                                                    <div className="error">
                                                        {errors.password}
                                                    </div>
                                                )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword">
                                                Retype password
                                            </label>
                                            <input
                                                id="confirmPassword"
                                                placeholder="Retype password"
                                                type="password"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control"
                                            />
                                            {errors.confirmPassword &&
                                                touched.confirmPassword && (
                                                    <div className="error">
                                                        {errors.confirmPassword}
                                                    </div>
                                                )}
                                        </div>
                                        <div className="form-group btn-block-bottom">
                                            <button
                                                disabled={!(isValid && dirty)}
                                                className="relative btn btn-primary btn-bolck"
                                            >
                                                {isSubmitting ? (
                                                    <div>
                                                        <span className="loading-spin"></span>
                                                        <span>In progress</span>
                                                    </div>
                                                ) : (
                                                    'Save and continue'
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    user: state.authentication,
})

const actionCreators = {
    changepwd: userActions.changepwd,
    login: userActions.login,
}

const connectedChangePage = connect(
    mapStateToProps,
    actionCreators,
)(ChangePassword)
export { connectedChangePage as ChangePassword }
