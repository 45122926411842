import * as Sentry from '@sentry/react'
import axios from 'axios'
import { addDays, format } from 'date-fns'
import { stripHtml } from 'string-strip-html'
import { userConstants } from '../_constants'
import { TrackingService } from '../_services/tracking.service.js'

const AllStatics = localStorage.getItem('statics')
    ? JSON.parse(localStorage.getItem('statics'))
    : {}

function saveNotes(id, data, cb) {
    console.log(data)
    let requestOptions = {}

    for (var propName in data) {
        if (data[propName] == '' || data[propName] == '') {
            delete data[propName]
        }
    }

    if (data.coachingArea) {
        AllStatics.competencies.forEach((item) => {
            if (item.name === data.coachingArea) {
                data.coachingArea = item.key
            }
        })
    }

    if (data._id) {
        requestOptions = {
            method: 'put',
            url: `${userConstants.BASE_URL}coachee/${id}/note/${data._id}`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }
    } else {
        requestOptions = {
            method: 'post',
            url: `${userConstants.BASE_URL}coachee/${id}/note`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }
    }

    return new Promise((resolve, reject) => {
        axios(requestOptions)
            .then((res) => {
                resolve(res)
            })
            .catch(function (error) {
                Sentry.captureException(error)
                reject(error)
            })
    })
}
/* Update Notes API */
function UpdateNotes(id, data, cb) {
    const requestOptions = {
        method: 'post',
        url: `${userConstants.BASE_URL}coachee/${id}/note/mine`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }
    axios(requestOptions)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Get All notes API */
function getAllNotes(id, cb) {
    axios
        .get(`${userConstants.BASE_URL}coachee/${id}/note`)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
function getPrevCoachNotes(id, cb) {
    axios
        .get(`${userConstants.BASE_URL}coachee/${id}/note/previous`)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}

/* Create task API */
function createTask(id, data, cb) {
    const requestOptions = {
        method: 'post',
        url: `${userConstants.BASE_URL}coachee/${id}/task`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }
    axios(requestOptions)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
            TrackingService.send('task_added', { coachee_id: id })
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Update task API */
function updateTask(id, data, cb) {
    const requestOptions = {
        method: 'put',
        url: `${userConstants.BASE_URL}task/${id}`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }
    axios(requestOptions)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Delete task API */
function deleteTask(data, cb) {
    const requestOptions = {
        method: 'delete',
        url: `${userConstants.BASE_URL}task/${data._id}`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }
    axios(requestOptions)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Get All tasks API */
function getTasks(id, cb) {
    axios
        .get(`${userConstants.BASE_URL}coachee/${id}/task`)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Get goals API */
function getGoal(id, cb) {
    axios
        .get(`${userConstants.BASE_URL}coachee/${id}/goal`)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}
/* Get Profile image API */
function getProfilePicture(id) {
    return new Promise((resolve, reject) => {
        if (id) {
            return axios
                .get(
                    `${userConstants.BASE_URL}coach/${id}/picture?size=thumbnail`,
                    { responseType: 'arraybuffer' },
                )
                .then(async function (res) {
                    const fileReader = new FileReader()
                    let blob = new Blob([res.data])
                    fileReader.readAsDataURL(blob)

                    fileReader.onload = function (evt) {
                        var result = evt.target.result
                        try {
                            const data = {
                                expires: addDays(new Date(), 30),
                                image: result,
                            }
                            localStorage.setItem(
                                'picture',
                                JSON.stringify(data),
                            )

                            resolve(URL.createObjectURL(blob))
                        } catch (e) {
                            Sentry.captureException(e)
                            console.log('Storage failed: ' + e)
                            reject(e)
                        }
                    }
                })
                .catch(function (error) {
                    Sentry.captureException(error)
                    console.log('Error: ', error)
                    reject(error)
                })
        } else {
            resolve()
        }
    })
}

function getFile(id, size) {
    return axios
        .get(`${userConstants.BASE_URL}file/${id}?size=${size}`, {
            responseType: 'arraybuffer',
        })
        .then((res) => {
            let blob = new Blob([res.data])
            let objectURL = URL.createObjectURL(blob)
            return objectURL
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}

function saveCoachNotesQuestion(coacheeId, data) {
    const requestOptions = {
        method: 'put',
        url: `${userConstants.BASE_URL}coachee/${coacheeId}/note/questions`,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    }
    return axios(requestOptions)
        .then(function (res) {
            return true
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}

function getCoachNotesQuestion(coacheeId) {
    return axios
        .get(
            `${userConstants.BASE_URL}coachee/${coacheeId}/note/questions/last`,
        )
        .then(function (res) {
            return res.data
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}

function getSummaryLine(content, maxLength) {
    if (content) {
        return stripHtml(content).result.substring(0, maxLength)
    } else {
        return ''
    }
}

/* Get All tasks API */
function getCoacheeSessions(id, data, cb) {
    let startDate
    let endDate
    let isFiltering = false

    if (data.startDate !== null && data.endDate !== null) {
        startDate = format(data.startDate, 'MM-dd-yyyy')
        endDate = format(data.endDate, 'MM-dd-yyyy')
        isFiltering = true
    }

    const requestOptions = {
        method: 'get',
        url: isFiltering
            ? `${userConstants.BASE_URL}coachee/${id}/coach-bookings/${startDate}/${endDate}`
            : `${userConstants.BASE_URL}coachee/${id}/coach-bookings`,
        headers: { 'Content-Type': 'application/json' },
    }

    axios(requestOptions)
        .then(function (res) {
            if (typeof cb !== 'undefined') {
                cb(res)
            }
        })
        .catch(function (error) {
            Sentry.captureException(error)
            console.log('Error: ', error)
        })
}

export const CommonService = {
    saveNotes,
    UpdateNotes,
    getAllNotes,
    getPrevCoachNotes,
    createTask,
    updateTask,
    deleteTask,
    getTasks,
    getGoal,
    getProfilePicture,
    getFile,
    saveCoachNotesQuestion,
    getCoachNotesQuestion,
    getSummaryLine,
    getCoacheeSessions,
}
