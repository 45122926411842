import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import SpectrumGif from '../assets/images/chat/voice-recording/sound_wave.gif'
import { SpectrumSvg } from '../assets/images/chat/voice-recording/sound_wave'
import { formatDuration } from '../utils/date-time'

interface Props {
    handleFormSubmit: any
    open: any
}

export const VoiceRecord: React.FC<Props> = observer(
    ({ handleFormSubmit, open }) => {
        const [recordedMessage, setRecordedMessage] = useState<Blob | null>(
            null,
        )
        const [finalRecordingLength, setFinalRecordingLength] = useState(0)
        const [isPlaying, setIsPlaying] = useState(false)
        const {
            startRecording,
            stopRecording,
            recordingBlob,
            isRecording,
            isPaused,
            recordingTime,
        } = useAudioRecorder(undefined, undefined)

        const baseURL = '/ffmpeg'
        const convertWebmToMp3 = useCallback(
            async (webmBlob: Blob): Promise<Blob> => {
                if (!recordedMessage) {
                    const ffmpeg = new FFmpeg();
                  await ffmpeg.load({
                        coreURL: await toBlobURL(
                            `${baseURL}/ffmpeg-core.js`,
                            'text/javascript',
                        ),
                        wasmURL: await toBlobURL(
                            `${baseURL}/ffmpeg-core.wasm`,
                            'application/wasm',
                        ),
                        workerURL: await toBlobURL(
                            `${baseURL}/ffmpeg-core.worker.js`,
                            'text/javascript',
                        ),
                    })
                                        
                    const inputName = 'input.webm'
                    const outputName = 'output.mp3'

                    await ffmpeg.writeFile(
                        inputName,
                        new Uint8Array(await webmBlob.arrayBuffer()),
                    )

                    await ffmpeg.exec(['-i', inputName, outputName], 10000)
                    const outputData = await ffmpeg.readFile(outputName)
                    const file = new Blob([outputData], { type: 'audio/mp3' })
                    setRecordedMessage(file)
                    return Promise.resolve(file)
                } else {
                    return Promise.resolve(recordedMessage)
                }
            },
            [recordedMessage],
        )

        
    useEffect(() => {
        if (finalRecordingLength <= 0) return
        if (!isPlaying) return
        const intervalId = setInterval(() => {
            setFinalRecordingLength((prevTime) => prevTime - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [finalRecordingLength, isPlaying])

        return (
            <form className="MessageForm">
                <button
                    className="bin-icon"
                    onClick={(e) => {
                        e.preventDefault()
                        setRecordedMessage(null)
                        open(false)
                        // deleteRecording(e)
                    }}
                ></button>
                <div className="input-container voice flex">
                    {!isRecording && (
                        <SpectrumSvg
                            viewBox="0 0 150 15"
                            preserveAspectRatio="none"
                            width="100px"
                            style={{objectFit: 'cover'}}
                            height="20px"
                        />
                    )}

                    {isRecording && (
                        <img
                            src={SpectrumGif}
                            width="80%"
                            height="20px"
                            className="h-[25px]"
                        />
                    )}

                    <span
                        style={{
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '5px',
                            display: 'inline-block',
                        }}
                    >
                           {isRecording
                        ? formatDuration(recordingTime)
                        : formatDuration(finalRecordingLength)}
                    </span>

                    {!isRecording && !isPaused && !recordingBlob && (
                        <button
                            className="play-icon"
                            onClick={(e) => {
                                e.preventDefault()
                                startRecording()
                            }}
                        ></button>
                    )}

                    {recordingBlob && (
          
                        <button
                            className="play-icon"
                            onClick={async (e) => {
                                e.preventDefault()
                                const blob = await convertWebmToMp3(
                                    recordingBlob,
                                )
                                if (blob) {
                                    const audio = new Audio(
                                        URL.createObjectURL(blob),
                                    )
                                    audio.play();
                                setIsPlaying(true)

                                }
                            }}
                        ></button>
                    )}
                    {isRecording && (
                        <button
                            className="pause-icon"
                            onClick={() => {
                                setFinalRecordingLength(recordingTime)
                                stopRecording()
                            }}
                        ></button>
                    )}
                </div>
                <div className="button-container">
                    <button
                        type="button"
                        className="fa fa-paper-plane"
                        disabled={isRecording}
                        onClick={async (e) =>
                            recordingBlob
                                ? handleFormSubmit(e, {
                                      file: await convertWebmToMp3(
                                          recordingBlob,
                                      ),
                                      length: finalRecordingLength,
                                  })
                                : null
                        }
                    ></button>
                </div>
            </form>
        )
    },
)
