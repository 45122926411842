import { userConstants } from '../_constants'
const initialState = {}

export function coachee(
    state = initialState,
    action: { type: string; payload: any },
) {
    switch (action.type) {
        case userConstants.GET_COACHEE_PROFILE:
            mapData(action.payload)
            return state
        default:
            return state
    }
}

function mapData(data: any) {
    data.coachingLanguage = mapStaticsString(
        'coachingLanguage',
        'languages',
        data,
    )
    data.industry = mapStaticsString('industry', 'industries', data)
    data.position = mapStaticsString('position', 'positions', data)
    data.function = mapStaticsString('function', 'functions', data)
    data.desireCompetency = mapStaticsString(
        'desireCompetency',
        'competencies',
        data,
    )

    data.coachGender =
        data.coachGender[0].toUpperCase() + data.coachGender.substring(1)
    data.timezone = data.user?._timezone
    if (data.confidentialInsight) {
        data.confidentialInsight = data.confidentialInsight.replace(
            /\n/g,
            '<br />',
        )
    }
}

function mapStaticsString(field: string, staticField: string, data: any[]) {
    const AllStatics = JSON.parse(localStorage.getItem('statics') ?? '{}')
    let returnData = ''
    AllStatics[staticField].forEach((item2: StaticsValues) => {
        if (item2.key === data[field as keyof typeof data]) {
            returnData = item2.name
        }
    })
    return returnData
}
