import React, { useState, useEffect, useCallback } from 'react'

import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import OrangeDownArrow from '../assets/icons/orange_down_arrow.svg'

import './sama-componant-styles.css'

interface Props {
    options: any
    icon?: string
    emptyText: string
    onChange: any
    value: string
    initialValue: string
}

const SamaDropdownBox = ({
    options,
    icon,
    emptyText,
    onChange,
    value,
    initialValue,
}: Props) => {
    // useEffect(
    //     function effectFunction() {
    //         setCurrentItem(value)
    //     },
    //     [value],
    // )

    const [currentItem, setCurrentItem] = useState()
    const [isOpen, openOptions] = useState(false)

    const checkKeyPress = useCallback((e: any) => {
        const { key, keyCode } = e
        if (keyCode === 27) {
            openOptions(false)
        }
    }, [])

    function handleClick(data: any) {
        setCurrentItem(data)
        onChange(data)
    }

    const checkMousePress = useCallback((event: any) => {
        if (
            event &&
            event.target &&
            (typeof event.target.className === 'string' ||
                event.target.className instanceof String) &&
            !event.target.className.includes('MuiTypography-root') &&
            !event.target.className.includes('MuiListItemButton-root') &&
            !event.target.className.includes('MuiListItemIcon-root') &&
            !event.target.className.includes('search-box') &&
            !event.target.className.includes('select-arrow')
        ) {
            openOptions(false)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', checkKeyPress)
        document.addEventListener('mousedown', checkMousePress)
        return () => {
            window.removeEventListener('keydown', checkKeyPress)
            document.removeEventListener('mousedown', checkMousePress)
        }
    }, [checkKeyPress, checkMousePress])

    const getListHtml = () => {
        return options.map((item: any, i: number) => {
            return (
                <ListItemButton
                    key={i}
                    className="list-button"
                    onClick={() => handleClick(item)}
                    selected={currentItem == item}
                >
                    <ListItemText primary={item} />
                </ListItemButton>
            )
        })
    }

    return (
        <div className="s-input-box" onClick={() => openOptions(!isOpen)}>
            {icon && <img src={icon} style={{ paddingLeft: '20px' }} />}
            <span style={{ paddingLeft: '14px' }}>
                {!currentItem
                    ? !initialValue
                        ? emptyText
                        : initialValue
                    : currentItem}
            </span>
            <img
                src={OrangeDownArrow}
                className="select-arrow"
                style={{
                    filter: isOpen
                        ? 'invert(98%) sepia(23%) saturate(0%) hue-rotate(95deg) brightness(105%) contrast(102%)'
                        : '',
                }}
                onClick={() => openOptions(!isOpen)}
            />
            <Collapse
                className="type-list-box"
                in={isOpen}
                timeout="auto"
                unmountOnExit
            >
                <List component="nav" aria-labelledby="nested-list-subheader">
                    {getListHtml()}
                </List>
            </Collapse>
        </div>
    )
}

export default SamaDropdownBox
