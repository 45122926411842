import React, { useState, useCallback, useEffect } from 'react'
import Room from './Room'
import { RatingBar } from '../_components/rating'
import { VideService } from '../_services/video.services'
import { TrackingService } from '../_services/tracking.service.js'
import { Booking } from '../Sessions/types/Booking'

const VideoChat = ({
    onCloseVideoPop,
    booking,
}: {
    onCloseVideoPop: () => void
    booking: Booking
}) => {
    const [roomName, setRoomName] = useState('')
    const [token, setToken] = useState(null)
    const [isRating, setRating] = useState(false)
    const [rateVlaue, setRateVlaue] = useState(0)

    const trackDisconnect = () => {
        TrackingService.send('session_live_coaching_disconnected', {})
    }

    useEffect(() => {
        VideService.getToken(booking._id, function (response: any) {
            setRoomName(response.data.room.name)
            setToken(response.data.room.token)
        })
        window.addEventListener('beforeunload', trackDisconnect, false)

        return window.removeEventListener('beforeunload', trackDisconnect)
    }, [booking])

    const handleLogout = useCallback(() => {
        TrackingService.send('session_live_coaching_disconnected', {})
        window.removeEventListener('beforeunload', trackDisconnect)

        setToken(null)
        setRating(true)
    }, [])

    const onRateMe = (value: number) => {
        setRateVlaue(value)
    }

    const onRatingSubmit = () => {
        VideService.ratingUpdate(
            booking._id,
            { rate: Number(rateVlaue) },
            function () {
                setRating(false)
                onCloseVideoPop()
            },
        )
    }

    return (
        <>
            {token && (
                <Room
                    roomName={roomName}
                    booking={booking}
                    isVideoEnabled={booking.isVideoSession}
                    token={token}
                    handleLogout={handleLogout}
                />
            )}

            {!token && (
                <div className="rating-container justify-content-center align-items-center">
                    {isRating && (
                        <div className="start-rating-block text-center">
                            <button
                                type="button"
                                className="close btn-close"
                                onClick={onCloseVideoPop}
                            >
                                &times;
                            </button>
                            <p>How would you rate your call?</p>
                            <RatingBar onRateMe={onRateMe} />
                            <div className="text-center">
                                {!rateVlaue ? (
                                    <button
                                        onClick={() => {
                                            setRating(false)
                                            onCloseVideoPop()
                                        }}
                                        type="button"
                                        className="btn btn-rate"
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-rate"
                                        onClick={onRatingSubmit}
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
export default VideoChat
